import "font-awesome/css/font-awesome.css";
import "../css/contact.css";

function CarrerBaner() {
  return (
    <>
      <div className="contact-banner">
        <div>
          <p className="main-title">Career</p>
        </div>
      </div>
    </>
  );
}

export default CarrerBaner;
