// PartnershipForm.js

import React from "react";

const PartnershipForm = () => {
  // Add your form logic here

  return (
    <div className="partnership-form">
      <h2>Partner With Us</h2>
    </div>
  );
};

export default PartnershipForm;
