import React from "react";
import CommonBtns from "./CommonBtn";
import ProductContent from "./ProductContent";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const ProductDetail = () => {
  const { id, cat } = useParams();
  // Get the current URL using window.location.href
  const currentUrl = window.location.href;
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",

    name: "Infygain Technologies",
    legalName: "Computer Service",
    email: "info@infygain.com",
    url: "https://infygain.com/",
    logo: "https://infygain.com/images/logo.svg",
    sameAs: [
      "https://twitter.com/infygain",
      "https://www.instagram.com/infygain/",
      "https://www.facebook.com/infygain/",
      "https://www.linkedin.com/company/infygain-technologies/",
      "https://www.youtube.com/@infygain6707",
      "https://infygain.com/",
    ],
    address: {
      addressLocality: "prozone mall",
      postalCode: "641035",
      streetAddress:
        "Hari complex opp.prozone mall, Sathy Rd, Saravanampatti, Coimbatore, Tamil Nadu ",
    },
  };

  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://infygain.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "About - Us",
        item: "https://infygain.com/about",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "IT Supports",
        item: "https://infygain.com/Iotservice",
      },
      {
        "@type": "ListItem",
        position: 4,
        name: "ISP Services",
        item: "https://infygain.com/ispservice",
      },
      {
        "@type": "ListItem",
        position: 5,
        name: "WEB Services",
        item: "https://infygain.com/ServiceSeo",
      },
      {
        "@type": "ListItem",
        position: 6,
        name: "Blogs",
        item: "https://infygain.com/mainblogs",
      },
      {
        "@type": "ListItem",
        position: 7,
        name: "Career",
        item: "https://infygain.com/career",
      },
      {
        "@type": "ListItem",
        position: 8,
        name: "Partner",
        item: "https://infygain.com/Partner",
      },
      {
        "@type": "ListItem",
        position: 9,
        name: "Contact",
        item: "https://infygain.com/contact",
      },
      {
        "@type": "ListItem",
        position: 10,
        name: "Online Store",
        item: "https://infygain.com/onlinestore/",
      },
    ],
  };

  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Infygain Technologies",
    image: "https://infygain.com/images/logo.svg",
    "@id": "",
    url: "https://infygain.com",
    telephone: "9952141444",
    address: {
      "@type": "PostalAddress",
      streetAddress: "hari complex opp.prozone mall, Sathy Rd, Saravanampatti",
      addressLocality: "Coimbatore",
      postalCode: "641035",
      addressCountry: "IN",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 11.0549976,
      longitude: 76.9951292,
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      opens: "09:45",
      closes: "19:00",
    },
  };

  const websiteSchema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Infygain Technologies",
    url: "https://infygain.com/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://infygain.com/{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://infygain.com/",
    },
    headline: "Best IT support and web development company in Coimbatore",
    description:
      "We are a prominent IT service provider, based in Coimbatore, with a wide range of products and services, to meet the exclusive needs of our customers. We offer end-to-end solutions to enable a reliable and uninterrupted workflow for our customers.",
    image: "https://infygain.com/images/others/services.webp",
    author: {
      "@type": "Organization",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Infygain Technologies",
      logo: {
        "@type": "ImageObject",
        url: "https://infygain.com/images/logo.svg",
      },
    },
    datePublished: "2024-01-12",
  };
  const videoSchema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: "Infygain Technologies",
    description:
      "INFYGAIN - Leading Computer Service at Doorstep in Coimbatore",
    thumbnailUrl: "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
    uploadDate: "2024-01-12T12:00:00Z",
    duration: "PT1M1M", // Replace with the actual duration of your video
    contentUrl: "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
    embedUrl: "https://www.youtube.com/embed/CdP9VCScVmQ",
    publisher: {
      "@type": "Organization",
      name: "Infygain Technologies",
      logo: {
        "@type": "ImageObject",
        url: "URL to your organization's logo",
      },
    },
  };
  const Laptop = [
    {
      name: "Gaming Laptop",
      key:"Gaming Laptop Service",
      img: "/images/gaming-laptop-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/149",
      para: "Welcome to Infygain Technologies, where the world of gaming meets cutting-edge technology. Immerse yourself in an unparalleled gaming experience with our collection of the best Gaming Laptops, meticulously chosen to redefine your gaming adventures. As the <b>best laptop service center in Coimbatore</b>, we are committed to ensuring your gaming devices receive top-notch service and support. Elevate your gaming standards with us – where excellence in gaming laptops meets the <b>best laptop service center in Coimbatore.</b>",
      p1: "At Infygain Technologies, we understand the demands of avid gamers. Our meticulously selected best Gaming Laptops deliver power-packed performance, ensuring smooth gameplay, stunning graphics, and lightning-fast responsiveness. Explore <b>gaming laptop services in Coimbatore</b> with us for an immersive gaming experience.",
      p2: "Experience a superior gaming experience with the high-performance components integrated into our best Gaming Laptops. From powerful processors and advanced graphics cards to high refresh rate displays, our laptops are designed to meet the demands of modern gaming.",
      p3: "Enjoy gaming on the go with the sleek and portable design of our best Gaming Laptops. Whether you're a professional gamer, a content creator, or a casual player, our laptops offer the perfect blend of style and performance for your gaming lifestyle.",
      p4: "Choose from our versatile range of Gaming Laptops that cater to various gaming preferences and requirements. Whether you prefer intense multiplayer battles, immersive single-player experiences, or content creation, our collection includes the best options to suit diverse gaming needs.",
      p5: "Explore a comprehensive range of <b>Gaming Laptop Service</b> with the best technology for various gaming setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Gaming Laptop based on your specific gaming requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Gaming Laptop technology and service.",
      p8: "Elevate your gaming experience with the <b>best Gaming Laptop Service</b> available at Infygain Technologies. For all your laptop needs, including <b>Wifi Connectivity Issues, Doorstep Laptop Repair, Laptop and RAM Upgradation, Laptop Screen Replacement, Data Recovery, Driver Solutions, Auto Data Backup, New Laptop Setup, Laptop Support, and Laptop Repair, we've got you covered.",
    },
    {
      name: "Consumer laptops",
      key:"Consumer Laptop Service",     
      img: "/images/consumer-laptop-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/149",
      para: "Welcome to Infygain Technologies, where cutting-edge technology meets consumer-friendly innovation. Dive into the world of superior computing with our collection of the best Consumer Laptops, meticulously chosen to deliver an exceptional user experience for your everyday needs. Explore the best <b>laptop service in Coimbatore</b> for unparalleled support and maintenance.",
      p1: "At Infygain Technologies, we understand the importance of a laptop that effortlessly blends performance and user-friendly features. Our carefully selected best Consumer Laptops are designed to provide seamless performance for various tasks, from work to entertainment.",
      p2: "Experience powerful computing with the high-speed performance of our best Consumer Laptops. Whether you're multitasking, streaming, or working on creative projects, our laptops are equipped with advanced processors to handle it all with ease.",
      p3: "Opt for sleek design and portable convenience with our best Dell Laptops in Coimbatore. Perfect for users on the go, our laptops offer a blend of style and functionality, ensuring you can take your computing power wherever life takes you.",
      p4: "Choose from our versatile range of Consumer Laptops that cater to various computing preferences. Whether you need a laptop for productivity, entertainment, or a bit of both, our collection includes the best options to suit diverse consumer needs.",
      p5: "Explore a comprehensive range of Consumer Laptops with the best technology for various user setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Consumer Laptop based on your specific requirements.",
      // p7: "Join our satisfied customers who trust in our commitment to providing the best in Consumer Laptop technology and service.",
      p7: "Elevate your everyday computing experience with the best Consumer Laptops available at Infygain Technologies located in Coimbatore. Explore a diverse range featuring HP, Asus, and more, ensuring top-tier performance and style for your computing needs.",
      p8: "Visit our dedicated service centers for HP, Acer, and Dell laptops in Coimbatore. Experience prompt and reliable service at our<b> HP Laptop Service Center, Acer Laptop Service Center, and Dell Laptop Service Center in Coimbatore.",
    },
    {
      name: "WorkStation",
      key:"Work Station",
      img: "/images/workstation vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/149",
      para: "Welcome to Infygain Technologies, where we redefine productivity and performance in the world of professional computing. Elevate your work experience with our collection of the best Workstation Laptops, meticulously chosen to meet the demanding needs of modern professionals. Explore top-tier workstation sales in Coimbatore for cutting-edge technology and superior performance. ",
      p1: "At Infygain Technologies, we understand the requirements of professionals who need robust computing power. Our carefully selected best Workstation Laptops deliver unparalleled performance, ensuring seamless multitasking, complex computations, and resource-intensive tasks are handled with ease.",
      p2: "Experience professional excellence with the high-end components integrated into our best Workstation Laptops. From powerful processors and dedicated graphics cards to extensive RAM and storage options, our laptops are designed to meet the demands of professional workflows.",
      p3: "Enjoy maximum productivity with the precision engineering of our best Workstation Laptops. Whether you're a content creator, designer, engineer, or any other professional, our laptops offer the precision and reliability needed for your critical tasks.",
      p4: "Choose from our versatile range of Branded Desktops that cater to various computing preferences and requirements. Whether you need a desktop for work, entertainment, or creative endeavors, our collection includes the best options to suit diverse desktop computing needs. Explore top-tier desktop service for reliable maintenance and support. ",
      p5: "Explore a comprehensive range of Workstation Laptops with the best technology for various professional setups. Trust our expert service for HP, Dell Workstations in Coimbatore, ensuring top-tier performance and support for your professional needs.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best mobile Workstation Laptop in coimbatore based on your specific professional requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Workstation Laptop technology and service.",
      p8: "Elevate your professional computing experience with the best Workstation Laptops available at Infygain Technologies.",
    },
  ];

  const Desktop = [
    {
      name: "Branded Desktop",
      key:"Branded Desktop",
      img: "/images/branded-desktop.webp",
      link: "https://infygain.com/onlinestore/products/ca/148",
      para: "Welcome to Infygain Technologies, where top-tier technology meets unparalleled performance. Explore the world of computing excellence with our collection of the best Branded Desktops, meticulously chosen to deliver power, reliability, and innovation for your computing needs. Discover the best branded desktops in Coimbatore for a superior computing experience.",
      p1: "At Infygain Technologies, we understand the importance of a desktop that exceeds expectations. Our carefully selected best Branded Desktops offer unparalleled performance, ensuring smooth multitasking, fast data processing, and optimal efficiency for both personal and professional use.",
      p2: "Experience dependable computing with the high-quality components integrated into our best Branded Desktops. From advanced processors and efficient RAM to reliable storage solutions, our desktops are designed to meet the demands of modern computing tasks.",
      p3: "Enjoy innovative design and robust solutions with our best Branded Desktops. Whether you're a professional, creative, or a home user, our desktops offer a blend of style and functionality, ensuring they seamlessly fit into your workspace. Explore top-tier desktop repair, auto-backup solutions, and more for reliable performance and peace of mind.",
      p4: "Choose from our versatile range of Branded Desktops that cater to various computing preferences and requirements. Whether you need a desktop for work, entertainment, or creative endeavors, our collection includes the best options to suit diverse desktop computing needs.",
      p5: "Explore a comprehensive range of Branded Desktops with the best technology for various computing setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Branded Desktop based on your specific computing requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Branded Desktop technology and service.",
      p8: "Elevate your desktop computing experience with the best Branded Desktops available at Infygain Technologies in Coimbatore. For all your desktop needs, including Desktop Repair, Auto Backup, Data Management, Spy Software, Service Management, System Service, 60 Minutes Instant Support, Mail Solution, Hardware Installation Support, PC Turn-up, Doorstep Repair, and Desktop Support, we've got you covered.",
    },
    {
      name: "Assembled Desktop",
      key:"Assembled Desktop",
      img: "/images/Assembled-desktop-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/148",
      para: "Welcome to Infygain Technologies, where we empower you to create a personalized computing solution. Dive into the world of customization and performance with our collection of the best Assembled Desktops, meticulously chosen to offer flexibility, power, and the freedom to tailor your desktop to your exact specifications.",
      p1: "At Infygain Technologies, we recognize that every user has unique computing needs. Our carefully selected best Assembled Desktops provide the ultimate flexibility, allowing you to choose components that suit your specific requirements and preferences.",
      p2: "Experience the freedom to select high-performance components for your best Assembled Desktop. From advanced processors and powerful graphics cards to customized RAM and storage solutions, our desktops are designed to cater to your individual computing demands.",
      p3: "Enjoy the flexibility of design and easy upgradability with our best Assembled Desktops in Coimbatore. Whether you're a gaming enthusiast, a content creator, or a professional, our desktops offer the versatility to adapt to evolving technologies and your changing computing needs.",
      p4: "Choose from our versatile range of Assembled Desktops that cater to various computing preferences and requirements. Whether you need a desktop for gaming, creative work, or professional tasks, our collection includes the best options to suit diverse desktop computing needs.",
      p5: "Explore a comprehensive range of Assembled Desktops with the best technology for various computing setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Assembled Desktop based on your specific computing requirements.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in Assembled Desktop technology and service in Coimbatore. Experience convenience with our doorstep repair and desktop support for seamless solutions and reliable performance. ",
      p8: " Craft your perfect computing experience with the best Assembled Desktops available at Infygain Technologies.  ",
    },
    {
      name: "Work Station",
      key:"Work Station Desktop",
      img: "/images/workstation vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/148",
      para: "Welcome to Infygain Technologies, where we redefine professional computing excellence in Coimbatore. Unleash unparalleled power and performance with our collection of the best Workstation Desktops, meticulously chosen to meet the demands of your most intensive tasks and workflows.",
      p1: " At Infygain Technologies, we understand the critical nature of professional tasks. Our carefully selected best Workstation Desktops offer the pinnacle of performance, ensuring seamless execution of complex computations, rendering, and data-intensive applications.",
      p2: "Experience professional-grade computing with the high-end components integrated into our best Workstation Desktops. From powerful processors and professional-grade graphics cards to extensive RAM and storage options, our desktops are designed to handle the demands of modern professional workflows.",
      p3: " Enjoy maximum productivity with the precision engineering of our best Workstation Desktops. Whether you're a content creator, designer, engineer, or any other professional, our desktops offer the precision and reliability needed for your critical tasks.",
      p4: "Choose from our versatile range of Workstation Desktops that cater to various professional settings and requirements. Whether you're working with graphic design, 3D modeling, video editing, or data analysis, our collection includes the best options to suit diverse workstation needs.",
      p5: " Explore a comprehensive range of Workstation Desktops with the best technology for various professional setups.",
      p6: "Craft your perfect computing experience with the best Assembled Desktops available at Infygain Technologies in coimbatore. ",
      p7: " Rely on our knowledgeable team for expert advice on selecting the best Workstation Desktop based on your specific professional requirements. Experience seamless integration with our hardware installation support for top-tier performance and reliability. ",
      p8: " Elevate your professional computing experience with the best Workstation Desktops available at Infygain Technologies.",
    },
    {
      name: "Gaming Desktop",
      key:"Gaming Desktop",
      img: "/images/gaming-desktop-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/148",
      para: " Welcome to Infygain Technologies, where the world of gaming meets cutting-edge technology. Elevate your gaming experience to new heights with our collection of the best Gaming Desktops, meticulously chosen to deliver unrivaled performance, stunning graphics, and a seamless gaming adventure. Explore top-tier gaming desktop service in Coimbatore for superior support and maintenance. ",
      p1: "At Infygain Technologies, we understand the demands of avid gamers. Our carefully selected best Gaming Desktops deliver power-packed performance, ensuring smooth gameplay, stunning graphics, and lightning-fast responsiveness.",
      p2: "Experience gaming prowess with the high-performance components integrated into our best Gaming Desktops. From advanced processors and top-tier graphics cards to high-speed RAM and storage options, our desktops are designed to meet the demands of modern gaming.",
      p3: "Immerse yourself in gaming ambiance with the sleek design and customizable RGB lighting of our best Gaming Desktops. Whether you're a casual gamer or a competitive enthusiast, our desktops offer style and personalization to enhance your gaming setup.",
      p4: "Choose from our versatile range of Gaming Desktops that cater to various gaming preferences and requirements. Whether you prefer high-octane multiplayer battles, immersive single-player experiences, or content creation, our collection includes the best options to suit diverse gaming needs.",
      p5: " Explore a comprehensive range of VR Gaming Laptops in coimbatore with the best technology for various gaming setups. Elevate your gaming experience with cutting-edge features designed specifically for immersive VR gaming. ",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best Gaming Desktop based on your specific gaming requirements. Ensure optimal performance and reliability with our desktop repair services, tailored to enhance your gaming experience. ",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Gaming Laptop technology and service in coimbatore. ",
      p8: "Elevate your gaming experience with the best Gaming Desktops available at Infygain Technologies.  ",
    },
  ];

  const HUB = [
    {
      name: "HUB",
      key:"Hub Solutions",
      img: "/images/hub vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/178",
      para: "Our Website Hub serves as the central nerve center of our online presence, designed to be your go-to destination for accessing a wealth of information, resources, and interactive features. Think of it as the heart of our digital ecosystem, pulsating with the latest updates, featured content, and a seamless navigation experience. ",
      p1: "Streamlined access to essential information with our <b>Hub Solutions in coimbatore</b>. Everything you need, neatly organized in a central location for effortless navigation.      ",
      p2: "Enjoy a user-friendly experience with intuitive navigation features, ensuring that visitors can explore various sections effortlessly.",
      p3: "Discover trending or important content prominently showcased within the hub, offering a quick overview of what's currently relevant.",
      p4: "Stay ahead with our <b>Network Hub Services in coimbatore</b> - Your go-to destination for real-time updates and news. Our centralized hub ensures convenient access to the latest information, fostering seamless connectivity within your network.",
      p5: "Engage with the community through interactive elements such as comment sections, polls, and forums, fostering a sense of connection and collaboration.",
      p6: " Unlock personalized experiences with our Hub in <b>Network Solutions in coimbatore</b>. Tailor your view, customize preferences, and delve into areas of interest seamlessly.",
      p7: "Experience the hub seamlessly across devices, whether you're accessing it from your desktop, tablet, or smartphone.",
      p8: "Dive into specific categories effortlessly with our quick links and organized menus, enhancing your ability to find relevant content swiftly. Explore the convenience of hub, wireless hub, and hub for home options.",
    },
  ];
  const Switch = [
    {
      name: "Switch",
      key:"Network switch",
      img: "/images/switch vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/178",
      para: "Network infrastructure with Infygain Technologies' cutting-edge <b>switch solutions in coimbatore</b>. Discover efficiency, scalability, and security seamlessly integrated into our advanced <b>network switches in coimbatore</b>. Trust us to empower your business with the technology it needs for seamless operations. Explore the future of networking with Infygain Technologies.",
      p1: "We offer a range of switches incorporating the latest technological advancements to ensure optimal performance.",
      p2: "Choose from our selection of managed switches that provide granular control over your network. Set up VLANs, implement Quality of Service (QoS), and monitor network activity with ease. ",
      p3: "Simplify your network setup with PoE switches that deliver power and data over a single cable. Ideal for powering devices like IP cameras, VoIP phones, and wireless access points without the need for additional power sources..",
      p4: "Unlock limitless scalability with our cutting-edge <b>network switching solutions in coimbatore</b>. Seamlessly expand your business network as needed, effortlessly integrating additional switches.",
      p5: "Elevate your business connectivity with our cutting-edge <b>network switching solutions in coimbatore</b>.Streamlineyour office network with reliable and high-performance switches designed to meet the demands of modern businesses.",
      p6: "Create secure and segmented networks for different departments or classes with VLAN-enabled switches.",
      p7: "Power your security cameras with PoE switches, eliminating the need for separate power sources.",
      p8: "Experience improved data transfer speeds with our full-duplex switches that allow simultaneous data sending and receiving.",
    },
  ];
  const Router = [
    {
      name: "Router",
      key:"Wi-Fi Router",
      img: "/images/router-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/178",
      para: "Discover the beating heart of your network with our cutting-edge <b>router solutions provider</b>. We don't just offer routers; we provide gateways to a world where data flows effortlessly, security is paramount, and your connectivity needs are met with precision.",
      p1: "Routers make real-time decisions about the most efficient path for data to travel, facilitating optimal connectivity. ",
      p2: "Experience the internet at the speed of today's demands. Our routers are engineered to deliver swift and reliable connectivity for all your devices. ",
      p3: "Unlock unparalleled connectivity with our High-Performance <b>Router Solutions in coimbatore</b>. Harness the power of Network Address Translation (NAT) to seamlessly map private IP addresses to a single public IP, enabling multiple devices to share a lightning-fast internet connection.",
      p4: "Elevate your network security with premium routers in coimbatore fortified by robust firewalls and encryption protocols. ",
      p5: "Modern routers support Wi-Fi, providing wireless connectivity for diverse devices, from smartphones to smart home gadgets. Explore our <b>internet router</b> and <b>Wi-Fi router solutions</b> for seamless connectivity",
      p6: "In offices, routers enable seamless communication among employees and ensure a secure connection to the internet for business operations.",
      p7: "Empower your gaming and streaming adventures with cutting-edge Router Functions in coimbatore. Prioritize bandwidth for an immersive online experience, ensuring smooth gameplay and uninterrupted streaming. ",
      p8: "<b>Routers are the backbone of home networks, facilitating internet access for various devices such as computers, smartphones, and smart TVs.</b>",
    },
  ];
  const Firewall = [
    {
      name: "Firewall",
      key:"Network Firewall",
      img: "/images/firewall.webp",
      link: "https://infygain.com/onlinestore/products/ca/178",
      para: "In the ever-evolving landscape of digital threats, a firewall stands as a stalwart guardian, defending your digital fortress against malicious intruders. At Infygain Technologies, we recognize the critical role that firewalls play in safeguarding your network's integrity, ensuring a secure environment for your digital operations.",
      p1: "Firewalls meticulously examine incoming and outgoing traffic, allowing or blocking data packets based on pre-established security rules. This ensures that only legitimate and safe connections are permitted.",
      p2: "Ensure secure online transactions and protect customer data on your e-commerce platform with our <b>firewall solutions</b> designed for the unique demands of online businesses.",
      p3: "Our firewalls are equipped with intrusion detection and prevention systems (IDPS) that actively identify and thwart potential threats, safeguarding your network from evolving cyber risks.",
      p4: "Heightened security with our advanced firewalls and meticulous <b>firewall configuration in coimbatore</b>. Beyond traditional packet filtering, our firewalls delve into the application layer, providing granular scrutiny of data.",
      p5: "Our firewalls leverage cutting-edge technology to detect and mitigate emerging threats, providing real-time protection against the latest cybersecurity challenges.",
      p6: "Simplify network security with our firewalls. Intuitive interfaces make it easy for both technical and non-technical users to implement security measures effortlessly. Explore reliable <b>firewall security solutions.</b> ",
      p7: "Safeguard sensitive corporate data and communications with our enterprise-grade firewalls, securing the backbone of your business operations.",
      p8: "Protect student and staff information from potential cyber threats with robust firewall solutions tailored for educational environments.",
    },
  ];
  const EndPointSecurity = [
    {
      name: "End Point Security",
      key:"End Point Security",
      img: "/images/endpoint vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/178",
      para: "Endpoint Security is the first and last line of defense against cyber threats. It revolves around safeguarding every device connected to a network - be it laptops, smartphones, or Internet of Things (IoT) devices - from potential cybersecurity risks. At Infygain Technologies, we understand that the strength of your digital fortress lies in the security of every endpoint.",
      p1: "Empower your cybersecurity with our advanced <b>Endpoint Security</b> software in coimbatore. Going beyond traditional antivirus measures, our solution employs sophisticated threat detection algorithms and real-time analysis.",
      p2: "Take control of your digital landscape effortlessly. Our user-friendly interface allows centralized management of security policies, updates, and configurations across all your endpoints.",
      p3: "Stay one step ahead with our real-time monitoring capabilities. We actively identify and respond to potential threats, ensuring a proactive defense against evolving cyber risks.",
      p4: "Empower your cybersecurity strategy with our cutting-edge <b>endpoint security solutions</b> in coimbatore. Harnessing the latest technologies, we offer real-time protection against emerging threats, ensuring robust defense in the dynamic cybersecurity landscape.",
      p5: "Tailor security policies to align with your unique business requirements. From restricting access to certain websites to prioritizing critical applications, our solutions offer flexibility.",
      p6: "Simplify <b>endpoint security</b> with our VPN-enabled solutions. Our user-friendly interfaces make managing endpoint security effortless, ensuring a seamless experience for both technical and non-technical users.            ",
      p7: "Protect patient records and medical devices from cyber threats with our comprehensive <b>endpoint security solutions</b>. Ensure patient confidentiality and compliance with healthcare regulations for a secure healthcare environment.",
      p8: "Safeguard financial transactions, customer data, and sensitive information from potential cyberattacks in the financial sector.",
    },
  ];
  const Fiber = [
    {
      name: "Fiber",
      key:"Fiber Solutions",
      img: "/images/fibres.webp",
      link: "https://infygain.com/onlinestore/products/ca/178",
      para: "At Infygain Technologies, we believe that the backbone of modern connectivity is built on the speed and reliability of <b>fiber optics</b>. As pioneers in the field, we are committed to delivering state-of-the-art fiber solutions that transform the way you connect, communicate, and collaborate.",
      p1: "Unmatched data transfer speeds with our <b>fiber network solutions</b> in coimbatore. Ideal for businesses, homes, and industries where speed is paramount, our fiber optics technology ensures unparalleled connectivity.",
      p2: "Say goodbye to interruptions and signal degradation. <b>Fiber optics </b>are resistant to electromagnetic interference, ensuring a stable and reliable connection, even over long distances.",
      p3: "The incredible bandwidth capacity of <b>fiber optics</b> means you can handle large volumes of data simultaneously without compromising on speed or quality.",
      p4: "Invest in the future of connectivity. Fiber optics are not just meeting today's demands; they are ready to support the ever-increasing data needs of tomorrow.",
      p5: "Unmatched data transfer speeds with our fiber network solutions in coimbatore. Ideal for businesses, homes, and industries where speed is paramount, our fiber optics technology ensures unparalleled connectivity.",
      p6: "Enhance your business operations with our business-grade <b>fiber solutions</b>. From improved communication to efficient data management, fiber optic cable is the key to unlocking your business's full potential.",
      p7: "Revolutionize your network with our cutting-edge fiber optic cabling solutions in coimbatore. Explore seamless connectivity and unparalleled speed with our fiber network design services in Coimbatore.",
      p8: "Transform your telecom network with the speed and reliability of fiber optics.",
    },
  ];
  const Sdwan = [
    {
      name: "SD-WAN",
      key:"SD-WAN Router",
      img: "/images/sdwan-vector.webp",
      link: "https://infygain.com/onlinestore/products/",

      para: "At Infygain Technologies, we believe that the backbone of modern connectivity is built on the speed and reliability of SD-WAN. As pioneers in the field, we are committed to delivering state-of-the-art <b>SD-WAN solutions </b>that transform the way you connect, communicate, and collaborate.",
      p1: "Unmatched data transfer speeds with our <b>SD-WAN network solutions in Coimbatore</b>. Ideal for businesses, homes, and industries where speed is paramount, our SD-WAN technology ensures unparalleled connectivity.",
      p2: "Say goodbye to interruptions and signal degradation. <b>SD-WAN networks</b> are designed to be resistant to disruptions, ensuring a stable and reliable connection, even over long distances.",
      p3: "The incredible bandwidth capacity of SD-WAN means you can handle large volumes of data simultaneously without compromising on speed or quality.",
      p4: "Invest in the future of connectivity. SD-WAN technology is not just meeting today's demands; it is ready to support the ever-increasing data needs of tomorrow.",
      p5: "Unmatched data transfer speeds with our<b> SD-WAN network solutions</b> in Coimbatore. Ideal for businesses, homes, and industries where speed is paramount, our SD-WAN technology ensures unparalleled connectivity.",
      p6: "Enhance your business operations with our business-grade SD-WAN solutions. From improved communication to efficient data management, SD-WAN is the key to unlocking your business's full potential.",
      p7: "Revolutionize your network with our cutting-edge SD-WAN solutions in Coimbatore. Explore seamless connectivity and unparalleled speed with our <b>SD-WAN network design services.</b>",
      p8: "Transform your telecom network with the speed and reliability of SD-WAN.",
    },
  ];
  const Analog = [
    {
      name: "Analog",
      key:"Analog CCTV Camera dealer",
      img: "/images/vector for analog-01.jpg",
      link: "https://infygain.com/onlinestore/products/ca/152",
      para: "<b>Analog CCTV Camera</b> - Welcome to Infygain Technologies, your premier destination for top-notch <b>Analog surveillance solutions</b>. At Infygain Technologies, we pride ourselves on offering a range of Analog products that combine simplicity, reliability, and clarity for unparalleled security. Here's why our Analog solutions stand out",
      p1: "Dive into the essence of enduring surveillance with our <b>Analog Camera</b> range. Experience timeless security through our analog systems in Coimbatore.",
      p2: "Experience clarity in every frame, capturing crucial details with precision.",
      p3: "Explore our Analog electronics that form the backbone of reliable surveillance systems.",
      p4: "Benefit from tried-and-true technology that has stood the test of time.",
      p5: "Discover unparalleled durability and reliability with our <b>analog CCTV solutions in coimbatore</b>.",
      p6: "Navigate your security needs effortlessly with user-friendly Analog systems.",
      p7: "Capture every moment with precision using trusted analog technology like <b>CCTV camera</b> and digital camera. Unleash clarity in every frame.",
      p8: "robust security with confidence through Infygain Technologies' top-tier <b>Analog CCTV Systems in coimbatore.</b>",
    },
  ];

  const IP = [
    {
      name: "IP",
      key:"IP CCTV Camera Dealer",
      img: "/images/ip-cctv.webp",
      link: "https://infygain.com/onlinestore/products/ca/152",
      para: "<b>IP CCTV Camera</b> - Welcome to Infygain Technologies, where innovation meets security excellence with our cutting-edge IP surveillance solutions. Discover the power of Internet Protocol technology that elevates your surveillance experience. Here's why our IP solutions stand out:",
      p1: "The future of surveillance with our cutting-edge <b>IP CCTV system in coimbatore</b>. Step into a world of crystal-clear imaging and heightened security. Our high-definition IP camera in coimbatore.",
      p2: "Experience advanced features, including remote access and smart analytics.",
      p3: "Enjoy superior image quality with our high-definition <b>IP cameras.</b>",
      p4: "Empower your security with seamless IP camera installation, offering remote access and control for your surveillance system in coimbatore.",
      p5: "Benefit from smart analytics that enhance the efficiency and effectiveness of your security.",
      p6: "Secure your space with our <b>IP DVR and NVR systems</b>, offering seamless recording and playback capabilities.",
      p7: "Maximize surveillance capabilities with cutting-edge IP camera technology in coimbatore. Explore seamless storage and retrieval of high-quality footage.",
      p8: "Transform your telecom network with the speed and reliability of fiber optics.",
    },
  ];
  const NVR = [
    {
      name: "NVR",
      key:"NVR Security Camera",
      img: "/images/NVRvector.webp",
      link: "https://infygain.com/onlinestore/products/ca/152",
      para: " Welcome to <b>NVR</b>, where cutting-edge technology meets comprehensive security solutions. Explore the pinnacle of video recording with our collection of the best <b>NVR (Network Video Recorder)</b> options, meticulously chosen to enhance and optimize your CCTV system.",
      p1: "DVR camera solutions at Infygain Technologies. Elevate your CCTV setup with our meticulously chosen DVR camera in coimbatore, designed for optimal video management. Our top-tier <b>DVR cameras</b> ensure efficient recording and playback capabilities, empowering your surveillance system to operate seamlessly.",
      p2: "Experience crystal-clear footage with our best NVR solutions. Designed to support high-resolution cameras, our NVRs capture every detail, providing you with clear and precise video recordings for security monitoring and analysis.",
      p3: "Opt for remote access and monitoring with the network integration features of our best NVR solutions. Stay connected to your CCTV system from anywhere, allowing you to monitor your property or business remotely for added peace of mind.",
      p4: "Choose scalable solutions for growing security needs with our best NVR options. Our recorders are designed to accommodate an increasing number of cameras and support additional features, ensuring your CCTV system remains effective as your security requirements expand.",
      p5: "Discover cutting-edge <b>DVR system in coimbatore</b> solutions for your surveillance needs. Explore a comprehensive range of NVR options featuring the latest technology, ideal for diverse surveillance setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best NVR based on your specific <b>CCTV system</b> requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in NVR technology and service.",
      p8: "Enhance your security infrastructure with cutting-edge <b>NVR solutions</b> from Infygain Technologies. Elevate your CCTV system's performance through seamless <b>DVR setup in coimbatore.</b>",
    },
  ];
  const DVR = [
    {
      name: "DVR",
      key:"DVR Security Camera",
      img: "/images/dvr vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/152",
      para: "Welcome to Infygain Technologies, where security meets innovation in the realm of CCTV solutions. Explore unparalleled recording capabilities with our collection of the best DVR (Digital Video Recorder) options, meticulously chosen to elevate your surveillance system to new heights.",
      p1: "Discover superior <b>DVR camera solutions</b> at Infygain Technologies, where we prioritize the pivotal role of a DVR in your CCTV system in coimbatore.",
      p2: "Experience high-quality video playback and storage with our best <b>DVR solutions</b>. Whether you need to review footage for security purposes or gather evidence, our DVRs deliver clear and detailed recordings for comprehensive monitoring.",
      p3: "Opt for seamless integration with advanced features available in our best DVR solutions. From remote access to intelligent analytics, our DVRs provide the tools needed to enhance your CCTV system's performance and functionality.",
      p4: "Future-proof your surveillance with our best <b>DVR solutions in Coimbatore</b>, tailored for scalability. Our DVRs seamlessly integrate with IP cameras, offering a robust foundation for your evolving security needs.",
      p5: "Benefit from smart analytics that enhance the efficiency and effectiveness of your security.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best DVR based on your specific CCTV system requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in DVR technology and service.",
      p8: "Optimize your surveillance with top-tier DVR solutions in coimbatore at Infygain Technologies, enhancing your IP camera system's recording capabilities.",
    },
  ];
  const cctv360 = [
    {
      name: "360",
      key:"360 Degree CCTV Camera",
      img: "/images/360 vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/152",
      para: "Welcome to Infygain Technologies, where innovation meets comprehensive security solutions. Explore the forefront of surveillance technology with our collection of the best 360-Degree Cameras, meticulously chosen to provide panoramic views and complete coverage for your CCTV system.",
      p1: "Unparalleled security with our <b>360-Degree Camera in Coimbatore</b>, setup at Infygain Technologies. Our carefully selected best-in-class cameras offer comprehensive monitoring, eliminating blind spots for total coverage.",
      p2: "Experience unparalleled surveillance with our best 360-Degree Cameras. Designed to capture panoramic views, these cameras enable you to monitor large areas with a single device, eliminating the need for multiple cameras and maximizing your surveillance capabilities.",
      p3: "Opt for detailed observation with high-resolution imaging capabilities of our best <b>360-Degree Cameras</b>. Whether you need to identify faces, license plates, or specific details, our cameras deliver clear and crisp footage for effective security monitoring.",
      p4: "Heightened security with our top-tier 360-Degree Outdoor Cameras in coimbatore. Harness the power of remote monitoring and advanced analytics to stay connected and vigilant from any location in coimbatore.",
      p5: "Explore a comprehensive range of <b>360-Degree</b> Cameras with the best technology for various surveillance setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best 360-Degree Camera based on your specific CCTV system requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in 360-Degree Camera technology and service.",
      p8: "Transform your CCTV system with cutting-edge <b>360-Degree Cameras </b>from Infygain Technologies. Explore the best-in-class 360 bikers camera in coimbatore to elevate surveillance capabilities.",
    },
  ];
  const PTZ = [
    {
      name: "PTZ",
      key:"PTZ CCTV Camera",
      img: "/images/ptz vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/152",
      para: "Welcome to Infygain Technologies, where dynamic technology meets superior security solutions. Explore the pinnacle of surveillance control with our collection of the best <b>PTZ (Pan-Tilt-Zoom) Cameras</b>, meticulously chosen to provide unmatched flexibility and precision for your CCTV system.",
      p1: "Discover unparalleled surveillance capabilities with our <b>best PTZ camera in coimbatore</b> at Infygain Technologies. Our carefully curated selection ensures dynamic control through pan, tilt, and zoom functionalities, empowering you to monitor specific areas with precision.",
      p2: "Experience comprehensive monitoring with our <b>best PTZ Cameras</b>. Offering 360-degree coverage, these cameras ensure no blind spots, allowing you to track subjects in real-time and enhance the overall security of your premises.",
      p3: "Opt for detailed observation with the zoom capabilities of our best PTZ Cameras. Whether you need to focus on specific details, faces, or distant objects, our cameras deliver precision and clarity to support effective security monitoring.",
      p4: "The epitome of surveillance sophistication with our CP Plus <b>PTZ camera in coimbatore</b>. Unlock automated surveillance capabilities featuring preset and pattern functions. Effortlessly program designated paths and monitoring patterns for hands-free operation.",
      p5: "Explore a comprehensive range of <b>PTZ Cameras</b> with the best technology for various surveillance setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best PTZ Camera based on your specific CCTV system requirements.",
      p7: "Community of satisfied customers who rely on our unwavering commitment to delivering cutting-edge PTZ camera technology in Coimbatore and exceptional service. Explore the unparalleled features of our PTZ camera in coimbatore, designed for precision and versatility.",
      p8: "Elevate your <b>CCTV system's</b> capabilities with the best PTZ Cameras available at Infygain Technologies.",
    },
  ];

  const biometric = [
    {
      name: "Bio-Metric",
      key:"Bio-Metric Machine",
      img: "/images/biometric-vector.webp",
      link: "https://infygain.com/onlinestore/products/",
      para: "Welcome to Infygain Technologies, your premier destination for cutting-edge <b>biometric security solutions</b>. Step into the future of access control and identity verification with our state-of-the-art biometric systems. Our lineup features the latest in fingerprint, facial recognition, and iris scanning technology, meticulously crafted to deliver unparalleled accuracy and reliability.",
      p1: "This is one of the oldest and most widely used biometric modalities. It involves capturing and analyzing the unique patterns of ridges and valleys on a person's fingertip.",
      p2: "Facial recognition analyzes the unique features of a person's face such as the distance between the eyes, nose, and mouth to verify or identify individuals.",
      p3: "This modality involves scanning the intricate patterns in the iris of the eye. Iris recognition systems use near-infrared light to capture high-resolution images of the iris.",
      p4: "Voice recognition systems analyze the unique characteristics of an individual's voice, including pitch, tone, and rhythm, to identify or verify the person's identity.",
      p5: "Similar to fingerprint recognition, palmprint recognition analyzes the unique patterns present on the palm of the hand.",
      p6: "Retina recognition systems scan the unique patterns of blood vessels located at the back of the eye. It's highly accurate but requires close proximity to the scanning device.",
      p7: "Vein recognition involves analyzing the unique patterns of veins in the hand or finger. It's often used in combination with other biometric modalities for enhanced security.",
      p8: "Gait recognition analyzes the unique walking pattern of an individual, including factors like stride length, pace, and posture, to identify or verify the person.",
    },
  ];
  const boombar = [
    {
      name: "Boom Barrier",
      key:"Boom Barrier",
      img: "/images/Boom-bar-vector.webp",
      link: "https://infygain.com/onlinestore/products/",
      para: "Welcome to Infygain Technologies, your ultimate destination for advanced <b>boom barrier solutions</b> that redefine security and access control. Step into a realm where reliability meets innovation as we introduce you to our cutting-edge boom barrier systems.",
      p1: "Install fingerprint scanners on the <b>boom barrier </b>control panel. Authorized users can simply place their fingertip on the scanner to verify their identity and gain access.",
      p2: " Incorporate facial recognition cameras into the <b>boom barrier system</b>. As vehicles approach, the system can capture images of occupants' faces and match them against a database of authorized individuals for seamless access.",
      p3: "While not strictly biometric, LPR systems can be used in conjunction with biometric authentication.",
      p4: "Equip the <b>boom barrier control panel</b> with voice recognition technology. When a visitor approaches, they can use the intercom system ",
      p5: "Install hand geometry scanners alongside the boom barrier. Authorized users can place their hand on the scanner, which measures the size and shape of their hand for identification purposes.",
      p6: "Implement mobile-based biometric authentication where users register their biometric data through a mobile app.",
      p7: " Issue access cards embedded with vein recognition technology. Users can hold their access cards near a reader as they approach the <b>boom barrier</b>, which scans the unique vein patterns in their hand for authentication.",
      p8: "For enhanced security, combine multiple biometric modalities or biometrics with other authentication factors such as PIN codes or RFID cards. This adds layers of security to the access control process.",
    },
  ];
  const accesscontrol = [
    {
      name: "Access Control",
      key:"Access Control",
      img: "/images/accesspoint-vector.webp",
      link: "https://infygain.com/onlinestore/products/",
      para: "Welcome to Infygain Technologies, where we redefine <b>access control </b>security solutions with innovation and reliability. Step into a realm of heightened security and seamless access management as we introduce you to our comprehensive range of cutting-edge access control systems.",
      p1: " Implement multi-factor authentication methods combining at least two factors such as biometrics, PIN codes, smart cards, or tokens to enhance security and prevent unauthorized access.",
      p2: "Utilize RBAC to assign permissions to users based on their roles and responsibilities within the organization, ensuring that individuals have access only to the resources necessary for their tasks.",
      p3: "Employ real-time monitoring capabilities to track access events and detect anomalies or security breaches promptly. Configure alerts to notify administrators of suspicious activities or unauthorized access attempts.",
      p4: " Encrypt user credentials stored in databases and during transmission between access control devices and servers to protect sensitive information from unauthorized access or interception.",
      p5: "Implement physical security measures such as tamper-resistant hardware, anti-passback controls, and secure enclosures for access control devices to prevent unauthorized tampering or bypassing of security mechanisms.",
      p6: " Conduct regular security audits and compliance checks to assess the effectiveness of access control measures, identify vulnerabilities, and ensure adherence to industry regulations and standards.",
      p7: "Establish automated processes for user provisioning (onboarding) and deprovisioning (offboarding) to promptly grant or revoke access rights as employees join or leave the organization, reducing the risk of insider threats.",
      p8: "Implement disaster recovery measures and redundant systems to ensure continuity of access control operations in case of system failures, natural disasters, or cyberattacks, minimizing downtime and data loss.",
    },
  ];

  const accessories = [
    {
      name: "RAM",
      key:"RAM",
      img: "/images/ram vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your trusted destination for the best RAM (Random Access Memory) solutions that redefine the way your computer performs. As a crucial component of your system, choosing the right RAM is paramount for optimal speed and efficiency. Explore why our RAM stands out among computer accessories at the best RAM service centre in Coimbatore.",
      p1: "Unleash your computer's full potential with our premium selection of high-performance RAM modules.Elevate your gaming, creative tasks, or business operations with lightning-fast performance and seamless multitasking. Choose the best RAM for unparalleled efficiency and power.",
      p2: "Our best RAM solutions are designed to deliver unmatched speed and responsiveness. Say goodbye to lag and delays, and enjoy a computing experience that keeps up with your demands.",
      p3: "Choose from a range of memory capacities to suit your specific needs. Our best RAM modules offer scalable options, ensuring that you have the right amount of memory for your tasks, big or small.",
      p4: "Count on our RAM solutions for reliability and stability. We prioritize quality to ensure that your system runs smoothly without interruptions, providing a dependable foundation for your computing needs. Choose us as your trusted RAM provider in Coimbatore for seamless performance and enhanced computing efficiency.",
      p5: "Stay ahead in technology with our cutting-edge RAM solutions for enhanced performance.Elevate your computing experience and boost efficiency with the best RAM technology. Trust us for top-notch performance and reliability.",
      p6: "Our knowledgeable team is here to assist you in choosing the ideal RAM configuration for your system. Optimize your system's performance with large storage RAM options tailored to meet your computing needs.",
      p7: "Join our satisfied customers who rely on our best RAM solutions for a superior computing experience.",
      p8: "Elevate your computer's performance with the best RAM solutions available at Infygain Technologies.",
    },
    {
      name: "HDD,SSD",
      key:"HDD,SSD",
      img: "/images/HDD & SDD Vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your go-to destination for the best in storage solutions. Whether you're looking for high-capacity storage or lightning-fast performance, our selection of<b> HDDs (Hard Disk Drives) </b>and SSDs (Solid State Drives) caters to all your computing needs. Explore top-tier SSD service in Coimbatore for enhanced storage performance and reliability.        ",
      p1: "Explore our range of the best <b>HDDs</b>, offering ample storage capacity for your data. From personal files to business applications, our HDDs provide reliable and cost-effective storage solutions for every user.",
      p2: "Experience the next level of speed with our selection of the best SSDs repair service in coimbatore. Boost your system's responsiveness, reduce load times, and enjoy seamless multitasking with these cutting-edge solid-state drives.        ",
      p3: "Whether you prioritize storage capacity or speed, our collection of <b>HDDs and SSDs </b>provides versatile options to suit your specific requirements. Choose the best storage solution tailored to your computing needs",
      p4: "Our commitment to quality ensures that you get the best in reliability and durability. Trust our storage solutions to safeguard your data and deliver consistent performance over time.",
      p5: "Explore a diverse range of <b>HDDs</b> and external SSDs drive to find the best fit for your computing requirements.        ",
      p6: "Our knowledgeable team is ready to assist you in selecting the high storage solution for your specific needs",
      p7: "Stay ahead with our commitment to providing the best in storage technology for enhanced performance",
      p8: "Elevate your computing experience with the <b>best HDDs and SSDs </b>available at Infygain Technologies. ",
    },
    {
      name: "Keyboard",
      key:"Keyboard",
      img: "/images/keyboard vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, where we redefine your computing experience with the best <B>keyboard sales in coimbatore</b>. A keyboard is more than just a peripheral – it's your interface to productivity, creativity, and seamless interaction with your device. Discover why our keyboards stand out among computer accessories.",
      p1: "Experience unparalleled comfort with our range of the <b>best keyboards</b>. Ergonomically designed and responsive, our keyboards ensure a comfortable typing experience, reducing strain during long hours of use.",
      p2: " Our commitment to quality ensures that you get the best in precision and durability. Each keystroke is reliable, providing consistent performance over time. Our<b> keyboards </b>are built to withstand the demands of daily use.",
      p3: "Whether you're a gamer, a professional, or a casual user, our selection of the<b> best keyboards </b>caters to all. Enjoy customizable features, programmable keys, and versatile designs that suit your specific needs.",
      p4: "Illuminate your workspace with our backlit <b>keyboards</b>. Choose from a variety of customizable lighting options to match your style and enhance visibility, even in low-light conditions.",
      p5: "Explore a diverse range of keyboards to find the best fit for your typing preferences. Elevate your typing experience with our comprehensive selection of high-quality and ergonomic keyboards.",
      p6: "Stay ahead with our commitment to providing the best in keyboard technology for enhanced performance.",
      p7: "Join our satisfied customers who rely on our <b>best keyboards</b> for a superior typing experience.",
      p8: "Elevate your typing experience with the <b>best keyboards</b> available at Infygain Technologies in Coimbatore.        ",
    },
    {
      name: "Mouse and Monitor",
      key:"Mouse and Monitor",
      img: "/images/mouse monitor vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your destination for the best in computer accessories. Dive into a world of precision, clarity, and seamless interaction with our exceptional mouse and monitor offerings. Explore top-notch mouse and monitor service in Coimbatore for enhanced performance and reliability.        ",
      p1: " Transform your computing experience with the best mouse designed for precision and comfort. Our ergonomic designs ensure a comfortable grip, while advanced sensors deliver accurate and responsive tracking. Choose from a variety of customizable options to suit your unique preferences.",
      p2: " Immerse yourself in stunning visuals with our collection of the best monitors. From vibrant colors to sharp resolutions, our monitors redefine clarity and deliver an unparalleled viewing experience. Whether you're a gamer, designer, or professional, our monitors cater to diverse needs.",
      p3: "Our commitment to providing the best extends to offering versatile options. Explore a variety of pointing devices with programmable buttons and customizable features. Select from monitors with different sizes, resolutions, and refresh rates to match your specific requirements.",
      p4: "Stay ahead with our dedication to cutting-edge technology. Our pointing devices and monitors feature the latest advancements, ensuring that you experience the best in performance, connectivity, and functionality.",
      p5: "Explore a range of pointing devices and monitors to find the best fit for your computing needs.",
      p6: "Our knowledgeable team is ready to assist you in selecting the best accessories for your specific requirements. Explore top-notch options with our monitor sales in Coimbatore for an enhanced visual experience tailored to your needs.        ",
      p7: "Join our satisfied customers who rely on our best pointing devices and computer monitors for an enhanced computing experience.",
      p8: "Elevate your computing setup with the Wireless mouse and computer monitor available at Infygain Technologies.",
    },
    {
      name: "Cabinet",
      key:"Cabinet",
      img: "/images/cabinet vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your ultimate destination for the best in computer accessories. Transform your computing experience with our exceptional computer cabinets, designed for optimal organization and style. Discover top-tier cabinet service in Coimbatore for enhanced functionality and aesthetic appeal.        ",
      p1: " Explore our range of the best computer cabinets that redefine storage solutions. From sleek designs to spacious interiors, our cabinets offer a perfect blend of functionality and aesthetics.",
      p2: "Upgrade your workspace with the best cabinets designed for precise organization. Our cabinets feature strategically placed compartments and cable management solutions to keep your setup tidy and efficient.",
      p3: "Experience superior airflow and ventilation with our selection of the best computer cabinets. We prioritize cooling solutions to ensure that your components operate at optimal temperatures, reducing the risk of overheating.",
      p4: "Invest in durability and style with our best computer cabinets. Crafted from high-quality materials, our cabinets not only stand the test of time but also add a touch of elegance to your workspace.",
      p5: " Explore a range of cabinets at low prices to find the best fit for your computing needs.        ",
      p6: "Our knowledgeable team is ready to assist you in selecting the best storage cabinet for your specific requirements.",
      p7: " Join our satisfied customers who rely on our best cabinets for an organized and stylish computing space.",
      p8: "Elevate your workspace with the best computer cabinets available at Infygain Technologies in Coimbatore.",
    },
    {
      name: "Motherboard",
      key:"Motherboard", 
      img: "/images/mother board vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, where we bring you the best in computer accessories, starting with our exceptional selection of motherboards. As a critical component of your system, our motherboards are crafted to deliver top-tier performance, reliability, and compatibility. Explore superior motherboard services in Coimbatore for an enhanced computing experience.",
      p1: "Explore our range of the best motherboards designed to elevate your computing experience. Our selection caters to a variety of needs, from gaming to professional workstations, ensuring optimal performance in every scenario.",
      p2: "Upgrade your system with the best motherboards that prioritize connectivity and compatibility. Our motherboards support the latest hardware and provide a range of ports for seamless integration of peripherals.",
      p3: "Discover motherboards that are optimized for speed and efficiency, providing faster data transfer rates and improved overall system responsiveness. Our best motherboards leverage advanced technologies to keep your system running at its peak. Choose us for reliable motherboard repair services to ensure seamless performance and longevity.",
      p4: " For gaming enthusiasts, our best gaming motherboards deliver the power and features you need for an immersive gaming experience. Unlock the full potential of your gaming rig with cutting-edge technology and performance-driven designs.",
      p5: "Explore a range of cabinets to find the best fit for your computing needs.",
      p6: "Explore a comprehensive range of motherboards to find the best fit for your specific needs.",
      p7: " Join our satisfied customers who rely on our best motherboards for exceptional performance and reliability.",
      p8: "Elevate your computing experience with the best motherboards available at Infygain Technologies in Coimbatore.",
    },
    {
      name: "SMPS",
      key:"SMPS",
      img: "/images/smps-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your ultimate destination for top-notch computer accessories. Explore the realm of optimal power delivery with our exceptional SMPS (Switched Mode Power Supply) units. We understand the critical role that power plays in computing, and our commitment is to provide you with the best SMPS in coimbatore for a reliable and efficient power supply.",
      p1: "Discover our curated selection of the best SMPS units designed to ensure a stable and uninterrupted power supply to your computer. Our offerings are crafted to meet the demands of modern computing, providing consistent and clean power to your system components.",
      p2: "Experience efficiency and energy savings with our best SMPS units. Our power supplies are optimized to deliver power precisely where and when it's needed, reducing energy consumption and lowering your electricity bills.",
      p3: "Explore a comprehensive range of online  SMPS units to find the best fit for your specific computer requirements.",
      p4: "Invest in the best SMPS units that prioritize the safety of your computer components. Our power supplies come equipped with advanced protection features, including over-voltage protection, short-circuit protection, and more, ensuring the longevity of your hardware.",
      p5: "Explore a comprehensive range of best online SMPS units to find the best fit for your specific computer requirements.        ",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best SMPS for your system.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in power supply solutions.",
      p8: "  Elevate your computing setup with the best SMPS units available at Infygain Technologies. ",
    },
    {
      name: "Cables and Chargers",
      key:"Cables and chargers",
      img: "/images/cable vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your go-to destination for cutting-edge computer accessories. Dive into a world of seamless connectivity with our carefully curated selection of the best connectors and converters. Our commitment is to provide you with accessories that redefine versatility, ensuring that your devices connect effortlessly. Explore top-notch cables and chargers service in Coimbatore for enhanced connectivity solutions.",
      p1: " Explore our range of the best cables designed to offer unmatched connectivity for your devices. From high-speed data transfer cables to reliable power cables, our selection ensures optimal performance and durability.",
      p2: "Discover chargers engineered for efficiency and speed. Our best chargers are designed to power up your devices quickly and reliably, ensuring that you stay connected and productive without interruption.",
      p3: "Choose from our versatile cable solutions that cater to various connectivity needs. Our collection includes HDMI cables, USB-C cables, charging cables, and more, providing you with the best tools for connecting your devices seamlessly.",
      p4: "Experience the durability of our cables and the convenience of tangle-free designs. Our best cables are built to withstand daily use, and their tangle-free designs ensure hassle-free handling and storage.",
      p5: "Explore a comprehensive range of cables and chargers to find the best fit for your specific needs.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best accessories for your devices.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in connectivity solutions.",
      p8: "Elevate your connectivity and charging experience with the best cables and chargers available at Infygain Technologies in Coimbatore.",
    },
    {
      name: "Connectors and Converters",
      key:"Connector and Converter",
      img: "/images/conector convertor vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/173",
      para: "Welcome to Infygain Technologies, your premier destination for cutting-edge computer accessories. Step into the world of seamless connectivity with our curated collection of the best connectors and convertors in coimbatore. We understand the importance of reliable connections and efficient charging, and our commitment is to provide you with accessories that redefine convenience.",
      p1: "Discover our range of the best connectors designed to establish reliable links between your devices. Whether it's USB connectors, audio connectors, or other interface connectors, our selection ensures stable and efficient connections.",
      p2: "Explore converters engineered for compatibility and flexibility. Our best converters are designed to bridge the gap between different devices and formats, ensuring that you can use your devices without limitations.",
      p3: "Choose from our versatile solutions that cater to a variety of connectivity needs. Our collection includes HDMI converters, USB adapters, audio splitters, and more, providing you with the best tools to adapt to any technological environment. Explore top-notch wired network services for seamless connectivity solutions tailored to your needs.",
      p4: "Experience durability and compactness with our connectors and converters. Our best accessories are built to withstand daily use, and their compact designs ensure easy portability for on-the-go connectivity.",
      p5: "Explore a comprehensive range of ethernet cables and  vga to hdmi adapter chargers to find the best fit for your specific needs.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best accessories for seamless connectivity.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in connectivity solutions.",
      p8: " Elevate your connectivity experience with the best connectors and converters available at Infygain Technologies in coimbatore.        ",
    },
  ];
  const projectors = [
    {
      name: "LCD",
      key:"LCD Projector",
      img: "/images/lcdvector.webp",
      link: "https://infygain.com/onlinestore/products/ca/190",
      para: " Welcome to Infygain Technologies, your premier destination for cutting-edge projection technology. Step into a world of vivid and immersive visuals with our collection of the best LCD projectors. Our commitment is to redefine your viewing experience, ensuring unparalleled clarity, brightness, and color accuracy. Explore top-notch LCD service centre in Coimbatore for reliable maintenance and support.",
      p1: "Explore our carefully curated selection of the best LCD projectors, meticulously chosen to deliver superior visuals for your presentations, entertainment, or educational needs. Witness vibrant colors, sharp resolutions, and detailed images that captivate your audience.",
      p2: "Experience crystal-clear presentations and entertainment with our best LCD projectors. Whether you're delivering a business presentation or enjoying a movie night at home, our projectors ensure that every detail is projected with precision.",
      p3: "Choose from our versatile range of LCD projectors that cater to various settings and requirements. Our collection includes portable projectors for on-the-go presentations and high-lumen projectors for large venues, offering the best solutions for diverse needs.",
      p4: "Stay ahead with our commitment to cutting-edge LCD technology. Our best projectors leverage advancements such as high refresh rates, dynamic contrast ratios, and advanced color processing for an immersive and lifelike viewing experience.",
      p5: "Explore a comprehensive range of LCD projectors to find the best fit for your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best LCD projector based on your intended use. Explore top-tier options with superior LCD resolution for crystal-clear visuals and an immersive viewing experience.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in LED projection technology and service in coimbatore. ",
      p8: "Elevate your visual experiences with the best LCD projectors available at Infygain Technologies.",
    },
    {
      name: "LED",
      key:"LED Projector",
      img: "/images/led vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/190",
      para: "Welcome to Infygain Technologies, your ultimate destination for cutting-edge projection technology. Step into a world of brilliance and clarity with our collection of the best LED projectors in coimbatore. Our commitment is to redefine your visual experiences, ensuring vibrant colors, exceptional brightness, and energy-efficient performance. ",
      p1: "Explore our carefully curated selection of the best LED projectors, meticulously chosen to deliver brilliant visuals for your presentations, entertainment, or educational needs. Witness stunning color accuracy, sharp resolutions, and immersive images that captivate your audience.",
      p2: " Experience vivid presentations and entertainment with our best LED projectors. Whether you're delivering a business presentation or enjoying a movie night at home, our projectors ensure that every detail is illuminated with precision and clarity.",
      p3: "Choose from our versatile range of LED projectors that cater to various settings and requirements. Our collection includes portable projectors for on-the-go presentations and high-lumen projectors for large venues, offering the best solutions for diverse needs.",
      p4: "Stay ahead with our commitment to energy-efficient LED technology. Our best projectors not only deliver superior visuals but also ensure cost-effective and eco-friendly operation, making them a sustainable choice for your projection needs. Explore top-tier LED repair services in coimbatore for reliable maintenance and support. ",
      p5: "Explore a comprehensive range of LED projectors to find the best fit for your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best LED projector based on your intended use.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in projection technology and service.",
      p8: "Elevate your visual experience with the best portable LED projectors available at Infygain Technologies.",
    },
    {
      name: "Smart Projectors",
      key:"Smart Projector",
      img: "/images/smart projector vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/190",
      para: " Welcome to Infygain Technologies, your premier destination for cutting-edge projection technology. Step into a world of innovation and connectivity with our collection of the best smart projectors. Our commitment is to redefine your visual experiences, ensuring not only stunning visuals but also seamless integration with smart technologies.",
      p1: "Explore our carefully curated selection of the best smart projectors, meticulously chosen to deliver intelligent presentations for your business meetings, educational sessions, or entertainment needs. Witness brilliance in both visuals and smart functionality that elevates your projection experience. Discover top-notch smart projectors in Coimbatore for cutting-edge technology and seamless integration. ",
      p2: "Experience connectivity redefined with our best freestyle projectors. Whether you're casting presentations wirelessly, streaming content from your favorite platforms, or engaging in interactive applications, our projectors seamlessly integrate with smart technologies for a truly modern experience.        ",
      p3: "Choose from our versatile range of smart projectors that cater to various settings and requirements. Our collection includes portable smart projectors for on-the-go professionals and feature-rich models for home entertainment, offering the best solutions for diverse needs.",
      p4: "Stay ahead with intuitive controls and user-friendly interfaces on our best smart projectors. Navigate effortlessly through settings, applications, and connectivity options, making your projection experience smooth and enjoyable.",
      p5: "Explore a comprehensive range of smart wireless projectors to find the best fit for your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best smart projector based on your intended use.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in smart projection technology and service.",
      p8: "Elevate your visual experiences with the best smart projectors available at Infygain Technologies in coimbatore. ",
    },
    {
      name: "TV",
      key:"TV",
      img: "/images/android tv vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/22",
      para: "Welcome to Infygain Technologies, your destination for an unparalleled entertainment experience. Dive into the world of innovation with our collection of the best TVs, meticulously chosen to redefine the way you enjoy content on the big screen.",
      p1: " Immerse yourself in a world of entertainment with our premium selection of TVs. Elevate your living room into a smart hub, seamlessly integrating TV for instant access to a diverse range of content, apps, and games directly from your television.",
      p2: " Experience vivid visuals and smart features with our best TVs. The cutting-edge technology ensures stunning picture quality, while the TV platform provides you with a user-friendly interface, voice control, and access to a vast array of streaming services.",
      p3: " Choose from our versatile range of TVs that cater to various preferences and requirements. Our collection includes different screen sizes, resolutions, and features, offering the best solutions for diverse entertainment needs.",
      p4: " Stay ahead with intuitive controls and user-friendly interfaces on our best TVs. Navigate effortlessly through your favorite apps, switch between content, and explore a world of entertainment with ease.",
      p5: " Entertainment with the latest TV technology, offering a diverse array of options to cater to your specific requirements. Choose from a variety of TV boxes in Coimbatore to transform your TV into a smart hub, bringing endless streaming and interactive possibilities to your fingertips.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the Smart TV based on your preferences.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in TV technology and service.",
      p8: " The best touch TVs at Infygain Technologies. Immerse yourself in a world of interactive brilliance with our cutting-edge touch TV in Coimbatore collection. Elevate your viewing experience with seamless integration, intuitive controls, and advanced features.",
    },
    {
      name: "InteractivePanel",
      key:"Interactive Panel",
      img: "/images/intercom-vector.webp",
      link: "https://infygain.com/onlinestore/products/",
      para: " Welcome to Infygain Technologies, where collaboration meets innovation. Step into a world of interactive excellence with our collection of the best 65,75-inch interactive panels. Our commitment is to redefine the way you collaborate, present, and engage with cutting-edge technology that brings your ideas to life. Explore top-notch interactive panels service in Coimbatore for reliable maintenance and support. ",
      p1: "Explore our carefully curated selection of the best 65,75-inch interactive panels, meticulously chosen to deliver immersive collaboration experiences. Whether you're conducting dynamic presentations, interactive lessons, or collaborative  brainstorming sessions, our panels provide a canvas for creativity.",
      p2: " Experience vivid visuals and responsive touch technology with our best interactive panels. The 65,75-inch size ensures that your content is displayed with clarity, while the touch functionality allows for seamless interaction,making presentations and lessons engaging and dynamic. ",
      p3: "Choose from our versatile range of 65,75-inch interactive panels that cater to various settings and requirements. Our collection includes feature-rich panels suitable for classrooms, boardrooms, and collaborative workspaces, offering the best solutions for diverse needs.",
      p4: "Stay ahead with intuitive controls and user-friendly interfaces on our best interactive panels.Navigate effortlessly through interactive applications, annotate on the screen, and utilize collaborative tools with ease, making your presentations and collaborations smooth and productive.",
      p5: "Explore a comprehensive range of 65,75-inch interactive panels to find the best fit for your specific collaboration requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best interactive touch screen panel based on your intended use. ",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in interactive panel technology and service in coimbatore.",
      p8: "Elevate your collaborative experiences with the best 65,75-inch interactive panels available at Infygain Technologies.",
    },
  ];
  const interactivepanel = [
    {
      name: "65-Inch Interactive Panels",
      img: "/images/interactivepanel.webp",
      link: "https://infygain.com/onlinestore/products/ca/194",
      para: " Welcome to Infygain Technologies, where collaboration meets innovation. Step into a world of interactive excellence with our collection of the best 65-inch interactive panels. Our commitment is to redefine the way you collaborate, present, and engage with cutting-edge technology that brings your ideas to life. Explore top-notch interactive panels service in Coimbatore for reliable maintenance and support. ",
      p1: "Explore our carefully curated selection of the best 65-inch interactive panels, meticulously chosen to deliver immersive collaboration experiences. Whether you're conducting dynamic presentations, interactive lessons, or collaborative  brainstorming sessions, our panels provide a canvas for creativity.",
      p2: " Experience vivid visuals and responsive touch technology with our best interactive panels. The 65-inch size ensures that your content is displayed with clarity, while the touch functionality allows for seamless interaction,making presentations and lessons engaging and dynamic. ",
      p3: "Choose from our versatile range of 65-inch interactive panels that cater to various settings and requirements. Our collection includes feature-rich panels suitable for classrooms, boardrooms, and collaborative workspaces, offering the best solutions for diverse needs.",
      p4: "Stay ahead with intuitive controls and user-friendly interfaces on our best interactive panels.Navigate effortlessly through interactive applications, annotate on the screen, and utilize collaborative tools with ease, making your presentations and collaborations smooth and productive.",
      p5: "Explore a comprehensive range of 65-inch interactive panels to find the best fit for your specific collaboration requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best interactive touch screen panel based on your intended use. ",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in interactive panel technology and service in coimbatore.",
      p8: "Elevate your collaborative experiences with the best 65-inch interactive panels available at Infygain Technologies.",
    },
    {
      name: "75-Inch Interactive Panels",
      img: "/images/interactivepanel.webp",
      link: "https://infygain.com/onlinestore/products/ca/194",
      para: "Welcome to Infygain Technologies, where innovation meets collaboration on a grand scale. Immerse yourself in a world of interactive excellence with our collection of the best 75-inch interactive panels. Our commitment is to redefine the way you collaborate, present, and engage with cutting-edge technology that provides a commanding canvas for your ideas.",
      p1: "Explore our meticulously curated selection of the best 75-inch interactive panels, chosen to deliver an immersive collaboration experience. Whether you're conducting impactful presentations, interactive lessons, or collaborative brainstorming sessions, our panels provide a vast canvas for creativity.",
      p2: "Experience vivid visuals and responsive touch technology with our best interactive panels. The expansive 75-inch size ensures that your content is displayed with crystal clarity, while the responsive touch functionality allows for seamless interaction, making presentations and lessons engaging and dynamic.",
      p3: "Choose from our versatile range of 75-inch interactive panels that cater to various settings and requirements. Our collection includes feature-rich panels suitable for classrooms, boardrooms, and collaborative workspaces, offering the best solutions for diverse needs.",
      p4: "Stay ahead with intuitive controls and user-friendly interfaces on our best 75- inch interactive panels. Navigate effortlessly through interactive applications, annotate on the screen, and utilize collaborative tools with ease, making your presentations and collaborations smooth and productive.",
      p5: "Explore a comprehensive range of 75-inch interactive panels to find the best fit for your specific collaboration requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best interactive panel based on your intended use",
      p7: "Join our satisfied customers who trust in our commitment to providing the best interactive touch screen display technology and service. ",
      p8: "Elevate your collaborative experiences with the best 75-inch interactive panels available at Infygain Technologies in Coimbatore. .",
    },
  ];
  const tv = [
    {
      name: "Android TV",
      img: "/images/android tv vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/151",
      para: "Welcome to Infygain Technologies, your destination for an unparalleled entertainment experience. Dive into the world of innovation with our collection of the best Android TVs, meticulously chosen to redefine the way you enjoy content on the big screen.",
      p1: " Immerse yourself in a world of entertainment with our premium selection of Android TV in Coimbatore. Elevate your living room into a smart hub, seamlessly integrating Android TV for instant access to a diverse range of content, apps, and games directly from your television.",
      p2: " Experience vivid visuals and smart features with our best Android TVs. The cutting-edge technology ensures stunning picture quality, while the Android TV platform provides you with a user-friendly interface, voice control, and access to a vast array of streaming services.",
      p3: " Choose from our versatile range of Android TVs that cater to various preferences and requirements. Our collection includes different screen sizes, resolutions, and features, offering the best solutions for diverse entertainment needs.",
      p4: " Stay ahead with intuitive controls and user-friendly interfaces on our best Android TVs. Navigate effortlessly through your favorite apps, switch between content, and explore a world of entertainment with ease.",
      p5: " entertainment with the latest Android TV technology, offering a diverse array of options to cater to your specific requirements. Choose from a variety of Android TV box in coimbatore to transform your TV into a smart hub, bringing endless streaming and interactive possibilities to your fingertips.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the Smart Android TV based on your preferences.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in Android TV technology and service.",
      p8: " The best Android touch TVs at Infygain Technologies. Immerse yourself in a world of interactive brilliance with our cutting-edge Android touch TV in Coimbatore collection. Elevate your viewing experience with seamless integration, intuitive controls, and advanced features.",
    },
    {
      name: "Smart TV",
      img: "/images/smart-tv vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/151",
      para: "  Welcome to Infygain Technologies, where innovation meets entertainment. Immerse yourself in a world of cutting-edge technology with our collection of the best A-Smart TVs, meticulously selected to redefine your television experience.",
      p1: "the epitome of entertainment with the best smart TVs at Infygain Technologies. Immerse yourself in interactive brilliance as our cutting-edge smart TV in Coimbatore, redefine your viewing experience. Elevate your entertainment with seamless integration, intuitive controls, and advanced features.",
      p2: "Experience vivid visuals and smart capabilities with our best A-Smart TVs. Enjoy stunning picture quality and immerse yourself in a world of content, apps, and connectivity options that transform your TV into a central hub for entertainment.",
      p3: "Choose from our versatile range of A-Smart TVs that cater to various preferences and requirements. Our collection includes different screen sizes, resolutions, and features, providing the best solutions for diverse entertainment needs.",
      p4: " Stay ahead with intuitive controls and user-friendly interfaces on our best A-Smart TVs. Navigate effortlessly through your favorite apps, switch between content, and explore the intelligence of A-Smart technology with ease.",
      p5: "Entertainment needs with our extensive collection of A-Smart TVs. Explore a variety of Smart TV in Coimbatore, options that cater to your specific preferences. Find excellence in picture quality, innovative features. Immerse yourself in cutting-edge technology.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best A-Smart TV based on your preferences.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in A-Smart TV technology and service.",
      p8: "A-Smart TVs by Infygain Technologies. Explore the best in smart TV technology for an elevated viewing experience. Discover the perfect smart TV for your home in coimbatore, combining cutting-edge features and superior performance. Upgrade your leisure with Infygain's selection of smart TV.",
    },
  ];
  const ups = [
    {
      name: "Inventor",
      key:"Inventor",
      img: "/images/inventorfinal.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, your trusted partner in ensuring uninterrupted power supply through cutting-edge UPS technology. Explore a world of innovation and reliability with our collection of the best inventors in the UPS industry, designed to safeguard your critical systems and sensitive equipment.",
      p1: "At Infygain Technologies, we take pride in offering the best inventors in UPS technology, meticulously chosen to provide unrivaled reliability. Our UPS solutions are crafted with precision, ensuring seamless transitions during power outages and protection against voltage fluctuations. Trust our expert team for top-notch UPS installation service in Coimbatore, ensuring your uninterrupted power supply system is set up with precision and care.",
      p2: "Experience innovative features that elevate the performance of our best UPS inventors. From advanced battery management systems to intelligent monitoring capabilities, our UPS solutions are designed to meet the dynamic power needs of modern businesses.  ",
      p3: " Choose from our versatile range of UPS solutions that cater to various applications and power capacities. Whether you need a UPS for your home office, a small business, or a large data center, our collection includes the best inventors to suit diverse requirements.",
      p4: "Trust in the best inventors in UPS technology to provide reliable protection for your sensitive equipment. Our UPS solutions safeguard against power surges, spikes, and outages, ensuring that your electronics and critical systems remain operational and protected. Explore top-notch UPS for home or office for uninterrupted power supply and peace of mind.",
      p5: "  Explore a comprehensive range of UPS solutions with the best inventors for various applications.Explore a comprehensive range of UPS solutions with the best invertors for various applications. Whether it's for home or office use, our UPS systems provide reliable power backup to ensure uninterrupted operations. Choose peace of mind with our advanced UPS for home or office applications.        ",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best UPS inventor based on your specific power needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in UPS technology and service in Coimbatore.",
      p8: "Ensure uninterrupted power for your critical systems with the best UPS inventors available at Infygain Technologies.  ",
    },
    {
      name: "Online UPS",
      key:"Online UPS",
      img: "/images/online ups vector.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, where we redefine uninterrupted power supply with our state-of-the-art Online UPS solutions. Explore a world of reliability and continuous power with the best Online UPS technology designed to safeguard your critical systems and ensure seamless operations",
      p1: " At Infygain Technologies, we take pride in offering the best Online UPS solutions meticulously chosen for unmatched reliability. Our Online UPS technology provides a continuous and clean power supply, protecting your sensitive equipment from power disruptions and ensuring uninterrupted performance., we take pride in offering the best Online UPS solutions meticulously chosen for unmatched reliability. Our Online UPS technology provides a continuous and clean power supply, protecting your sensitive equipment from power disruptions and ensuring uninterrupted performance.",
      p2: "Experience optimal performance with the advanced features integrated into our best Online UPS solutions. From double-conversion technology to precision voltage regulation, our UPS systems are designed to deliver consistent and reliable power to critical loads.",
      p3: "Choose from our versatile range of Online UPS solutions centre in coimbatore that cater to various applications and power capacities. Whether you need a UPS for your home office, a small business, or an enterprise-level data center, our collection includes the best options to suit diverse requirements.",
      p4: "Trust in the best Online UPS technology to provide enhanced protection for your critical systems. Our UPS solutions safeguard against power fluctuations, surges, and outages, ensuring the continuous operation of your equipment and preventing data loss.",
      p5: " Explore a comprehensive range of Online UPS solutions with the best technology for various applications. Ensure seamless power supply with our expert UPS installation services, providing reliable setups tailored to meet your specific requirements.",
      p6: " Rely on our UPS service Centre in Coimbatore for expert advice on selecting the best Online UPS based on your specific power needs.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in Online UPS technology and service.",
      p8: "Ensure a seamless and uninterrupted power supply for your critical systems with the best Online UPS solutions available at Infygain Technologies.   ",
    },
  ];
  const server = [
    {
      name: "Server Installation",
      key:"Server Installation Services",
      img: "/images/serverint-vector.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, your go-to destination for seamless <b>server installations service</b> backed by state-of-the-art technology. Dive into a realm of innovation and dependability with our premier selection of <b>server solutions</b>, meticulously crafted to fortify your essential systems and delicate infrastructure.",
      p1: "Before installation, our expert team conducts a thorough assessment of your server requirements, ensuring optimal performance tailored to your needs.",
      p2: "We meticulously select the best-in-class server hardware, considering factors such as processing power, storage capacity, and scalability to meet both current and future demands.",
      p3: "Each <b>server installation</b> is meticulously configured and customized to integrate seamlessly with your existing infrastructure, maximizing efficiency and compatibility.",
      p4: "Security is paramount. We implement robust security protocols and measures to safeguard your data and systems against potential threats and breaches.",
      p5: "Our technicians fine-tune server settings and parameters to ensure optimal performance, minimizing downtime and maximizing productivity.",
      p6: "Rigorous testing procedures are employed to verify the stability and reliability of the installed servers, guaranteeing smooth operation under varying workloads",
      p7: "Comprehensive documentation is provided along with hands-on training to your IT staff, empowering them to effectively manage and maintain the installed servers.",
      p8: "Our commitment doesn't end with installation. We offer proactive support and maintenance services to keep your servers running smoothly, providing peace of mind and uninterrupted operations.",
    },
    {
      name: "Server Maintenance",
      key:"Server Maintenance Service",
      img: "/images/servermain-vector.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, your premier destination for comprehensive <b>server maintenance solutions</b>. Step into a realm of excellence and reliability with our top-tier maintenance services, tailored to uphold the performance and integrity of your critical server infrastructure.",
      p1: "Experience optimal performance with our top-tier server maintenance solutions, featuring advanced monitoring and rapid response capabilities to ensure smooth server operation and minimize downtime.",
      p2: "Choose from our versatile range of server maintenance options, tailored to suit various applications and server capacities, whether you operate a single server setup or an enterprise-level data center.",
      p3: "Trust in our cutting-edge server maintenance technology to provide enhanced protection against hardware failures, software issues, and security threats, ensuring the continuous operation of your critical systems.",
      p4: "Explore our comprehensive range of <b>server maintenance Services</b>, equipped with the latest technology to address diverse application needs and ensure seamless server operation.",
      p5: "Rely on our expert advice and support from our service center in Coimbatore to select the most suitable maintenance plan based on your specific server requirements and business objectives.",
      p6: " Join our satisfied clientele who rely on our commitment to delivering top-notch server maintenance technology and service, backed by years of expertise and dedication.",
      p7: "Ensure uninterrupted operation for your critical systems with our reliable server maintenance solutions, designed to keep your servers running smoothly and safeguard against data loss or downtime.",
      p8: "Partner with Infygain Technologies to access the <b>best server maintenance solutions</b> available, providing you with peace of mind and confidence in the reliability and performance of your IT infrastructure.",
    },
    {
      name: "Web Server",
      key:"Web Server",
      img: "/images/webserver-vector.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, your ultimate destination for superior <b>web server solutions</b>. Embark on a journey of excellence and dependability with our cutting-edge web server offerings, meticulously designed to optimize performance and ensure the seamless operation of your online presence.",
      p1: " Experience optimal performance with our top-tier <b>web server</b> maintenance solutions, featuring advanced monitoring and rapid response capabilities to ensure smooth website operation and minimize downtime.",
      p2: "Choose from our versatile range of web server maintenance options, tailored to suit various website sizes and traffic volumes, whether you run a small business website or a high-traffic e-commerce platform",
      p3: "Trust in our cutting-edge web server maintenance technology to provide enhanced protection against cyber threats, software vulnerabilities, and performance issues, ensuring the continuous availability and security of your online presence.",
      p4: "Rely on our expert advice and support from our service center in <b>Coimbatore</b> to select the most suitable maintenance plan based on your specific website requirements and business objectives.",
      p5: " Explore a comprehensive range of Online UPS solutions with the best technology for various applications. Ensure seamless power supply with our expert UPS installation services, providing reliable setups tailored to meet your specific requirements.",
      p6: "Join our satisfied clientele who rely on our commitment to delivering top-notch web server maintenance technology and service, backed by years of expertise and dedication.",
      p7: " Ensure uninterrupted operation for your critical online systems with our reliable <b>web server maintenance solutions</b>, designed to keep your website running smoothly and safeguard against cyber attacks or performance degradation.",
      p8: "Partner with Infygain Technologies to access the best web server maintenance solutions available, providing you with peace of mind and confidence in the reliability and performance of your online infrastructure.",
    },
    {
      name: "Storage Server",
      key:"Storage Server",
      img: "/images/storageserver-vetor.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, your foremost destination for advanced <b>storage server solutions</b>. Embark on a journey of reliability and efficiency with our innovative storage server offerings, meticulously engineered to safeguard your data and optimize storage performance.",
      p1: " Experience optimal performance with our top-tier <b>storage server maintenance solutions</b>, featuring advanced monitoring and rapid response capabilities to ensure smooth data storage operations and minimize downtime.",
      p2: "Choose from our versatile range of <b>storage server maintenance</b> options, tailored to suit various data storage needs and capacities, whether you require storage for a small business data repository or an enterprise-level storage infrastructure.",
      p3: "Trust in our cutting-edge storage server maintenance technology to provide enhanced protection against data loss, hardware failures, and security breaches, ensuring the continuous availability and integrity of your stored data.",
      p4: "Explore our comprehensive range of <b>storage server</b> maintenance solutions, equipped with the latest technology to address diverse data storage requirements and ensure seamless data access and retrieval.",
      p5: " Rely on our expert advice and support from our <b>service center in Coimbatore</b> to select the most suitable maintenance plan based on your specific data storage needs and business objectives.",
      p6: "Join our satisfied clientele who rely on our commitment to delivering top-notch storage server maintenance technology and service, backed by years of expertise and dedication.",
      p7: "Ensure uninterrupted operation for your critical data storage systems with our reliable storage server maintenance solutions, designed to keep your data accessible and secure at all times, even in the face of hardware failures or cyber threats.",
      p8: "Partner with Infygain Technologies to access the best storage server maintenance solutions available, providing you with peace of mind and confidence in the reliability and performance of your <b>data storage infrastructure.",
    },
  ];

  const pos = [
    {
      name: "POS",
      key:"POS Machine Dealer",
      img: "/images/kioskvectorlasts.webp",
      link: "https://infygain.com/onlinestore/products/s/pos",
      para: "Looking for a reliable <b>POS machine dealer?</b> Look no further than Infygain Technologies! Our POS sales and service are second to none. Explore our cutting-edge POS systems for seamless business operations and exceptional customer experiences",
      p1: " At Infygain Technologies, we understand the crucial role of a <b>POS system</b> in modern business. Our carefully selected best POS solutions offer advanced features and user-friendly interfaces, ensuring smooth and efficient transactions for both your staff and customers.",
      p2: "Experience efficient order processing and inventory management with our <b>best POS machine solutions</b>. Our systems are designed to streamline the entire transaction process, from order entry to inventory tracking, allowing you to optimize stock levels and prevent stockouts. Explore top-notch POS system for small business, enhancing your operational efficiency and customer satisfaction.",
      p3: "Opt for secure payment processing with our <b>best POS system solutions</b>. We prioritize the security of transactions, providing your customers with confidence in the safety of their payments and enhancing the overall trustworthiness of your business.",
      p4: "Choose versatility with our best POS solutions tailored for various business environments. Whether you run a retail store, restaurant, or any other establishment, our POS systems can be customized to meet the specific needs of your industry.",
      p5: "Explore a comprehensive range of <b>POS machine solutions in Coimbatore</b> with the best technology for various business setups. ",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best POS system based on your specific business requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in POS technology and service Centre in Coimbatore. ",
      p8: " Optimize your business operations with the best POS solutions available at Infygain Technologies. ",
    },
    {
      name: "KIOSK",
      key:"Kiosk Dealers",
      img: "/images/kioskvectorlasts.webp",
      link: "https://infygain.com/onlinestore/products/s/pos",
      para: "Searching for a dependable <b>kiosk machine solution provider?</b> Your quest ends here at Infygain Technologies! From kiosk sales to unparalleled service, we're your go-to experts. Dive into our innovative kiosk systems, ensuring seamless operations and unforgettable customer interactions. Elevate your business with our comprehensive kiosk solutions today!",
      p1: "At Infygain Technologies, we understand the significance of efficient and interactive transactions at your POS. Our carefully selected best Kiosk solutions offer a cutting-edge way for customers to interact with your business, leading to streamlined processes and improved customer satisfaction.",
      p2: "Experience effortless transactions with our <b>best Kiosk machine solutions</b>. The user-friendly interfaces empower your customers to navigate through the kiosk, place orders, make payments, or access information easily, providing a smooth and enjoyable experience.  ",
      p3: "Optimize efficiency and order accuracy with the advanced features integrated into our <b>Kiosk solutions</b>. From intuitive menu selections to real-time order updates, our Kiosks are designed to deliver the best in customer service, reducing wait times and minimizing errors.",
      p4: "Choose from our versatile range of Kiosk solutions that cater to various POS setups and industries. Whether you operate a restaurant, a retail store, or a service-oriented business, our collection includes the best options to suit diverse customer interaction needs.",
      p5: "Explore a comprehensive range of <b>Kiosk Machine Services </b>with the best technology for various POS environments. Optimize your POS operations in restaurants or for your small business with our advanced POS system, enhancing efficiency and customer satisfaction.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best Kiosk based on your specific business requirements.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in Kiosk technology and service.",
      p8: " Elevate your customer service with the best Kiosk solutions available at Infygain Technologies in Coimbatore. ",
    },
    {
      name: "Barcode Scanner",
      key:"Barcode Scanner Dealers",
      img: "/images/barcode-vector.webp",
      link: "https://infygain.com/onlinestore/products/s/pos",
      para: "<b>Barcode scanners</b> have become indispensable tools in modern business operations, and at Infygain Technologies, we lead the charge in providing cutting-edge solutions. With our state-of-the-art barcode scanners, businesses can streamline their processes, enhance efficiency, and elevate customer experiences. From inventory management to point-of-sale transactions, our <b>code scanner</b> ensure seamless operations from start to finish. ",
      p1: " Barcode scanning automates the process of entering product information during transactions, reducing the time required for manual data entry and minimizing errors.",
      p2: "Scanning barcodes ensures accurate product identification and pricing, reducing the risk of human error in entering product codes or prices.",
      p3: " Barcode scanning enables real-time tracking of inventory levels as products are sold, helping businesses maintain optimal stock levels and prevent stockouts or overstocking.",
      p4: "With <b>barcode scanning</b>, checkout lines move more quickly as products can be scanned rapidly, leading to improved customer satisfaction and loyalty.",
      p5: "Integrating barcode scanning into billing applications streamlines various operational processes, from receiving shipments to restocking shelves, improving overall efficiency.",
      p6: "Barcode scanning generates valuable data on sales trends, popular products, and stock movement, which businesses can analyze to make informed decisions about inventory management and marketing strategies.",
      p7: "Barcode scanning is compatible with a wide range of products and industries, making it a versatile solution for businesses of all sizes and types, from retail stores to warehouses.",
      p8: "As technology advances, barcode scanning continues to evolve, with newer standards such as QR codes and RFID offering additional functionalities and capabilities for businesses to leverage in their billing processes.",
    },
    {
      name: "Table Top Barcode Scanner",
      key:"Table Top Barcode Scanner Dealer",
      img: "/images/tabletopbc-vector.webp",
      link: "https://infygain.com/onlinestore/products/s/pos",
      para: " Welcome to Infygain Technologies, where we bring innovation to your Point of Sale (POS) experience. Explore a world of efficiency and precision in financial transactions with our collection of the best Billing Apps designed to enhance your POS operations. Discover top-tier Best Billing Apps service centre in Coimbatore for seamless support and maintenance. ",
      p1: "Many <b>Table Top Barcode Scanners</b> offer hands-free operation, allowing users to simply place items with barcodes in front of the scanner for quick and convenient scanning without the need to manually handle the scanner.",
      p2: "Experience an effortless billing process with our best Billing Apps. The user-friendly interfaces make it easy for your staff to navigate through the app, enabling swift and error-free transactions that contribute to a positive customer experience.",
      p3: "Tabletop scanners are equipped with high-speed scanning capabilities, enabling rapid and efficient scanning of barcodes, even in busy retail environments with high transaction volumes.",
      p4: "<b>Tabletop barcode scanners</b> are designed to seamlessly integrate with existing POS systems and software, allowing for easy setup and compatibility with different hardware and software configurations.",
      p5: "Built to withstand the rigors of continuous use in commercial environments, tabletop barcode scanners are constructed with durable materials that ensure long-lasting performance and reliability.",
      p6: " Many tabletop scanners come with adjustable stands that allow users to customize the angle and height of the scanner for optimal ergonomics and user comfort during prolonged use.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Billing App technology and service.",
      p8: "Table top barcode scanners typically offer multiple connectivity options, such as USB, Bluetooth, or Wi-Fi, allowing for flexible connectivity to POS terminals, computers, or mobile devices for seamless data transfer and communication",
    },
    {
      name: "Billing Apps",
      key:"Billing Apps",
      img: "/images/billingapp.webp",
      link: "https://infygain.com/onlinestore/products/s/pos",
      para: "Welcome to Vyapar <b>Billing Apps</b>, where innovative technology meets seamless financial management. Our user-friendly apps are designed to simplify invoicing, inventory tracking, and expense management, catering to businesses of all sizes. Join thousands of satisfied users and streamline your financial processes with Vyapar today.",
      p1: " At Infygain Technologies, we understand the importance of seamless billing in POS. Our meticulously selected best Billing Apps ensure quick and accurate transactions, allowing you to focus on providing excellent service to your customers.",
      p2: "Experience an effortless billing process with our best Billing Apps. The user-friendly interfaces make it easy for your staff to navigate through the app, enabling swift and error-free transactions that contribute to a positive customer experience.",
      p3: "Explore comprehensive financial management with the advanced features integrated into our <b>Billing Apps Services</b>. From real-time sales tracking and inventory management to customized reporting, our apps are designed to deliver the best in financial control for your business.",
      p4: "Choose from our versatile range of <b>Billing Apps</b> that cater to various POS setups and industries. Whether you run a retail store, a restaurant, or a service-oriented business, our collection includes the best options to suit diverse billing needs.",
      p5: "Explore a comprehensive range of Billing Apps with the best technology for various POS environments.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Billing App based on your specific business requirements. Explore top-tier billing apps for your business, ensuring efficient financial transactions and streamlined operations.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Billing App technology and service.",
      p8: "Optimize your POS billing process with the best online <b>Billing Apps Solution</b> available at Infygain Technologies in Coimbatore.",
    },
  ];

  const intercom = [
    {
      name: "InterCom",
      key:"Intercom Services",
      img: "/images/intercom-vector.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, where communication meets innovation in the realm of intercom systems. Explore the pinnacle of connectivity and convenience with our collection of the best Intercom Systems, meticulously chosen to enhance communication within your premises and elevate security measures.",
      p1: " communication with Infygain Technologies' best Intercom Systems. Our carefully curated selection boasts advanced features, guaranteeing crystal-clear and reliable connectivity.",
      p2: "Experience versatility with our best Intercom Systems designed for various environments. Whether you need intercoms for residential buildings, commercial spaces, or industrial facilities, our systems can be tailored to meet the specific communication needs of your environment.",
      p3: " Opt for enhanced security with integration capabilities available in our best Intercom Systems. Seamlessly connect your intercoms with access control systems to monitor and control entry points, providing an additional layer of security for your premises.",
      p4: "Choose user-friendly features for intuitive operation with our best Intercom Systems. From touch-screen interfaces to mobile app integration, our systems are designed to be easily operated by users, ensuring efficient communication within your facility.",
      p5: " Intercom Systems for home in Coimbatore. communication setups. Our comprehensive range features the latest technology, ensuring seamless and secure communication within your home. Explore innovative intercom solutions designed for convenience and enhanced security. ",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Intercom System based on your specific communication and security requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Intercom technology and service.",
      p8: " Workplace communication and security with top-tier Intercom Systems at Infygain Technologies. Explore our cutting-edge intercom system for office in Coimbatore, designed to elevate connectivity and safety measures.",
    },
    {
      name: "EPABX",
      key:"EPABX Intercom Service",
      img: "/images/intercomvector-01.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, where seamless communication is a priority. Explore a world of enhanced connectivity and efficient intercom solutions with our collection of the <b>best EPABX systems</b>, meticulously chosen to elevate your communication infrastructure.",
      p1: "Unparalleled communication efficiency with Infygain Technologies' <b>Best EPABX systems in coimbatore</b>. Our meticulously selected solutions provide a robust and scalable platform for intercom communication. Elevate connectivity within your premises with seamless integration and rapid response times.",
      p2: "Experience intuitive communication with our best EPABX systems. The user-friendly interfaces make it easy for users to initiate intercom calls, transfer calls, and manage communication effectively, contributing to a smooth and streamlined operation.",
      p3: "Explore optimal performance with the advanced features integrated into our EPABX systems. From efficient call routing and seamless conferencing to reliable voicemail and comprehensive call logging,our systems are designed to deliver the best in intercom communication for various settings. ",
      p4: "  Choose from our versatile range of EPABX systems that cater to various setups and industries. Whether you need a solution for a corporate office, a healthcare facility, or an educational institution, our collection includes the best options to suit diverse intercom communication needs.",
      p5: "Edge technology with our <b>EPABX intercom system in coimbatore</b>, offering a comprehensive range for various setups. Elevate communication efficiency and connectivity with our top-notch EPABX systems, tailored to meet diverse intercom needs",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best EPABX system based on your specific communication requirements.",
      p7: "  Join our satisfied customers who trust in our commitment to providing the best in EPABX technology and service.",
      p8: " EPABX systems from Infygain Technologies. Explore the best-in-class <b>EPABX solution in Coimbatore</b>, designed to elevate your intercom communication. Discover seamless connectivity and efficiency, with EPABX systems tailored to your needs.",
    },
    {
      name: "IPPBX",
      key:"IPPBX Intercom Service",
      img: "/images/intercomvector-01.webp",
      link: "https://infygain.com/onlinestore/",
      para: "Welcome to Infygain Technologies, where we prioritize seamless and advanced communication solutions. Explore a world of enhanced connectivity and efficient intercom systems with our collection of the best IPPBX systems, meticulously chosen to elevate your communication infrastructure.",
      p1: " Intercom communication solutions with our top-tier IPPBX system in Coimbatore, at Infygain Technologies. Elevate connectivity within your premises with carefully selected, cutting-edge IPPBX systems that deliver seamless communication and advanced features.",
      p2: "Experience intuitive communication with our best IPPBX systems. The user-friendly interfaces make it easy for users to initiate intercom calls, manage extensions, and utilize advanced features, contributing to a smooth and streamlined operation.",
      p3: "Explore optimal performance with the advanced features integrated into our IPPBX systems. From call routing and conferencing to voicemail and integration with other communication channels, our systems are designed to deliver the best in intercom communication for various settings.",
      p4: "Choose from our versatile range of IPPBX systems that cater to various setups and industries. Whether you need a solution for a corporate office, a healthcare facility, or an educational institution, our collection includes the best options to suit diverse intercom communication needs.",
      p5: "IPPBX solutions in Coimbatore, tailored for diverse intercom setups. Explore our comprehensive range of IPPBX systems, leveraging the latest technology to enhance communication efficiency.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best IPPBX system based on your specific communication requirements",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in IPPBX technology and service.",
      p8: " Intercom communication with Infygain Technologies' cutting-edge IPPBX host system in coimbatore. Elevate connectivity and streamline communication processes with our top-tier IPPBX solutions.",
    },
  ];

  const wifi = [
    {
      name: "Access Point Controller",
      key:"Access Point Controller",
      img: "/images/wifi5vector-01.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",

      para: "Welcome to Infygain Technologies, your gateway to unparalleled wireless connectivity. Dive into the world of seamless networking with our collection of the best Access Point solutions, meticulously chosen to optimize and manage your Wi-Fi network efficiently.",

      p1: "Empower your office connectivity with Infygain Technologies' top-tier Access Point solutions in Coimbatore. Elevate your Wi-Fi management with meticulously selected access points designed for enhanced performance. Our solutions guarantee a robust and reliable wireless network, delivering seamless connectivity for your office users in Coimbatore.",

      p2: "Experience centralized management that simplifies control over your Wi-Fi network. Our best Access Point solutions allow you to monitor, configure, and optimize multiple access points from a centralized interface, streamlining the management of your wireless infrastructure.",
      p3: "Explore optimal performance with the advanced features integrated into our Access Point solutions. From load balancing and traffic shaping to seamless roaming and security management, our solutions are designed to deliver the best Wi-Fi experience.",
      p4: "Choose from our versatile range of Access Point solutions that cater to various settings and network sizes. Whether you need a solution for a small business, a large enterprise, or a public venue, our collection includes the best options to suit diverse Wi-Fi requirements.",
      p5: "Unmatched Wi-Fi capabilities with our Access Point solutions, offering a comprehensive range to cater to diverse setups. Elevate your connectivity with the latest technology, ensuring an extensive access point range in Coimbatore that meets your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Access Point solution based on your specific network needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the most advanced Access Point technology and service. Our solutions are designed to enhance network efficiency, security, and scalability, ensuring a seamless experience for your organization's connectivity needs.",
      p8: "Manage your Wi-Fi network with the finest Wireless Access Point solutions in Coimbatore at Infygain Technologies. Explore cutting-edge solutions to enhance connectivity and performance. Our Access Point solutions empower you to efficiently deploy, monitor, and secure wireless access points, ensuring a seamless and reliable network experience.",
    },

    {
      name: "Instant Access Point",
      key:"Instant Access Point",
      img: "/images/wifi5vector-01.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",
      para: "Welcome to Infygain Technologies, where we redefine wireless connectivity with the latest in Instant Access Point technology. Explore a world of high-speed, reliable, and efficient wireless networking with our collection of the best Instant Access Point solutions designed to meet your connectivity needs.",
      p1: "Blazing-fast connectivity with Infygain Technologies' Instant Access Point technology in Coimbatore. Elevate your online experience with unparalleled speeds, ensuring seamless and lag-free connectivity for all your devices. Trust in our commitment to delivering the best in Instant Access Point technology, setting the standard for performance and reliability.",
      p2: "Experience enhanced bandwidth that enables seamless streaming and data transfers. Our best Instant Access Point solutions provide the necessary speed and capacity to support multiple devices simultaneously, making it an ideal choice for homes, offices, and public spaces.",
      p3: "Explore optimal performance with the advanced features integrated into our Instant Access Point solutions. From beamforming and MU-MIMO technology to improved security protocols, our solutions are designed to deliver the best Wi-Fi experience for a diverse range of applications.",
      p4: " Choose from our versatile range of Instant Access Point solutions that cater to various settings and connectivity requirements. Whether you need a solution for a smart home, a bustling office, or a public venue, our collection includes the best options to suit diverse Wi-Fi needs.",
      p5: "The ultimate in Instant Access Point technology and speed in Coimbatore with our comprehensive range of solutions. Elevate your connectivity setups with cutting-edge Instant Access Point technology, ensuring fast and reliable performance. Explore a diverse selection of products designed to optimize your network speed.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Instant Access Point solution based on your specific networking needs.",
      p7: "Explore a comprehensive range of Instant Access Point solutions, incorporating the latest technology for various connectivity setups.",
      p8: "Connectivity with cutting-edge Instant Access Point solutions from Infygain Technologies. Elevate your network performance to new heights with the latest Instant Access Point standard in Coimbatore. Experience faster speeds, enhanced reliability, and seamless connectivity.",
    },

    {
      name: "Wifi Router",
      key:"WIFI Router",
      img: "/images/acesspoint controller.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",
      para: "Welcome to Infygain Technologies, where we redefine wireless connectivity with the latest and greatest in Wifi Router router technology. Explore a world of cutting-edge performance, enhanced capacity, and seamless connectivity with our collection of the best Wifi Router router solutions designed to elevate your networking experience.",
      p1: "The pinnacle of wireless technology with Infygain Technologies - Introducing the best Wifi Router router innovation for unparalleled performance in Coimbatore (802.11ax). Elevate your connectivity with a new standard in speed, efficiency, and reliability.",
      p2: "Experience enhanced capacity that caters to the demands of crowded networks. Our best Wifi Router router solutions are designed to handle multiple devices simultaneously, making it the ideal choice for smart homes, busy offices, and high-traffic public spaces.",
      p3: "Explore optimal efficiency with the advanced features integrated into our Wifi Router router solutions. From OFDMA and MU-MIMO technology to improved security protocols, our solutions are crafted to deliver the best Wi-Fi experience, providing faster speeds and better coverage.",
      p4: "Choose from our versatile range of Wifi Router router solutions that cater to various settings and connectivity requirements. Whether you need a solution for a smart home, a bustling office, or a public venue, our collection includes the best options to suit diverse Wi-Fi needs.",
      p5: "Peak performance with our comprehensive Wifi Router router solutions, featuring cutting-edge technology to elevate Wi-Fi network speed in Coimbatore, across various connectivity setups. Unleash the full potential of Wifi Router, ensuring lightning-fast speeds and optimal connectivity.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Wifi Router router solution based on your specific networking needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the advanced features in Wifi Router router technology and service.",
      p8: "The future of seamless connectivity, speed, and reliability with our state-of-the-art Wifi Router router solutions. Step into a world where innovation meets performance, setting new benchmarks in the Wifi Router standard in Coimbatore.",
    },
    {
      name: "Point to Point",
      img: "/images/point-vector.webp",
      link: "https://infygain.com/onlinestore/products/",
      para: "Welcome to Infygain Technologies, where we revolutionize wireless connectivity with cutting-edge Point to Point Wi-Fi technology. Discover a realm of unmatched performance, extended reach, and robust connectivity with our lineup of top-notch Point to Point Wi-Fi solutions crafted to enhance your networking experience.",
      p1: "Experience the epitome of wireless technology with Infygain Technologies - Introducing the finest Point to Point Wi-Fi innovation for unmatched performance in Coimbatore. Elevate your connectivity with a new standard in speed, reliability, and range.",
      p2: "Encounter extended reach that caters to the requirements of remote connectivity. Our premium Point to Point Wi-Fi solutions are meticulously engineered to transmit data over long distances with minimal interference, making them perfect for remote locations, outdoor setups, and challenging environments.",
      p3: "Delve into unparalleled reliability with the advanced features embedded in our Point to Point Wi-Fi solutions. From beamforming technology to robust encryption protocols, our solutions are tailored to provide a seamless Wi-Fi experience, ensuring stable connections and enhanced security.",
      p4: "Select from our diverse array of Point to Point Wi-Fi solutions designed to accommodate various environments and connectivity needs. Whether you require connectivity for remote offices, industrial sites, or campus setups, our range encompasses the finest options to meet diverse Wi-Fi requirements.",
      p5: "Achieve peak performance with our comprehensive Point to Point Wi-Fi solutions, featuring state-of-the-art technology to amplify Wi-Fi network reach and reliability in Coimbatore and beyond. Unleash the full potential of Point to Point Wi-Fi, guaranteeing swift data transmission and robust connectivity.",
      p6: "Count on our proficient team for expert guidance in choosing the optimal Point to Point Wi-Fi solution tailored to your specific networking demands.",
      p7: "Join our delighted clientele who rely on our dedication to delivering advanced features in Point to Point Wi-Fi technology and exceptional service.",
      p8: "Embrace the future of seamless connectivity, extended reach, and steadfast reliability with our cutting-edge Point to Point Wi-Fi solutions. Enter a realm where innovation converges with performance, setting new benchmarks in the Point to Point Wi-Fi landscape in Coimbatore and beyond.",
    }
  ];

  const printer = [
    {
      name: "Laser",
      img: "/images/laser vector.webp",
      link: "https://infygain.com/onlinestore/products/s/printer",
      para: "Welcome to Infygain Technologies, where precision meets performance in the world of printing technology. Elevate your printing experience with our collection of the best Laser Printers, meticulously chosen to deliver sharp, high-quality prints for your professional and personal needs.",
      p1: "Infygain Technologies, elevate your printing experience with our top-tier Laser Printers in Coimbatore. Immerse yourself in the world of precision printing, where clarity meets professionalism. Our carefully curated selection ensures that every document, image, or presentation is a testament to quality. Infygain Technologies, elevate your printing experience with our top-tier Laser Printers in Coimbatore. Immerse yourself in the world of precision printing, where clarity meets professionalism. Our carefully curated selection ensures that every document, image, or presentation is a testament to quality.",
      p2: "Experience increased productivity with the high-speed printing capabilities of our best Laser Printers. Whether you're in a bustling office environment or managing a busy home office, our printers are built to handle large volumes of printing with efficiency.",
      p3: "Opt for cost-effective and reliable printing solutions with our best Laser Printers. Enjoy the benefits of lower cost per page and consistent print quality, making our printers an ideal choice for businesses, professionals, and home users alike.",
      p4: "Choose from our versatile range of Laser Printers that cater to various printing preferences and requirements. Whether you need a printer for text documents, graphics, or both, our collection includes the best options to suit diverse printing needs.",
      p5: "Explore a comprehensive range of Laser Printers with the best technology for various printing setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Laser Printer based on your specific printing requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the powerful laser light in Laser Printer technology and service.",
      p8: "Transform your printing experience with top-of-the-line Laser Printers at Coimbatore in Infygain Technologies. Explore our range of high-performance laser printers designed to elevate your printing standards. For efficient Printer Management, Toner Refill, Cartridge Refill, Printer Service, Printer Installation, and Printer Support, we've got you covered.",
    },
    {









      
      name: "Thermal",
      img: "/images/thermal-vector.webp",
      link: "https://infygain.com/onlinestore/products/s/printer",
      para: "Welcome to Infygain Technologies, where cutting-edge technology meets efficient printing solutions. Discover a world of streamlined and high-quality prints with our collection of the best Thermal Printers, meticulously chosen to meet the demands of various industries and applications",
      p1: "printing solutions with Infygain Technologies' best Thermal Printers in coimbatore. Designed for today's fast-paced environments, our carefully selected thermal printers prioritize speed and consistency.",
      p2: "Experience high-quality thermal printing that delivers clear and legible results with our best Thermal Printers. Whether you're printing receipts, labels, or other thermal media, our printers ensure precision and clarity, meeting the standards of diverse applications.",
      p3: "Opt for cost-effective and low-maintenance printing solutions with our best Thermal Printers. Enjoy the benefits of minimal consumables, reduced downtime, and efficient performance, making our printers an ideal choice for businesses and industries seeking reliability.",
      p4: "Choose from our versatile range of Thermal Printers that cater to various printing preferences and requirements. Whether you need a printer for point-of-sale receipts, shipping labels, or barcode printing, our collection includes the best options to suit diverse thermal printing needs.",
      p5: "Thermal Printers in coimbatore designed for diverse printing environments. Explore our comprehensive range featuring the latest technology. Enhance your printing setups with reliable thermal printer in Coimbatore, and streamline operations effortlessly.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Color sticker Printer based on both quality specific color printing requirements.        ",
      p7: "Join our satisfied customers who trust in our commitment to providing the quality in Color Printer technology and service.        ",
      p8: " Boost your printing efficiency with the finest Thermal Printers at Infygain Technologies. Explore our range for top-notch performance and reliability. Accomplish seamless results with our advanced thermal printers, perfectly complemented by high-quality thermal printer paper in coimbatore. ",
    },
    {
      name: "color",
      img: "/images/color-printer-vector.webp",
      link: "https://infygain.com/onlinestore/products/s/printer",
      para: "Welcome to Infygain Technologies, where innovation meets color brilliance in the realm of printing technology. Explore a spectrum of vivid possibilities with our collection of the best Color Printers, meticulously chosen to bring life and vibrancy to your documents, images, and creative projects.",
      p1: "Printing experience with Infygain Technologies' best color printers in coimbatore. Immerse yourself in vibrant and eye-catching prints that leave a lasting impression. Our carefully selected color printers guarantee stunning color reproduction, ensuring every detail captures attention.        ",
      p2: "Experience lifelike results with the high-resolution printing capabilities of our best Color Printers. Whether you're printing photos, marketing materials, or presentations, our printers ensure clarity and detail, enhancing the visual appeal of your prints.",
      p3: "Opt for cost-effective and efficient color printing solutions with our best Color Printers. Enjoy the benefits of precise color control, low per-page costs, and consistent color quality, making our printers an ideal choice for businesses, creatives, and home users alike.",
      p4: "Explore a comprehensive range of Color Printers with the best technology for various color printing setups.",
      p5: "Range of color printers designed for optimal performance in home settings. Find the perfect color printer for home use in Coimbatore, among our versatile collection, catering to a spectrum of printing preferences. Ideal for graphic design, marketing collateral, and everyday color prints, our selection ensures you have the best options at your fingertips.",
      p6: " Rely on our knowledgeable team for expert advice on selecting the best Color Printer based on your specific color printing requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Color Printer technology and service.",
      p8: "Office printing experience with the best Color Printers in Coimbatore, available at Infygain Technologies. Bring your prints to life with vibrant and brilliant colors that make your projects stand out.",
    },
    {
      name: "Multifunction",
      img: "/images/multi vector.webp",
      link: "https://infygain.com/onlinestore/products/s/printer",
      para: "Welcome to Infygain Technologies, where versatility meets efficiency in the realm of printing solutions. Discover the ultimate convenience with our collection of the best Multifunction Printers, meticulously chosen to streamline your printing, scanning, and copying tasks in one compact device.",
      p1: "Discover seamless printing, scanning, and copying with our best Multifunction Printers in Coimbatore at Infygain Technologies. Our carefully curated selection provides an all-in-one solution for diverse office and home needs.",
      p2: "Experience high-performance printing and copying with our best Multifunction Printers. Whether you're producing documents, reports, or presentations, our printers deliver crisp and clear results, ensuring professional-quality outputs every time.",
      p3: "Optimize your digital workflows with efficient scanning capabilities of our best Multifunction Printers. Scan documents directly to your computer, email, or cloud storage, streamlining the process of creating digital copies for archival or sharing purposes.",
      p4: "Choose cost-effective and space-saving solutions with our best Multifunction Printers. Enjoy the benefits of consolidating multiple devices into one, reducing costs, and saving valuable office or home space.",
      p5: "The epitome of versatility with our multifunction laser printers in coimbatore. Tailored to meet diverse printing and scanning preferences, our range offers the perfect solution for high-volume printing, large document scanning, and occasional copying.",
      p6: "Explore a comprehensive range of Multifunction Printers with the best budget all in one printer technology for various printing and scanning setups.",
      p7: "Rely on our knowledgeable team for expert advice on selecting the best Multifunction Printer based on your specific requirements.",
      p8: "The ultimate in Multifunction Printer technology for home use in coimbatore. Join our satisfied customers who trust our commitment to excellence in both cutting-edge features and top-notch service.",
    },
    {
      name: "Dot Matrix",
      img: "/images/dot-matrix-vector.webp",
      link: "https://infygain.com/onlinestore/products/s/printer",
      para: "Welcome to Infygain Technologies, where tradition meets durability in the realm of printing technology. Explore the time-tested reliability of dot matrix printing with our collection of the best Dot Matrix Printers, meticulously chosen to cater to industries and applications that demand robust and impactful printing.",
      p1: "Reliable printing solutions with our best Dot Matrix Printers in Coimbatore, at Infygain Technologies. Tailored to meet the distinct needs of businesses relying on impact printing, our carefully selected printers ensure clear and consistent outputs on multi-part forms and specialized media.",
      p2: "Experience high-speed printing with the efficiency of our best Dot Matrix Printers. Whether you're dealing with invoices, receipts, or other multipart documents, our printers are designed to handle high-volume tasks with precision and speed.",
      p3: "Opt for durable and low-cost printing solutions with our best Dot Matrix Printers. Enjoy the benefits of long-lasting print heads and minimal consumables, making our printers an ideal choice for businesses seeking a cost-effective and reliable printing solution.",
      p4: "Choose from our versatile range of Dot Matrix Printers that cater to various impact printing preferences. Whether you need a printer for invoicing, order forms, or other multipart documents, our collection includes the best options to suit diverse dot matrix printing needs.",
      p5: "extensive collection of Dot Matrix Printers, featuring cutting-edge technology for optimal impact printing in diverse setups. Elevate your printing experience with precision and reliability. Explore our range designed for DOT MATRIX PRINTER PAPER in coimbatore, ensuring seamless compatibility and high-quality results.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Dot Matrix Printer based on your specific requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Dot Matrix Printer technology and service. ",
      p8: "Range of high-performance dot matrix printers designed to deliver exceptional results. Elevate your printing experience with Infygain's best-in-class dot matrix printers in coimbatore, ensuring reliability and quality in every print job.",
    },
  ];

  const scanner = [
    {
      name: "Flatbed",
      img: "/images/flatbad-scanner-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/170",
      para: "Welcome to Infygain Technologies, where precision meets versatility in the world of scanning technology. Transform your document and image digitization experience with our collection of the best Flatbed Scanners, meticulously chosen to deliver high-quality scans for your professional and personal needs..",
      p1: "Infygain Technologies, explore the pinnacle of scanning precision with our carefully curated selection of the best flatbed scanners in coimbatore. Elevate your scanning experience with superior capabilities, capturing intricate details across various applications.",
      p2: "Optimize your scanning workflow with versatile media handling of our best Flatbed Scanners. Scan documents, photos, books, and more with ease, thanks to the flat and open scanning surface that accommodates a variety of media types and sizes.",
      p3: " Explore optimal performance with the advanced features integrated into our Access Point Controllers. From load balancing and traffic shaping to seamless roaming and security management, our controllers are designed to deliver the best Wi-Fi experience.",
      p4: " Choose efficiency in scanning for both productivity and creativity with our best Flatbed Scanners. Whether you're digitizing documents for the office or capturing artistic projects, our scanners provide the tools needed to enhance your scanning tasks.",
      p5: "Explore a comprehensive range of Flatbed Scanners with the best technology for various scanning setups.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Flatbed Scanner based on your specific scanning requirements.",
      p7: "Join our satisfied customers who trust in our commitment to providing the best in Flatbed Scanner technology and service",
      p8: "Transform your home scanning experience with the finest Flatbed Scanners in coimbatore available at Infygain Technologies. Explore top-notch technology designed for home use, ensuring unparalleled scanning precision and efficiency.",
    },
    {
      name: "Vertical",
      img: "/images/scanner vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/170",
      para: "Welcome to Infygain Technologies, where innovation meets efficiency in the realm of scanning technology. Redefine the way you digitize documents with our collection of the best Vertical Scanners, meticulously chosen to provide a space-saving and user-friendly solution for your scanning needs.",
      p1: " At Infygain Technologies, we understand the importance of maximizing space without compromising on functionality. Our carefully selected best Vertical Scanners offer a space-saving design that fits seamlessly into your workflow while delivering efficient and high-quality scanning results.",
      p2: "Experience efficient desktop integration with the compact design of our best Vertical Scanners. Perfect for environments where space is a premium, these scanners offer a vertical orientation, ensuring a small footprint while providing big capabilities.",
      p3: "Optimize your productivity with high-speed scanning capabilities of our best Vertical Scanners. Whether you're dealing with documents, receipts, or cards, our scanners are designed to handle a variety of media efficiently and with precision.",
      p4: "Choose user-friendly features for intuitive scanning operation with our best Vertical Scanners. Enjoy hassle-free scanning with easy-to-use controls, quick setup, and seamless integration into your digital workflow.",
      p5: "The pinnacle of scanning technology with our comprehensive range of Vertical Scanner Antenna in Coimbatore. Tailored for various scanning setups, our antennas boast cutting-edge technology to ensure optimal performance.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best Vertical Scanner based on your specific scanning requirements.",
      p7: " Join our satisfied customers who trust in our commitment to providing the best in Vertical Scanner technology and service.",
      p8: "Scanning efficiency with Infygain Technologies' cutting-edge vertical scanner line in coimbatore. Elevate your document management to new heights with our state-of-the-art vertical scanners, designed for precision and speed. Explore a range of top-notch scanning solutions to streamline your workflow and enhance productivity.",
    },
  ];
  const cloud = [
    {
      name: "Cloud-Hosting",
      key:"Cloud-Hosting",
      img: "/images/cloud-vector.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",

      para: "Welcome to Infygain Technologies, your gateway to unparalleled <b>cloud hosting solutions</b>. Dive into the world of seamless and scalable cloud infrastructure with our collection of the best cloud hosting services, meticulously chosen to optimize and manage your business operations efficiently",

      p1: "Empower your business with Infygain Technologies' top-tier <b>cloud hosting solutions in Coimbatore</b>. Elevate your IT infrastructure with meticulously selected cloud hosting services designed for enhanced performance and reliability. Our solutions guarantee robust and secure cloud environments, delivering seamless scalability and flexibility for your business needs in Coimbatore.",

      p2: "Experience centralized management that simplifies control over your cloud infrastructure. Our best <b>cloud hosting solutions</b> allow you to monitor, configure, and optimize your cloud resources from a centralized interface, streamlining the management of your IT infrastructure.",
      p3: "Explore optimal performance with the advanced features integrated into our cloud hosting solutions. From load balancing and automatic scaling to comprehensive security management and data backup, our solutions are designed to deliver the best cloud experience.",
      p4: "Choose from our versatile range of <b>cloud hosting solutions</b> that cater to various business sizes and requirements. Whether you need a solution for a small startup, a large enterprise, or a complex application, our collection includes the best options to suit diverse cloud hosting needs.",
      p5: "Unmatched hosting capabilities with our <b>cloud hosting</b> solutions, offering a comprehensive range to cater to diverse business setups. Elevate your operations with the latest technology, ensuring an extensive <b>cloud hosting</b> range in Coimbatore that meets your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best <b>cloud hosting solution</b> based on your specific business needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the most advanced cloud hosting technology and service. Our solutions are designed to enhance infrastructure efficiency, security, and scalability, ensuring a seamless experience for your organization's IT needs.",
      p8: "Manage your IT infrastructure with the finest <b>cloud hosting solutions in Coimbatore</b> at Infygain Technologies. Explore cutting-edge solutions to enhance connectivity and performance. Our cloud hosting solutions empower you to efficiently deploy, monitor, and secure your cloud resources, ensuring a seamless and reliable hosting experience.",
    },
    {
      name: "DataBackup",
      key:"DataBackup",
      img: "/images/databackupvector.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",

      para: "Welcome to Infygain Technologies, your gateway to unparalleled data protection. Dive into the world of seamless data management with our collection of the best <b>Data Backup solutions</b>, meticulously chosen to safeguard and manage your critical information efficiently.",

      p1: "Empower your business with Infygain Technologies' top-tier <b>Data Backup solutions in Coimbatore</b>. Elevate your data security with meticulously selected backup services designed for enhanced protection and reliability. Our solutions guarantee robust and secure data storage, delivering seamless recovery and continuity for your business needs in Coimbatore.",
      p2: "Experience centralized management that simplifies control over your data protection strategies. Our best <b>Data Backup solutions</b> allow you to monitor, configure, and optimize your backup processes from a centralized interface, streamlining the management of your data infrastructure.",
      p3: "Explore optimal performance with the advanced features integrated into our <b>Data Backup solutions</b>. From automated backups and real-time synchronization to comprehensive encryption and disaster recovery, our solutions are designed to deliver the best data protection experience.",
      p4: "Choose from our versatile range of Data Backup solutions that cater to various business sizes and requirements. Whether you need a solution for a small startup, a large enterprise, or complex data environments, our collection includes the best options to suit diverse data backup needs.",
      p5: "Unmatched data protection capabilities with our <b>Data Backup solutions</b>, offering a comprehensive range to cater to diverse business setups. Elevate your data management with the latest technology, ensuring an extensive backup range in Coimbatore that meets your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best <b>Data Backup solution</b> based on your specific business needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the most advanced data protection technology and service. Our solutions are designed to enhance data security, reliability, and scalability, ensuring a seamless experience for your organization's data management needs.",
      p8: "Manage your data infrastructure with the finest <b>Data Backup solutions in Coimbatore</b> at Infygain Technologies. Explore cutting-edge solutions to enhance data protection and performance. Our <b>Data Backup solutions</b> empower you to efficiently deploy, monitor, and secure your data, ensuring a seamless and reliable backup experience.",
    },
    {
      name: "CloudSecurity",
      key:"CloudSecurity",
      img: "/images/DataSecurity.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",
      para: "Welcome to <b>Infygain Technologies</b>, your gateway to unparalleled cloud security solutions. Dive into the world of secure and resilient cloud environments with our collection of the best <b>Cloud Security services</b>, meticulously chosen to protect and manage your digital assets efficiently.",
      p1: "Empower your business with Infygain Technologies' top-tier <b>Cloud Security solutions in Coimbatore</b>. Elevate your IT security with meticulously selected cloud security services designed for enhanced protection and reliability. Our solutions guarantee robust and secure cloud environments, delivering seamless protection for your business operations in Coimbatore.",
      p2: "Experience centralized management that simplifies control over your <b>Cloud Security</b> strategies. Our best Cloud Security solutions allow you to monitor, configure, and optimize your security policies from a centralized interface, streamlining the management of your cloud infrastructure.",
      p3: "Explore optimal performance with the advanced features integrated into our Cloud Security solutions. From real-time threat detection and automated incident response to comprehensive encryption and compliance management, our solutions are designed to deliver the best cloud security experience.",
      p4: "Choose from our versatile range of <a>Cloud Security Solutions</a> that cater to various business sizes and requirements. Whether you need a solution for a small startup, a large enterprise, or a complex application, our collection includes the best options to suit diverse cloud security needs.",
      p5: "Unmatched security capabilities with our <b>Cloud Security Solutions</b>, offering a comprehensive range to cater to diverse business setups. Elevate your protection with the latest technology, ensuring an extensive cloud security range in Coimbatore that meets your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best <b>Cloud Security Solution</b> based on your specific business needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the most advanced cloud security technology and service. Our solutions are designed to enhance infrastructure security, reliability, and scalability, ensuring a seamless experience for your organization's IT needs.",
      p8: "Manage your cloud infrastructure with the finest <b>Cloud Security Solutions in Coimbatore</b> at Infygain Technologies. Explore cutting-edge solutions to enhance protection and performance. Our Cloud Security solutions empower you to efficiently deploy, monitor, and secure your cloud resources, ensuring a seamless and reliable security experience.",
    },

    {
      name: "Saas",
      key:"Saas",
      img: "/images/saasvector.webp",
      link: "https://infygain.com/onlinestore/products/ca/181",
      para: "Welcome to Infygain Technologies, your gateway to unparalleled <b>SaaS solutions</b>. Dive into the world of seamless software delivery with our collection of the best SaaS services, meticulously chosen to optimize and manage your business operations efficiently.",
      p1: "Empower your business with Infygain Technologies' top-tier <b>SaaS solutions in Coimbatore</b>. Elevate your operational efficiency with meticulously selected SaaS services designed for enhanced performance and reliability. Our solutions guarantee robust and scalable software applications, delivering seamless functionality for your business needs in Coimbatore.",
      p2: "Experience centralized management that simplifies control over your software applications. Our best <b>SaaS solutions</b> allow you to monitor, configure, and optimize your software tools from a centralized interface, streamlining the management of your IT infrastructure.",
      p3: "Explore optimal performance with the advanced features integrated into our <b>SaaS solutions</b>. From automated updates and real-time collaboration to comprehensive security and scalability, our solutions are designed to deliver the best software experience.",
      p4: "Choose from our versatile range of <b>SaaS solutions</b> that cater to various business sizes and requirements. Whether you need a solution for a small startup, a large enterprise, or a complex operation, our collection includes the best options to suit diverse software needs.",
      p5: "Unmatched software capabilities with our <b>SaaS solutions</b>, offering a comprehensive range to cater to diverse business setups. Elevate your operations with the latest technology, ensuring an extensive SaaS range in Coimbatore that meets your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on selecting the best <b>SaaS solution</b> based on your specific business needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the most advanced SaaS technology and service. Our solutions are designed to enhance operational efficiency, reliability, and scalability, ensuring a seamless experience for your organization's software needs.",
      p8: "Manage your software applications with the finest <b>SaaS solutions in coimbatore</b> at Infygain Technologies. Explore cutting-edge solutions to enhance functionality and performance. Our SaaS solutions empower you to efficiently deploy, monitor, and secure your software resources, ensuring a seamless and reliable software experience.",
    },
    {
      name: "Cloud Migration Services",
      key:"Cloud Migration Services",
      img: "/images/Cloud Migration Servicesvector.webp",
      link: "https://infygain.com/onlinestore/products/",
      para: "Welcome to Infygain Technologies, your gateway to seamless cloud transitions. Dive into the world of efficient and secure cloud migration with our collection of the best <b>Cloud Migration Services</b>, meticulously designed to optimize and manage your migration journey smoothly.",
      p1: "Empower your business with Infygain Technologies' top-tier <b>Cloud Migration Services in Coimbatore</b>. Elevate your IT infrastructure with meticulously planned migration strategies designed for minimal disruption and maximum efficiency. Our solutions guarantee a smooth and secure transition to the cloud, delivering seamless performance for your business operations in Coimbatore.",
      p2: "Experience centralized management that simplifies your cloud migration process. Our best <b>Cloud Migration Services</b> allow you to monitor, configure, and optimize your migration tasks from a centralized interface, streamlining the management of your transition to the cloud.",
      p3: "Explore optimal performance with the advanced features integrated into our <b>Cloud Migration Services</b>. From comprehensive assessment and planning to real-time monitoring and post-migration support, our solutions are designed to deliver the best migration experience.",
      p4: "Choose from our versatile range of <b>Cloud Migration Services</b> that cater to various business sizes and requirements. Whether you need a solution for a small startup, a large enterprise, or a complex operation, our collection includes the best options to suit diverse migration needs.",
      p5: "Unmatched migration capabilities with our <b>Cloud Migration Services</b>, offering a comprehensive range to cater to diverse business setups. Elevate your cloud journey with the latest technology, ensuring an extensive migration service range in Coimbatore that meets your specific requirements.",
      p6: "Rely on our knowledgeable team for expert advice on planning and executing the best <b>cloud migration</b> strategy based on your specific business needs.",
      p7: "Join our satisfied customers who trust in our commitment to providing the most advanced cloud migration technology and service. Our solutions are designed to enhance infrastructure efficiency, reliability, and scalability, ensuring a seamless experience for your organization's migration to the cloud.",
      p8: "Manage your cloud transition with the finest <b>Cloud Migration Services in Coimbatore</b> at Infygain Technologies. Explore cutting-edge solutions to ensure a smooth and efficient migration. Our Cloud Migration Services empower you to efficiently plan, execute, and secure your move to the cloud, ensuring a seamless and reliable transition experience.",
    }
  ];

  // pos
  const KIOSK = pos.filter((val, index) => val.name === "KIOSK");
  const POS = pos.filter((val, index) => val.name === "POS");
  const BillingApps = pos.filter((val, index) => val.name === "Billing Apps");
  const barcode = pos.filter((val, index) => val.name === "Barcode Scanner");
  const toptablebarcode = pos.filter(
    (val, index) => val.name === "Table Top Barcode Scanner"
  );

  // Laptop
  const GamingLaptop = Laptop.filter(
    (val, index) => val.name === "Gaming Laptop"
  );
  const Consumerlaptops = Laptop.filter(
    (val, index) => val.name === "Consumer laptops"
  );
  const WorkStation = Laptop.filter((val, index) => val.name === "WorkStation");

  // Desktop
  const BrandedDesktop = Desktop.filter(
    (val, index) => val.name === "Branded Desktop"
  );
  const AssembledDesktop = Desktop.filter(
    (val, index) => val.name === "Assembled Desktop"
  );
  const WorkStationDesktop = Desktop.filter(
    (val, index) => val.name === "Work Station"
  );
  const GamingDesktop = Desktop.filter(
    (val, index) => val.name === "Gaming Desktop"
  );

  // accessories
  const ram = accessories.filter((val, index) => val.name === "RAM");
  const HDDSSD = accessories.filter((val, index) => val.name === "HDD,SSD");
  const Keyboard = accessories.filter((val, index) => val.name === "Keyboard");
  const MouseandMonitor = accessories.filter(
    (val, index) => val.name === "Mouse and Monitor"
  );
  const Cabinet = accessories.filter((val, index) => val.name === "Cabinet");
  const Motherboard = accessories.filter(
    (val, index) => val.name === "Motherboard"
  );
  const SMPS = accessories.filter((val, index) => val.name === "SMPS");
  const CablesandChargers = accessories.filter(
    (val, index) => val.name === "Cables and Chargers"
  );
  const ConnectorsandConverters = accessories.filter(
    (val, index) => val.name === "Connectors and Converters"
  );

  // projectors
  const LCD = projectors.filter((val, index) => val.name === "LCD");
  const LED = projectors.filter((val, index) => val.name === "LED");
  const TV = projectors.filter((val, index) => val.name === "TV");
  const Interpanel = projectors.filter(
    (val, index) => val.name === "InteractivePanel"
  );
  const SmartProjectors = projectors.filter(
    (val, index) => val.name === "Smart Projectors"
  );

  // interactivepanel
  const ip65 = interactivepanel.filter(
    (val, index) => val.name === "65-Inch Interactive Panels"
  );
  const ip75 = interactivepanel.filter(
    (val, index) => val.name === "75-Inch Interactive Panels"
  );

  // tv
  const AndroidTV = tv.filter((val, index) => val.name === "Android TV");
  const SmartTV = tv.filter((val, index) => val.name === "Smart TV");

  // ups
  const Inventor = ups.filter((val, index) => val.name === "Inventor");
  const OnlineUPS = ups.filter((val, index) => val.name === "Online UPS");

  // ups
  const AccessPointController = wifi.filter(
    (val, index) => val.name === "Access Point Controller"
  );
  const WiFi5 = wifi.filter(
    (val, index) => val.name === "Instant Access Point"
  );
  const WiFi6 = wifi.filter((val, index) => val.name === "Wifi Router");
  const point = wifi.filter((val, index) => val.name === "Point to Point");

  // intercom
  const EPABX = intercom.filter((val, index) => val.name === "EPABX");
  const IPPBX = intercom.filter((val, index) => val.name === "IPPBX");
  const InterCom = intercom.filter((val, index) => val.name === "InterCom");

  // printer
  const Laser = printer.filter((val, index) => val.name === "Laser");
  const Thermal = printer.filter((val, index) => val.name === "Thermal");
  const color = printer.filter((val, index) => val.name === "color");
  const Multifunction = printer.filter(
    (val, index) => val.name === "Multifunction"
  );
  const DotMatrix = printer.filter((val, index) => val.name === "Dot Matrix");

  // sacnner
  const Flatbed = scanner.filter((val, index) => val.name === "Flatbed");
  const Vertical = scanner.filter((val, index) => val.name === "Vertical");

  // server
  const serverinst = server.filter((val, index) => val.name === "Server Installation");
  const servermain = server.filter((val, index) => val.name === "Server Maintenance");
  const webserver = server.filter((val, index) => val.name === "Web Server");
  const storageserver = server.filter((val, index) => val.name === "Storage Server");

  //cloud
  const CloudHosting = cloud.filter((val, index) => val.name === "Cloud-Hosting");
  const DataBackup = cloud.filter((val, index) => val.name === "DataBackup");
  const CloudSecurity = cloud.filter((val, index) => val.name === "CloudSecurity");
  const Saas = cloud.filter((val, index) => val.name === "Saas");
  const CloudMigrationServices = cloud.filter((val, index) => val.name === "Cloud Migration Services");

  let metaContent =
    id === "Switch"
      ? {
        
          des: "Explore a wide range of network switches in Coimbatore. Find the perfect solution for your networking needs on our website today.",
          key: "POE switch, Ethernet switch, 10Gp switch, 8-port POE switch, data center switch ",
          ogt: "Configuring Network Switches in Coimbatore|ethernet switch 8 port.",
          og: "Explore a wide range of network switches in Coimbatore. Find the perfect solution for your networking needs on our website today.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "HUB"
      ? {
          des: "Discover hubs for seamless connectivity: Ethernet, USB LAN, and wireless options optimize networking, simplifying and enhancing your experience efficiently.",
          key: "Ethernet hub, USB hub LAN, wireless hub, networking solutions, connectivity hub",
          ogt: "Best Ethernet usb connector|best usb connector pc",
          og: "Discover hubs for seamless connectivity: Ethernet, USB LAN, and wireless options optimize networking, simplifying and enhancing your experience efficiently.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Router"
      ? {
          des: "WIFI Router Solution-Discover the best in connectivity with WiFi 6, 5G, and 4G routers. Elevate your home network with the top-performing, best-in-class WiFi router solutions.",
          key: "WiFi router, 5G router, best WiFi router, 4G router, best home WiFi, WiFi 6 router",
          ogt: "Top 10 5G Routers for High-Speed Internet|Best WiFi 6, 5G, and 4G Router Solutions provider in coimbatore",
          og: "Discover the best in connectivity with WiFi 6, 5G, and 4G routers. Elevate your home network with the top-performing, best-in-class WiFi router solutions.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Firewall"
      ? {
          des: "Discover the best firewall solutions in Coimbatore. Protect your network with top-notch security measures. Find out more on our website today!",
          key: "Firewall in network, firewall security, network firewall security, firewall installation",
          ogt: "Network Firewall Solutions provider in coimbatore|firewall installation",
          og: "Discover the best firewall solutions in Coimbatore. Protect your network with top-notch security measures. Find out more on our website today!",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "EndPointSecurity"
      ? {
          des: "Strengthen your network with advanced firewall security. Explore robust solutions for network security, ensuring a safe and protected digital environment",
          key: "Firewall in network, firewall security, network firewall security, firewall installation",
          ogt: "Secure Endpoints: Cisco Endpoint Cyber Security Solutions in coimbatore",
          og: "Strengthen your network with advanced firewall security. Explore robust solutions for network security, ensuring a safe and protected digital environment",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Fiber"
      ? {
          des: "Experience blazing fast connectivity with our fiber optic internet solutions. Discover the best providers for reliable fiber optic cable internet.",
          key: "Fiber optic internet, best fiber optic internet, fiber optic cable internet, fiber optic internet providers",
          ogt: "Best Fiber Optic Internet Solutions in coimbatore.",
          og: "Experience blazing fast connectivity with our fiber optic internet solutions. Discover the best providers for reliable fiber optic cable internet.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Analog"
      ? {
          des: " Shop for analog CCTV cameras in Coimbatore with ease on our website. Browse our selection for the best surveillance options available.",
          key: "Analog CCTV camera, analog security camera system, analog PTZ camera, analog surveillance camera",
          ogt: "Top 10 Analog CCTV Cameras for Coimbatore",
          og: " Shop for analog CCTV cameras in Coimbatore with ease on our website. Browse our selection for the best surveillance options available.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "IP"
      ? {
          des: "Looking for affordable IP CCTV cameras in Coimbatore? Find the best deals on high-quality security cameras to protect your home or business.",
          key: "IP camera, IP surveillance, network camera, HD IP security camera",
          ogt: "Top 5 IP CCTV Cameras in Coimbatore",
          og: "Looking for affordable IP CCTV cameras in Coimbatore? Find the best deals on high-quality security cameras to protect your home or business.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "DVR"
      ? {
          des: "Explore our wide selection of DVRs, including 16 channel DVRs and Hikvision DVRs, designed for security cameras in Coimbatore.",
          key: "Wireless CCTV with DVR, 16 channel DVR, security cameras and DVR, 4 DVR camera",
          ogt: "Installing Hikvision DVR for Coimbatore Security System",
          og: "Explore our wide selection of DVRs, including 16 channel DVRs and Hikvision DVRs, designed for security cameras in Coimbatore.",
          logo: "https://infygain.com/images/icons/infy-logo.svg", 
          
        }
      : id === "NVR"
      ? {
          des: "Elevate security with cutting-edge UNV NVR and Hik NVR camera solutions. Discover seamless integration for advanced CCTV surveillance.",
          key: "UNV NVR, NVR camera, camera with NVR, Hik NVR",
          ogt: "UNV NVR and Hik NVR Camera Solutions in coimbatore",
          og: "Elevate security with cutting-edge UNV NVR and Hik NVR camera solutions. Discover seamless integration for advanced CCTV surveillance.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
        : id === "Bio-Metric"
        ? {
            des: "Elevate security with cutting-edge UNV NVR and Hik NVR camera solutions. Discover seamless integration for advanced CCTV surveillance.",
            key: "UNV NVR, NVR camera, camera with NVR, Hik NVR",
            ogt: "5 Top Biometric Security Solutions in Coimbatore",
            og: "Elevate security with cutting-edge UNV NVR and Hik NVR camera solutions. Discover seamless integration for advanced CCTV surveillance.",
            logo: "https://infygain.com/images/icons/infy-logo.svg",
          }
      : id === "360"
      ? {
          des: "Explore comprehensive security with 360 Hikvision and MI home security cameras. Capture a full view with 360-degree CCTV camera technology.",
          key: "360 Hikvision camera, 360 camera Hikvision, 360 CCTV camera view, 360 CCTV, home security camera MI, 360-degree CCTV camera",
          ogt: "360 Cameras in Coimbatore: A Complete Price Comparison",
          og: "Explore comprehensive security with 360 Hikvision and MI home security cameras. Capture a full view with 360-degree CCTV camera technology.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "PTZ"
      ? {
          des: "Explore the best in PTZ technology with Hikvision PTZ cameras. Find competitive prices and exceptional quality for precision CCTV surveillance.",
          key: "PTZ CCTV camera price, Hikvision PTZ, Hik PTZ camera, PTZ cameras, EZVIZ PTZ camera",
          ogt: "Precision Surveillance: Hikvision PTZ Cameras at Competitive Prices",
          og: "Explore the best in PTZ technology with Hikvision PTZ cameras. Find competitive prices and exceptional quality for precision CCTV surveillance.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "HDD,SSD"
      ? {
          des: "Shop for hard disk and SSD drives, including 256GB options, on our Coimbatore site. Find the best storage solutions for your computer needs.",
          key: " Hard Disk Drive (HDD),Solid State Drive (SSD),Storage Technology,Data Recovery,Disk Partitioning,Disk Cloning,NAND Flash Memory ",
          ogt: "How to Choose Between a Hard Disk and SSD Drive",
          og: "Shop for hard disk and SSD drives, including 256GB options, on our Coimbatore site. Find the best storage solutions for your computer needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Mouse,Moniter"
      ? {
          des: "Explore a wide range of computer accessories, including mice, monitors, custom mouse pads, and the best keyboards. Enhance your workspace with top-notch peripherals.",
          key: " mouse, monitor, computer monitor, custom mouse pads, monitors, keyboard and mouse, best monitor ",
          ogt: "How to Choose the Right Gaming Monitor|How to Set Up a Wireless Mouse",
          og: "Explore a wide range of computer accessories, including mice, monitors, custom mouse pads, and the best keyboards. Enhance your workspace with top-notch peripherals.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "RAM"
      ? {
          des: "Looking for 16GB RAM or 32GB RAM for your laptop in Coimbatore? Browse our selection of high-performance RAM to boost your device's capabilities.",
          key: "RAM, memory modules, DDR4, SODIMM, DIMM, computer upgrades, laptop RAM, desktop memory, gaming memory, high-performance RAM, RAM sticks, system memory, memory expansion, computer accessories.",
          ogt: "How to Install RAM in a Laptop | How to Choose the Best 16GB RAM Laptop",
          og: "Looking for 16GB RAM or 32GB RAM for your laptop in Coimbatore? Browse our selection of high-performance RAM to boost your device's capabilities.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "KeyBoard"
      ? {
          des: "Shop for keyboards in Coimbatore, including iPad, gaming, wireless, and Logitech models. Enhance your setup with a wireless keyboard and mouse combo for seamless connectivity.",
          key: "gaming keyboard and mouse, wireless mouse and keyboard, PC gaming keyboard, ergonomic wireless .",
          ogt: "Top 10 Wireless Keyboards for Gaming in Coimbatore",
          og: "Shop for keyboards in Coimbatore, including iPad, gaming, wireless, and Logitech models. Enhance your setup with a wireless keyboard and mouse combo for seamless connectivity.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Cabinet"
      ? {
          des: "Discover a wide range of PC cabinets and CPU cabinets in Coimbatore. Find the perfect storage solution for your computer setup.",
          key: "ant esports ice 320tg, mini rack mount server, pc case corsair, rack mount pc case 2u, computer in server rack, 19 computer rack.",
          ogt: "The Ultimate Guide to PC and CPU Cabinets in Coimbatore",
          og: "Discover a wide range of PC cabinets and CPU cabinets in Coimbatore. Find the perfect storage solution for your computer setup.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "SMPS"
      ? {
          des: "Discover the leading source for SMPS in Coimbatore. Find high-quality products, expert advice, and reliable service for all your power supply needs.",
          key: "smps, computer accessories",
          ogt: "How to Choose the Best SMPS in Coimbatore",
          og: "Discover the leading source for SMPS in Coimbatore. Find high-quality products, expert advice, and reliable service for all your power supply needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Connector,Convertor"
      ? {
          des: " Discover high-quality connector and converter products in Coimbatore. Browse our wide selection and find the perfect solution for your needs.",
          key: "hdmi adapter for computer, computer monitor cord adapter, usb c adapter pc, computer monitor cable adapter, computer monitor connection adapter.",
          ogt: "High quality Connectors & Converters sales centre in coimbatore",
          og: " Discover high-quality connector and converter products in Coimbatore. Browse our wide selection and find the perfect solution for your needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "LED"
      ? {
          des: " Shop for top-notch LED projectors in Coimbatore. Our selection offers superior brightness and clarity, perfect for any setting. Illuminate your viewing experience now!",
          key: "led, led lights, ceiling lights, outdoor lights, light fixtures, led strip lights, volt lighting, solar lights, lighting stores near me, pendant lights.",
          ogt: "7 Best LED Projectors Available in Coimbatore",
          og: " Shop for top-notch LED projectors in Coimbatore. Our selection offers superior brightness and clarity, perfect for any setting. Illuminate your viewing experience now!",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "InteractivePanel65"
      ? {
          des: "Discover the Interactive Panel 65 and smart boards like BenQ RP6502, Maxhub 65, and Vivitek Novotouch 65. Elevate your educational experience with cutting-edge touchscreen technology.",
          key: "interactive panel 65, smart board 6065 price, BenQ RP6502, class 4k uhd educational touchscreen, Maxhub 65, IFP6550 E2, Vivitek Novotouch 65.",
          ogt: "Explore Interactive Panel 65 - Smart Educational Solutions",
          og: "Discover the Interactive Panel 65 and smart boards like BenQ RP6502, Maxhub 65, and Vivitek Novotouch 65. Elevate your educational experience with cutting-edge touchscreen technology.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
          : id === "BarcodeScanner"
      ? {
          des: "Leading Barcode Scanner Dealer in Coimbatore offering a wide range of high-quality scanners for businesses. Find the perfect solution for your scanning needs today!",
          key: "interactive panel 65, smart board 6065 price, BenQ RP6502, class 4k uhd educational touchscreen, Maxhub 65, IFP6550 E2, Vivitek Novotouch 65.",
          ogt: " Top 5 Barcode Scanner Dealers in Coimbatore",
          og: "Leading Barcode Scanner Dealer in Coimbatore offering a wide range of high-quality scanners for businesses. Find the perfect solution for your scanning needs today!",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "AndriodTV"
      ? {
          des: "Discover Google Android TV, 4K Smart WiFi Android TV, Android boxes, and more. Find the best Android TV for sale with advanced features and competitive prices.",
          key: "google android tv, android tv price, 4k smart wifi android tv, android boxes, android tv boxes, smart tv android tv, android to hdmi, android tv for sale.",
          ogt: "Low price andriod TV sales centre in coimbatore",
          og: "Discover Google Android TV, 4K Smart WiFi Android TV, Android boxes, and more. Find the best Android TV for sale with advanced features and competitive prices.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
        : id === "TableTopBarcodeScanner"
        ? {
            des: "Shop for Table Top Barcode Scanners in Coimbatore. Browse through our collection of reliable scanners for accurate and fast barcode scanning.",
            key: "google android tv, android tv price, 4k smart wifi android tv, android boxes, android tv boxes, smart tv android tv, android to hdmi, android tv for sale.",
            ogt: "Top 5 Table Top Barcode Scanners in Coimbatore",
            og: "Shop for Table Top Barcode Scanners in Coimbatore. Browse through our collection of reliable scanners for accurate and fast barcode scanning.",
            logo: "https://infygain.com/images/icons/infy-logo.svg",
          }
      : id === "Inventor"
      ? {
          des: "Explore Inventor UPS solutions for small businesses. Get reliable UPS services, including overnight and 2nd-day air options. Check UPS prices online.",
          key: "inventor ups, ups, ups small business, ups overnight, ups online, ups prices, ups website, ups mail, ups 2nd day air.",
          ogt: "Best quality Inventor sales centre in coimbatore",
          og: "Explore Inventor UPS solutions for small businesses. Get reliable UPS services, including overnight and 2nd-day air options. Check UPS prices online.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "OnlineUPS"
      ? {
          des: "Explore online UPS options, including APC online UPS and 10 kVA UPS. Find competitive online UPS prices for reliable power supply solutions.",
          key: "ups 10 kva, apc online ups, online ups price, online ups power supply, ups 1 kva.",
          ogt: "High power supply online UPS sales centre in coimbatore",
          og: "Explore online UPS options, including APC online UPS and 10 kVA UPS. Find competitive online UPS prices for reliable power supply solutions.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "POS"
      ? {
          des: " Discover the best POS swipe machines in Coimbatore at competitive prices. Enhance your business transactions with our reliable and efficient solutions.",
          key: "square pos system, best pos system for small business, best pos for small business, cash register for sale, best pos system.",
          ogt: "Step-by-Step: Using a Swipe Machine | Top 5 Swipe Machines for Businesses in Coimbatore",
          og: " Discover the best POS swipe machines in Coimbatore at competitive prices. Enhance your business transactions with our reliable and efficient solutions.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        } 
      : id === "KIOSK"
      ? {
          des: "Leading Kiosk Machine Dealer in Coimbatore offering a wide range of cutting-edge self-service solutions. Find the perfect kiosk for your business today!",
          key: "square pos system, best pos system for small business, best pos for small business, cash register for sale, best pos system.",
          ogt: "Top 5 Kiosk Machine Dealers in Coimbatore",
          og: "Leading Kiosk Machine Dealer in Coimbatore offering a wide range of cutting-edge self-service solutions. Find the perfect kiosk for your business today!",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "HDD,SSD"
      ? {
          des: "Upgrade your computer accessories with cutting-edge HDD and SSD technology. Explore storage options,data,and NAND flash memory for enhanced performance.",
          key: " Hard Disk Drive (HDD),Solid State Drive (SSD),Storage Technology,Data Recovery,Disk Partitioning,Disk Cloning,NAND Flash Memory ",
          ogt: "Optimize Storage with HDD and SSD: Essential Computer Accessories",
          og: "Upgrade your computer accessories with cutting-edge HDD and SSD technology. Explore storage options,data,and NAND flash memory for enhanced performance.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Mouse,Moniter"
      ? {
          des: "Explore a wide range of computer accessories,including mice,monitors,mouse pads, and the best keyboards.Enhance workspace with top-notch peripherals.",
          key: " mouse, monitor, computer monitor, custom mouse pads, monitors, keyboard and mouse, best monitor ",
          ogt: "Elevate  Monitors and Mouse: Essential Computer Accessories in Coimbatore",
          og: "Explore a wide range of computer accessories,including mice,monitors,mouse pads, and the best keyboards.Enhance workspace with top-notch peripherals.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "MotherBoard"
      ? {
          des: " Looking for motherboards in Coimbatore? Check out our selection of Z690 and B550 motherboards. Upgrade your system with the best technology available.",
          key: " motherboard, x570, ibm power 10, motherboard cpu combo, server motherboard, best motherboard, white motherboard, atx motherboard, motherboard pc ",
          ogt: "How to Choose the Best Motherboard for Your PC",
          og: " Looking for motherboards in Coimbatore? Check out our selection of Z690 and B550 motherboards. Upgrade your system with the best technology available.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Cables,Charger"
      ? {
          des: "Shop for top-notch cables, chargers, and Ethernet cables in Coimbatore. Discover a variety of reliable electronic accessories on our website.",
          key: " cables and chargers, charging cable, fast charging, wireless charger, power adapter, cable management, portable charger",
          ogt: "Where Can I Find Affordable Chargers in Coimbatore?",
          og: "Shop for top-notch cables, chargers, and Ethernet cables in Coimbatore. Discover a variety of reliable electronic accessories on our website.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "LCD"
      ? {
          des: "Looking for a reliable LCD projector in Coimbatore? Browse our selection of high-quality projectors for all your presentation needs.",
          key: "lcd display, lcd screen, lcd panel, lcd monitor, custom lcd display, monitor lcd, lcd display screen ",
          ogt: "Why LCD Projectors Are Perfect for Home Theaters",
          og: "Looking for a reliable LCD projector in Coimbatore? Browse our selection of high-quality projectors for all your presentation needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "SmartProjector"
      ? {
          des: "Elevate your AV experience with a smart projector. Explore interactive projection, smart classroom solutions, and advanced AV integration.",
          key: "Smart projector, Interactive projection, Smart classroom solutions, AV integration, Portable smart projector, Advanced projection systems ",

          ogt: "Projectors: Smart Projectors for Interactive AV Solutions in coimbatore",

          og: "Elevate your AV experience with a smart projector. Explore interactive projection, smart classroom solutions, and advanced AV integration.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "InteractivePanel75"
      ? {
          des: "Elevate engagement with an interactive panel 75. Explore collaborative technology and a smart board experience with our advanced touchscreen display.",
          key: " Touchscreen display, Collaborative technology, Interactive learning, Interactive display board, Smart board, Interactive panel 75 ",
          ogt: "Immersive Collaboration: Interactive Panel 75 for Touchscreen Excellence",

          og: "Elevate engagement with an interactive panel 75. Explore collaborative technology and a smart board experience with our advanced touchscreen display.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "SmartTV"
      ? {
          des: "Explore the world of Smart TV with streaming and advanced features. Unlock a new level of entertainment with web-enabled and highly functional Smart TV.",
          key: " Streaming TV, 4K UHD TV, Smart TV features, Smart TV apps, Web-enabled TV, Connected TV, Smart TV functionality ",
          ogt: "Immerse Smart TV Excellence: Streaming, 4K UHD, and Advanced Features",

          og: "Explore the world of Smart TV with streaming and advanced features. Unlock a new level of entertainment with web-enabled and highly functional Smart TV.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "AccessPointController"
      ? {
          des: "Optimize your WiFi network with an Access Point Controller. Streamline wireless network management, ensure security and monitor access points efficiently.",
          key: " Access point controller, Wireless network management, WLAN controller, WiFi controller, Access point monitoring, Wireless access control ",
          ogt: "Elevate Connectivity: Access Point Controller for Efficient WLAN Management",

          og: "Optimize your WiFi network with an Access Point Controller. Streamline wireless network management, ensure security and monitor access points efficiently.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Wifi-5"
      ? {
          des: "Experience the power of WiFi 5 with 802.11ac technology. Achieve high-speed and efficient wireless communication with next-gen capabilities.",
          key: " WiFi 5, 802.11ac, High-speed wireless, Gigabit WiFi, Faster wireless speeds, Efficient WiFi communication, Next-gen WiFi ",
          ogt: "Unleashing WiFi 5: Accelerate with 802.11ac for Next-Gen Gigabit Speeds",

          og: "Experience the power of WiFi 5 with 802.11ac technology. Achieve high-speed and efficient wireless communication with next-gen capabilities.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Wifi-6"
      ? {
          des: "the future of wireless connectivity with WiFi 6 technology. Elevate your network with 802.11ac, high-speed and efficient communication in the next-gen.",
          key: " WiFi 6, 802.11ac, High-speed wireless, Gigabit WiFi, Faster wireless speeds, Efficient WiFi communication, Next-gen WiFi ",
          ogt: "Empowering Networks: WiFi 6 and 802.11ac for Next-Gen Gigabit Speeds",

          og: "the future of wireless connectivity with WiFi 6 technology. Elevate your network with 802.11ac, high-speed and efficient communication in the next-gen.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "EPABX"
      ? {
          des: "Upgrade communication with EPABX intercom systems. Explore efficient solutions like Accord EPABX intercom systems for seamless connectivity.",
          key: "epabx intercom system, epabx intercom, accord epabx intercom, telesoft intercom system.",
          ogt: "Enhance Connectivity: EPABX Intercom Systems, Accord, and Telesoft Solutions",
          og: "Upgrade communication with EPABX intercom systems. Explore efficient solutions like Accord EPABX intercom systems for seamless connectivity.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "IPPBX"
      ? {
          des: "Optimize your communication with IP PBX in intercom systems. Explore advanced solutions for seamless connectivity and efficient voice communication.",
          key: "ippbx in intercom, IP PBX, intercom system, IP PBX, VoIP intercom, Scalable PBX solutions.",
          ogt: "Revolutionize Connectivity: IPPBX in Intercom Systems for Seamless Communication",
          og: "Optimize your communication with IP PBX in intercom systems. Explore advanced solutions for seamless connectivity and efficient voice communication.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "InterCom"
      ? {
          des: "Elevate communication with Intercom solutions. Explore affordable intercom pricing and choose a trusted intercom company for digital connectivity.",
          key: "Elevate communication with Intercom solutions. Explore affordable intercom pricing, advanced systems, and choose a trusted intercom company for digital connectivity.",
          ogt: "Unlock Seamless Communication: Intercom Solutions and Digital Connectivity",
          og: "Elevate communication with Intercom solutions. Explore affordable intercom pricing and choose a trusted intercom company for digital connectivity.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "GamingLaptop"
      ? {
          des: "Discover top-notch gaming laptops, including Lenovo, HP, and Dell. Find affordable and high-performance options for an immersive gaming experience.",
          key: "gaming laptop, Lenovo gaming laptop, HP gaming laptop, cheap gaming laptop, Dell gaming laptop",
          ogt: "Explore High-Performance Gaming Laptops",
          og: "Discover top-notch gaming laptops, including Lenovo, HP, and Dell. Find affordable and high-performance options for an immersive gaming experience.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Consumerlaptops"
      ? {
          des: "Discover the best consumer laptops with top brands like HP, Dell, and Lenovo. Find affordable prices and reliable performance for your everyday computing needs.",
          key: "gaming laptop, Lenovo gaming laptop, HP gaming laptop, cheap gaming laptop, Dell gaming laptop",
          ogt: "Find the Best Consumer Laptops – HP, Dell, Lenovo | Affordable Prices",
          og: "Discover the best consumer laptops with top brands like HP, Dell, and Lenovo. Find affordable prices and reliable performance for your everyday computing needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "WorkStation"
      ? {
          des: "Discover high-performance mobile workstations from top brands like HP and Lenovo. Find reliable and portable workstation laptops for your professional needs.",
          key: "mobile workstation, HP workstation, workstation PC, Lenovo workstation, portable workstation, laptop workstation",
          ogt: "Discover the Best Mobile Workstations – HP, Lenovo | Portable Excellence",
          og: "Discover high-performance mobile workstations from top brands like HP and Lenovo. Find reliable and portable workstation laptops for your professional needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "BrandedDesktop"
      ? {
          des: "Discover top-notch branded desktops for sale – Dell, HP. Find high-performance desktop PCs at affordable prices. Explore the best deals on desktop computers.",
          key: "desktop prices, desktop PC, desktop computer for sale, Dell desktop, HP desktop, high-performance desktop",
          ogt: "Explore High-Performance Branded Desktops – Dell, HP | Affordable Prices",
          og: "Discover top-notch branded desktops for sale – Dell, HP. Find high-performance desktop PCs at affordable prices. Explore the best deals on desktop computers.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "AssembledDesktop"
      ? {
          des: "xplore custom-built, high-performance assembled desktops with top-notch hardware. Tailor your PC with precision for unbeatable performance and reliability.",
          key: " assembled desktop, custom-built PC, high-performance desktop, custom PC build, desktop hardware, desktop price",
          ogt: "Build Your Best Assembled Desktop – High-Performance, Custom-Built Excellenc",
          og: "xplore custom-built, high-performance assembled desktops with top-notch hardware. Tailor your PC with precision for unbeatable performance and reliability.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "WorkStationDesktop"
      ? {
          des: "Explore top-tier desktop workstations from HP and Lenovo. Unlock superior performance and competitive prices with our reliable workstation PCs.",
          key: "desktop workstation, HP workstation, workstation PC, Lenovo workstation, workstation price",
          ogt: "Powerful Workstation Desktops – HP, Lenovo | Superior Performance, Competitive Prices",
          og: "Explore top-tier desktop workstations from HP and Lenovo. Unlock superior performance and competitive prices with our reliable workstation PCs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "GamingDesktop"
      ? {
          des: "Discover top-notch gaming desktops and pre-built gaming PCs by HP. Unleash powerful gaming experiences with reliable performance and cutting-edge technology",
          key: "gaming desktop, gaming PC, pre-built gaming PC, HP gaming PC, HP PC, pre-built PC",
          ogt: "Best Gaming Desktops for Ultimate Performance and Enjoyment",
          og: "Discover top-notch gaming desktops and pre-built gaming PCs by HP. Unleash powerful gaming experiences with reliable performance and cutting-edge technology",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "BillingApps"
      ? {
          des: "Streamline transactions with our Small Business POS Billing, Invoicing, and Retail Software. Explore the convenience of a Cash Register App and Mobile Billing System",
          key: "Small Business POS Billing, Point of Sale Billing, POS Invoicing, Retail Billing Software, Cash Register App, Mobile Billing System",
          ogt: "Efficient Business POS Billing with Point of Sale App ",
          og: "Streamline transactions with our Small Business POS Billing, Invoicing, and Retail Software. Explore the convenience of a Cash Register App and Mobile Billing System",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Laser"
      ? {
          des: "Discover top-notch laser printers - color options, HP, Brother LaserJet, and the best choices for home use. Find competitive prices and reliable printing performance",
          key: "laser printer, color laser printer, HP printer, Brother laser printer, LaserJet printer, best laser printer, laser printer for home, printer price",
          ogt: "Explore High-Quality Laser Printers - Color, HP, Brother Laser printer",
          og: "Discover top-notch laser printers - color options, HP, Brother LaserJet, and the best choices for home use. Find competitive prices and reliable printing performance",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Thermal"
      ? {
          des: "Explore top-rated thermal printers, including Zebra label printers, at the best prices. Find the perfect thermal label printer for your needs.",
          key: "Thermal printer, thermal label printer, Epson Thermal printers, best thermal label printer, thermal printer price",
          ogt: "The Best Thermal Printers – epson Thermal Printer, Best Prices",
          og: "Explore top-rated thermal printers, including Zebra label printers, at the best prices. Find the perfect thermal label printer for your needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Color"
      ? {
          des: "Explore top-rated color printers, including HP options. Find the best color laser printers and all-in-one solutions for vivid and high-quality prints",
          key: "color printer, color laser printer, color laser printer all in one, best color laser printer, HP color printer, best color printer",
          ogt: "Discover the Best Printing Solutions with the Best Color Laser Printers",
          og: "Explore top-rated color printers, including HP options. Find the best color laser printers and all-in-one solutions for vivid and high-quality prints",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "MultiFunction"
      ? {
          des: "Discover versatile options with Brother All-in-One Printers, Color Laser Printer choices, and competitive multifunction printer prices.",
          key: "brother all in one printer, color laser printer all in one, color laser printer, multifunction printer, printer price",
          ogt: "Best multifunction Printer sales in coimbatore",
          og: "Discover versatile options with Brother All-in-One Printers, Color Laser Printer choices, and competitive multifunction printer prices.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "DotMatrix"
      ? {
          des: "Discover dot matrix printers with competitive prices. Find the best dot printer for labels and reliable performance",
          key: "dot matrix printer, printer price, dot printer, dot matrix label printer, best printer",
          ogt: "Explore Dot Matrix Printers - Affordable Prices, Best Quality",
          og: "Discover dot matrix printers with competitive prices. Find the best dot printer for labels and reliable performance",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Flatbed"
      ? {
          des: "Explore top-notch flatbed scanners, including Epson and Canon. Find the best scanner prices and options for large format scanning.",
          key: "best flatbed scanner, Epson flatbed scanner, best scanner, scanner price, large format flatbed scanner, flatbed scanner, Canon flatbed scanner",
          ogt: "Discover Excellence with the Best Flatbed Scanners",
          og: "Explore top-notch flatbed scanners, including Epson and Canon. Find the best scanner prices and options for large format scanning.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Vertical"
      ? {
          des: "Discover vertical document scanners with top-notch features at the best prices. Find a low-budget scanner that meets your scanning needs efficiently.",
          key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
          ogt: "Explore Vertical Scanning Devices – Best Scanners at Affordable Prices",
          og: "Discover vertical document scanners with top-notch features at the best prices. Find a low-budget scanner that meets your scanning needs efficiently.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
        : id === "CloudHosting"
        ? {
            des: "Explore our Cloud Hosting services for high-performance, cost-effective solutions. Trust us to keep your data safe and accessible at all times.",
            key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
            ogt: "10 Benefits of Cloud Hosting for Your Business",
            og: "Explore our Cloud Hosting services for high-performance, cost-effective solutions. Trust us to keep your data safe and accessible at all times.",
            logo: "https://infygain.com/images/icons/infy-logo.svg",
          }
          : id === "DataBackup&Recovery"
          ? {
              des: "Protect your valuable data with our reliable backup and recovery solutions. Safeguard your information and ensure business continuity.",
              key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
              ogt: "What are the Key Components of Data Backup and Recovery?",
              og: "Protect your valuable data with our reliable backup and recovery solutions. Safeguard your information and ensure business continuity.",
              logo: "https://infygain.com/images/icons/infy-logo.svg",
            }
            : id === "CloudSecurity"
          ? {
              des: "Enhance your online security with our cutting-edge CloudSecurity solutions. Protect your data and privacy with our advanced technology.",
              key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
              ogt: "What are the Key Challenges in Cloud Security?",
              og: "Enhance your online security with our cutting-edge CloudSecurity solutions. Protect your data and privacy with our advanced technology.",
              logo: "https://infygain.com/images/icons/infy-logo.svg",
            }
            : id === "SaaS(SoftwareasaService)"
            ? {
                des: " Discover the latest SaaS solutions on our website and streamline your business operations efficiently. Find the perfect software for your needs today!",
                key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
                ogt: "Setting Up Your SaaS Platform: A Step-by-Step Guide",
                og: " Discover the latest SaaS solutions on our website and streamline your business operations efficiently. Find the perfect software for your needs today!",
                logo: "https://infygain.com/images/icons/infy-logo.svg",
              }
              : id === "CloudMigrationServices"
            ? {
                des: "Experience a smooth and secure transition to the cloud with our expert cloud migration services. Trust us to handle your data and applications with care.",
                key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
                ogt: "Top 10 Cloud Migration Services in Coimbatore",
                og: "Experience a smooth and secure transition to the cloud with our expert cloud migration services. Trust us to handle your data and applications with care.",
                logo: "https://infygain.com/images/icons/infy-logo.svg",
              }
              : id === "ServerInstallation"
              ? {
                  des: "Professional server installation services in Coimbatore. Get your servers up and running smoothly with our experienced team.",
                  key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
                  ogt: " 5 Best Server Installation Services in Coimbatore",
                  og: "Professional server installation services in Coimbatore. Get your servers up and running smoothly with our experienced team.",
                  logo: "https://infygain.com/images/icons/infy-logo.svg",
                }
                : id === "ServerMaintenance"
                ? {
                    des: "Looking for reliable server maintenance in Coimbatore? Our expert team will keep your servers in top condition, so you can focus on your business.",
                    key: "Vertical scanning device, vertical document scanner, scanner price, best scanner, low-budget scanner",
                    ogt: "Essential Server Maintenance for Coimbatore Service Providers",
                    og: "Looking for reliable server maintenance in Coimbatore? Our expert team will keep your servers in top condition, so you can focus on your business.",
                    logo: "https://infygain.com/images/icons/infy-logo.svg",
                  }
                
      : "";

  return (
    <>
      <Helmet>
        {/* <title></title> */}
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{metaContent.ogt}</title>
        <link rel="canonical" href={currentUrl} />

        <link rel="icon" href={"favicon.ico"} />
        <meta name="description" content={metaContent.des} />
        <meta name="keywords" content={metaContent.key} />

        <meta
          name="google-site-verification"
          content="ocR-tZ4yt2LD1ePYD1bR4LK6GYUc0keDJcspEtblHoM"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={metaContent.ogt} />
        <meta property="og:description" content={metaContent.og} />
        <meta property="og:image" content={metaContent.logo} />
        <meta property="og:url" content="https://infygain.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Infygain Technologies" />
        {/*  END FB Open Graph */}

        {/* Twitter Card Meta Tags */}

        <meta
          name="twitter:card"
          content="Best computer/laptop sales and service in coimbatore"
        />
        <meta name="twitter:site" content="Infygain Technologies" />
        <meta name="twitter:title" content="Infygain Technologies" />
        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:description"
          content="We are provide the best computer and laptop, networking service in coimbatore and also we best in cctv camera, server, billing software, graphic design, amc."
        />
        <meta name="twitter:image" content={metaContent.logo} />
        {/* Robot tag */}
        <meta name="robots" content="index, follow" />

        {/* End Robot tag */}

        {/* Facebook Pixel Code */}
        <script type="text/javascript">
          {`
               !function(f,b,e,v,n,t,s)
               {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
               n.callMethod.apply(n,arguments):n.queue.push(arguments)};
               if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
               n.queue=[];t=b.createElement(e);t.async=!0;
               t.src=v;s=b.getElementsByTagName(e)[0];
               s.parentNode.insertBefore(t,s)}(window, document,'script',
               'https://connect.facebook.net/en_US/fbevents.js');
               fbq('init', '1093378958018811');
               fbq('track', 'PageView');
               `}
        </script>

        <noscript>
          {`
               <img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=1093378958018811&ev=PageView&noscript=1"
               />
               `}
        </noscript>
        {/* End Facebook Pixel Code */}

        {/* Organization schema*/}
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>

        {/* Breadcrumb schema*/}
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>

        {/* Local business 31/5/23 schema */}

        <script type="application/ld+json">
          {JSON.stringify(localBusinessSchema)}
        </script>

        {/* Website Schema */}
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
        {/*Article Schema */}
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
        {/*Video Schema*/}
        <script type="application/ld+json">
          {JSON.stringify(videoSchema)}
        </script>

        {/* Google Analytics */}
        <script>
          {`
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)),
      (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", "UA-XXXXX-Y", "auto");
  ga("send", "pageview");
`}
        </script>
        {/* End Google Analytics */}

        {/* Google Tag Manager */}
        <script>
          {`
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NRCN7RF");
`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-89FZ8YHYVL"
        ></script>

        <script>
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("gtag("js", new Date());
          gtag("config", "G-89FZ8YHYVL");
        `}
        </script>
        {/* End Google tag (gtag.js) */}

        {/* Google optimizer */}
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NWGHP4M"></script>
        {/* End Google optimizer */}
      </Helmet>
      <div className="container">
        <div className="opaque1">
          <div className="row overall">
            <div className="col-md-4" id="column2">
              <CommonBtns id={id} cat={cat} />
            </div>

            {/* laptop */}
            {id === "GamingLaptop" &&
              GamingLaptop.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Consumerlaptops" &&
              Consumerlaptops.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "WorkStation" &&
              WorkStation.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* desktop */}
            {id === "BrandedDesktop" &&
              BrandedDesktop.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "AssembledDesktop" &&
              AssembledDesktop.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "WorkStationDesktop" &&
              WorkStationDesktop.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "GamingDesktop" &&
              GamingDesktop.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* networking */}
            {id === "HUB" &&
              HUB.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Switch" &&
              Switch.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Router" &&
              Router.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Firewall" &&
              Firewall.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "EndPointSecurity" &&
              EndPointSecurity.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Fiber" &&
              Fiber.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "SD-WAN" &&
              Sdwan.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* Cctv */}
            {id === "Analog" &&
              Analog.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "IP" &&
              IP.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "DVR" &&
              DVR.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "NVR" &&
              NVR.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "360" &&
              cctv360.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "PTZ" &&
              PTZ.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {id === "Bio-Metric" &&
              biometric.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "BoomBarrier" &&
              boombar.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "AccessControl" &&
              accesscontrol.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* Accessories */}
            {id === "RAM" &&
              ram.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "HDD,SSD" &&
              HDDSSD.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "KeyBoard" &&
              Keyboard.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Mouse,Moniter" &&
              MouseandMonitor.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Cabinet" &&
              Cabinet.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "MotherBoard" &&
              Motherboard.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "SMPS" &&
              SMPS.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Cables,Charger" &&
              CablesandChargers.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Connector,Convertor" &&
              ConnectorsandConverters.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* Projectors */}
            {id === "LCD" &&
              LCD.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "LED" &&
              LED.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "SmartProjector" &&
              SmartProjectors.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "TV" &&
              TV.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "InteractivePanel" &&
              Interpanel.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* TV */}
            {id === "AndriodTV" &&
              AndroidTV.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "SmartTV" &&
              SmartTV.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* printer */}
            {id === "Laser" &&
              Laser.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Thermal" &&
              Thermal.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Color" &&
              color.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "MultiFunction" &&
              Multifunction.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "DotMatrix" &&
              DotMatrix.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* Scanner */}
            {id === "Flatbed" &&
              Flatbed.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "Vertical" &&
              Vertical.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* InteractivePanel */}
            {id === "InteractivePanel65" &&
              ip65.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "InteractivePanel75" &&
              ip75.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {/*  UPS */}
            {id === "Inventor" &&
              Inventor.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "OnlineUPS" &&
              OnlineUPS.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/*  WIFI */}
            {id === "AccessPointController" &&
              AccessPointController.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "InstantAccessPoint" &&
              WiFi5.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "WifiRouter" &&
              WiFi6.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "PointtoPoint" &&
              point.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/*  InterCom */}
            {id === "EPABX" &&
              EPABX.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "IPPBX" &&
              IPPBX.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "InterCom" &&
              InterCom.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {/*  Pos */}
            {id === "KIOSK" &&
              KIOSK.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "POS" &&
              POS.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "BillingApps" &&
              BillingApps.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "BarcodeScanner" &&
              barcode.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "TableTopBarcodeScanner" &&
              toptablebarcode.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}

            {/* server */}
            {id === "ServerInstallation" &&
              serverinst.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "ServerMaintenance" &&
              servermain.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "WebServer" &&
              webserver.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {id === "StorageServer" &&
              storageserver.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
              {id === "CloudHosting" &&
              CloudHosting.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
               {id === "DataBackup&Recovery" &&
              DataBackup.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
                {id === "CloudSecurity" &&
              CloudSecurity.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
               {id === "SaaS(SoftwareasaService)" &&
              Saas.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
                {id === "CloudMigrationServices" &&
              CloudMigrationServices.map((value, index) => (
                <>
                  <ProductContent value={value} />
                </>
              ))}
            {/*  InterCom */}
            {/* {
                      id==="EPABX" &&       
                      EPABX.map((value,index) => (
                        <>
                         <ProductContent value={value}/>
                        </>
                      ))
                    } */}
            {/* {
                      id==="BillingApps" &&
                      IPPBX.map((value,index) => (
                        <>
                         <ProductContent value={value}/>
                        </>
                      ))
                    } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
