import "font-awesome/css/font-awesome.css";
import "../css/blog.css";

function Banner({blogs}) {
  return (
    <>
      <div className="top-banner">
        <div>
          <h1 className="main-title">{blogs.title}</h1>
        </div>
      </div>
    </>
  );
}

export default Banner;
