import React, { useEffect, useState } from "react";
import "../css/BestService.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet'
const currentUrl = window.location.href;
const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",

  "name": "Infygain Technologies",
  "legalName": "Computer Service",
  "email": "info@infygain.com",
  "url": "https://infygain.com/",
  "logo": "https://infygain.com/images/logo.svg",
  "sameAs": [
    "https://twitter.com/infygain",
    "https://www.instagram.com/infygain/",
    "https://www.facebook.com/infygain/",
    "https://www.linkedin.com/company/infygain-technologies/",
    "https://www.youtube.com/@infygain6707",
    "https://infygain.com/"
  ],
  "address": {
    "addressLocality": "prozone mall",
    "postalCode": "641035",
    "streetAddress": "Hari complex opp.prozone mall, Sathy Rd, Saravanampatti, Coimbatore, Tamil Nadu "
  }
};

const breadcrumbSchema = {
  "@context": "https://schema.org/",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://infygain.com/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "About - Us",
      "item": "https://infygain.com/about"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "IT Supports",
      "item": "https://infygain.com/Iotservice"
    },
    {
      "@type": "ListItem",
      "position": 4,
      "name": "ISP Services",
      "item": "https://infygain.com/ispservice"
    },
    {
      "@type": "ListItem",
      "position": 5,
      "name": "WEB Services",
      "item": "https://infygain.com/ServiceSeo"
    },
    {
      "@type": "ListItem",
      "position": 6,
      "name": "Blogs",
      "item": "https://infygain.com/mainblogs"
    },
    {
      "@type": "ListItem",
      "position": 7,
      "name": "Career",
      "item": "https://infygain.com/career"
    },
    {
      "@type": "ListItem",
      "position": 8,
      "name": "Partner",
      "item": "https://infygain.com/Partner"
    },
    {
      "@type": "ListItem",
      "position": 9,
      "name": "Contact",
      "item": "https://infygain.com/contact"
    },
    {
      "@type": "ListItem",
      "position": 10,
      "name": "Online Store",
      "item": "https://infygain.com/onlinestore/"
    }
  ]
};

const localBusinessSchema = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Infygain Technologies",
  "image": "https://infygain.com/images/logo.svg",
  "@id": "",
  "url": "https://infygain.com",
  "telephone": "9952141444",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "hari complex opp.prozone mall, Sathy Rd, Saravanampatti",
    "addressLocality": "Coimbatore",
    "postalCode": "641035",
    "addressCountry": "IN"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 11.0549976,
    "longitude": 76.9951292
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "opens": "09:45",
    "closes": "19:00"
  }
};


const websiteSchema = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Infygain Technologies",
  "url": "https://infygain.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://infygain.com/{search_term_string}",
    "query-input": "required name=search_term_string"
  }
};


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://infygain.com/"
  },
  "headline": "Best IT support and web development company in Coimbatore",
  "description": "We are a prominent IT service provider, based in Coimbatore, with a wide range of products and services, to meet the exclusive needs of our customers. We offer end-to-end solutions to enable a reliable and uninterrupted workflow for our customers.",
  "image": "https://infygain.com/images/others/services.webp",
  "author": {
    "@type": "Organization",
    "name": ""
  },
  "publisher": {
    "@type": "Organization",
    "name": "Infygain Technologies",
    "logo": {
      "@type": "ImageObject",
      "url": "https://infygain.com/images/logo.svg"
    }
  },
  "datePublished": "2024-01-12"
};
const videoSchema = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Infygain Technologies",
  "description": "INFYGAIN - Leading Computer Service at Doorstep in Coimbatore",
  "thumbnailUrl": "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
  "uploadDate": "2024-01-12T12:00:00Z",
  "duration": "PT1M1M",  // Replace with the actual duration of your video
  "contentUrl": "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
  "embedUrl": "https://www.youtube.com/embed/CdP9VCScVmQ",
  "publisher": {
    "@type": "Organization",
    "name": "Infygain Technologies",
    "logo": {
      "@type": "ImageObject",
      "url": "URL to your organization's logo"
    }
  }
};
function BestService() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const { id } = useParams();

  const [serv, setServ] = useState(null);

  useEffect(() => {
    if (id === "Websites") {
      setServ("Websites");
    }
    if (id === "Seo") {
      setServ("Seo");
    }
    if (id === "Application") {
      setServ("Application");
    }
    if (id === "Designing") {
      setServ("Designing");
    }
  }, [id]);
  let metaContent = (id === "Websites") ? {
    des: "Website Development Company in Coimbatore: Looking for a professional Website Development Company? Look no further! We create stunning websites that stand out.",
    key: "website design, web development, Coimbatore,dynamic website, ecommerce website,static website ",
    ogt: "Website Development Company in coimbatore|web design Services",
    og: "Website Development Company in Coimbatore: Looking for a professional Website Development Company? Look no further! We create stunning websites that stand out.",
    logo: "https://infygain.com/images/icons/infy-logo.svg"
  }:
    (id === "Seo") ? {
    des: "SEO Service:Unlock the power of organic traffic with the best SEO solutions. As the top SEO company, we're dedicated to maximizing your online presence. ",
    key: "best SEO services, best SEO company, best SEO solutions, organic traffic,best seo",
    ogt: "SEO Service provider in coimbatore|SEO services company in coimbatore",
    og: "Unlock the power of organic traffic with the best SEO solutions. As the top SEO company, we're dedicated to maximizing your online presence. ",
    logo: "https://infygain.com/images/icons/infy-logo.svg"
  } :
  (id === "Application") ? {
    des: "Web App Development Company in Coimbatore.Elevate your business with web app development company.  Expert app developers delivering customized solutions.  ",
    key: "top web application company, professional application development, custom web applications, Coimbatore app developer, innovative web solutions, reliable app development services, ",
    ogt: "Web App Development Company in coimbatore|App Development in coimbatore",
    og: "Elevate your business with the best web application company. Expert application developers delivering customized solutions.  ",
    logo: "https://infygain.com/images/icons/infy-logo.svg"
  } :
  (id === "Designing") ? {
    des: "UI UX Design Company in Coimbatore.Elevate your brand with expert graphic design services. From logos to branding, we craft visually stunning solutions. ",
    key: "graphic design services, logo design, branding solutions, visual identity",
    ogt: "UI UX Design Company in Coimbatore|Graphic Design Company in coimbatore",
    og: "Elevate your brand with expert graphic design services. From logos to branding, we craft visually stunning solutions. ",
    logo: "https://infygain.com/images/icons/infy-logo.svg"
  } :""
  
  return (
    <>
    <Helmet>
        {/* <title></title> */}
        <meta charset="UTF-8" />                                                                                          
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{metaContent.ogt}</title>
        <link rel="canonical" href={currentUrl} />

        <link rel="icon" href={"favicon.ico"} />
        <meta name="description" content={metaContent.des} />
        <meta name="keywords" content={metaContent.key} />

        <meta name="google-site-verification" content="ocR-tZ4yt2LD1ePYD1bR4LK6GYUc0keDJcspEtblHoM" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={metaContent.ogt} />
        <meta property="og:description" content={metaContent.og} />
        <meta property="og:image" content={metaContent.logo} />
        <meta property="og:url" content="https://infygain.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Infygain Technologies" />
        {/*  END FB Open Graph */}

        {/* Twitter Card Meta Tags */}

        <meta name="twitter:card" content="Best computer/laptop sales and service in coimbatore" />
        <meta name="twitter:site" content="Infygain Technologies" />
        <meta name="twitter:title" content="Infygain Technologies" />
        <meta name="twitter:card" content="summary" />

        <meta name="twitter:description" content="We are provide the best computer and laptop, networking service in coimbatore and also we best in cctv camera, server, billing software, graphic design, amc." />
        <meta name="twitter:image" content={metaContent.logo} />
        {/* Robot tag */}
        <meta name="robots" content="index, follow" />

        {/* End Robot tag */}

        {/* Facebook Pixel Code */}
        <script type="text/javascript">
          {`
               !function(f,b,e,v,n,t,s)
               {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
               n.callMethod.apply(n,arguments):n.queue.push(arguments)};
               if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
               n.queue=[];t=b.createElement(e);t.async=!0;
               t.src=v;s=b.getElementsByTagName(e)[0];
               s.parentNode.insertBefore(t,s)}(window, document,'script',
               'https://connect.facebook.net/en_US/fbevents.js');
               fbq('init', '1093378958018811');
               fbq('track', 'PageView');
               `}
        </script>

        <noscript>{`
               <img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=1093378958018811&ev=PageView&noscript=1"
               />
               `}
        </noscript>
        {/* End Facebook Pixel Code */}

       {/* Organization schema*/}
       <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>

        {/* Breadcrumb schema*/}
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>


        {/* Local business 31/5/23 schema */}

        <script type="application/ld+json">
          {JSON.stringify(localBusinessSchema)}
        </script>

        {/* Website Schema */}
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
        {/*Article Schema */}
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
        {/*Video Schema*/}
        <script type="application/ld+json">
          {JSON.stringify(videoSchema)}
        </script>

       

       


        {/* Google Analytics */}
        <script>
          {`
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)),
      (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", "UA-XXXXX-Y", "auto");
  ga("send", "pageview");
`}
        </script>
        {/* End Google Analytics */}

        {/* Google Tag Manager */}
        <script>
          {`
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NRCN7RF");
`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-89FZ8YHYVL"></script>

        <script>
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("gtag("js", new Date());
          gtag("config", "G-89FZ8YHYVL");
        `}
        </script>
        {/* End Google tag (gtag.js) */}

        {/* Google optimizer */}
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NWGHP4M"></script>
        {/* End Google optimizer */}
      </Helmet>
      <div className="top-banner">
        <h1 className="main-title">{serv === "Websites" ?"Website Development Company in Coimbatore":serv === "Seo" ?"SEO Services Company in coimbatore":serv === "Application" ?"Web App Development in coimbatore":"UI UX Design Company in Coimbatore"}</h1>
      </div>

      {/* 1 */}
      {serv === "Websites" ? (
        <div className="container lolp mt-5 mb-5">
          <div className="hkl">
            <h2 className="bril mb-4 webfontchange">Best Website Development Company in Coimbatore</h2>
            {/* <p className="text-muted webfontchanges">Elevate your online presence with our top-rated <a href="/contact"><b>Website Design Company</b></a> in Coimbatore. Let us bring your vision to life!</p> */}
            <p className="text-muted webfontchanges">Welcome to Infygain Technologies, the best company in Coimbatore for top <b><a href="/contact">website design and development services</a></b>. Our skilled team of experts is committed to building technically amazing extremely useful, and user-friendly websites that are specifically designed for your company's specific needs.</p>
          </div>

          <div className="row">
            <div
              className="col-md-4 col-lg-4  popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk">
                <img
                  src="/images/Website-Design-Company-in-Coimbatore.webp"
                  alt="Website-Design-Company-in-Coimbatore"
                  className="image-fluid"
                  title="Website-Design-Company-in-Coimbatore"
                />
              </div>

              {/* <div className='butk'>
               <button className='btn butks'>
               <a href="https://infygain.com/onlinestore/products/ca/178"><FaCartPlus/> buy now </a>
                </button>
               </div> */}
            </div>
            <div
              className="col-md-8 col-lg-8   wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra"></div>
                <h2>Static Website Development</h2>
              </div>
              <p className="text-muted mt-2">
              Leading Static<a className="pos-text"href="/contact"> Website Development Company</a> in Coimbatore offering top-notch services. Get a professional website for your business today!
              </p>
              <p className="text-muted ">
              At Infygain Technologies, we provide excellent Static <b>Website Development Services</b> in Coimbatore that are customized to your company's specific requirements. Businesses searching for quick, safe, and affordable <b><a href="/contact">web solutions</a></b> should consider static websites. Static websites guarantee fast load times and improved security, offering a smooth user experience, because they have fixed content and don't require server-side processing. Static websites made by our talented team of designers and developers are visually striking, responsive, and search engine optimization-friendly, enabling you to stand out in the crowded online market.
                <br />
                
                <button className="serv-btns1 text-center text-light-blogss mt-2">
                  <Link
                    className="serv-link"
                    to="/ServiceSeo?scrollTo=projects"
                  >
                    {" "}
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </Link>
                </button>
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-7 col-lg-7 wifit1"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra"></div>
                <h2>Dynamic Website Development</h2>
              </div>
              <p className="text-muted mt-2">
              Looking for a <a className="pos-text"href="/contact">dynamic website Development company</a> in Coimbatore? Our experts create stunning websites that engage your audience.               </p>
              <p className="text-muted">
              At Infygain Technologies, we're experts in providing dynamic <b>website design services in Coimbatore</b>. We build dynamic, feature-rich websites that are adapted to your company's changing demands. Server-side scripting and databases are used by <b><a href="/contact">dynamic websites</a></b> to create content in real time, giving each visitor a unique experience. Advanced features like user logins, e-commerce capabilities, content management systems (CMS), and more are made possible by this flexibility. Our knowledgeable staff makes sure your dynamic website is not only aesthetically pleasing but also incredibly safe, functional, and user-friendly.
                <button className="serv-btns1 text-center text-light-blogss mt-2">
                  <Link
                    className="serv-link"
                    to="/ServiceSeo?scrollTo=projects"
                  >
                    {" "}
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </Link>
                </button>
              </p>
            </div>
            <div
              className="col-md-5 col-lg-5 olk"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk2">
                <img
                  src="/images/web-design-company-in-coimbatore.webp"
                  alt="website design company in coimbatore"
                  className="image-fluid"
                  title="web-design-company-in-coimbatore"
                />
              </div>

              {/* <div className='butk'>
               <button className='btn butks'>
               <a href="https://infygain.com/onlinestore/products/ca/181"><FaCartPlus/> buy now </a> 
               </button>
               </div> */}
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk">
                <img
                  src="/images/web-design-in-coimbatore.webp"
                  alt="web-design-in-coimbatore"
                  className="image-fluid"
                  title="web-design-in-coimbatore"
                />
              </div>

              {/* <div className='butk'>
               <button className='btn butks'>
               <a href="https://infygain.com/onlinestore/products/ca/178"><FaCartPlus/> buy now </a>
                </button>
               </div> */}
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra"></div>
                <h3>E-commerce Website Development</h3>
              </div>
              <p className="text-muted mt-2">
                Unlock online success with our E-commerce websites – your
                gateway to seamless transactions and satisfied customers.
              </p>
              <p className="text-muted ">
                "Elevate your business presence on the digital frontier with our
                E-commerce websites. Our solutions offer seamless shopping
                experiences, ensuring secure transactions and a user-friendly
                interface crafted for your success. Embrace the power of online
                commerce with confidence." <br />
                <button className="serv-btns1 text-center text-light-blogss mt-2">
                  <Link
                    className="serv-link"
                    to="/ServiceSeo?scrollTo=projects"
                  >
                    {" "}
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </Link>
                </button>
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-6 col-lg-6  probl"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra"></div>
                <h3>Discover Our Services</h3>
              </div>
              <ul className="text-muted">
                <li> <b>Custom Website Design</b>
                  <p>
                  Customized layouts for a smooth user experience across devices, interesting user interfaces, easy navigation, and designs that capture your company identity.
                  </p>
                </li>
                <li><b>E-commerce Web Design & Development</b>
                  <p>
                  robust e-commerce systems featuring user-friendly product catalogs and shopping cart systems, safe payment gateways, and connectivity with well-known e-commerce tools and plugins.
                  </p>
                </li>
                <li><b>Website Redesign and Maintenance</b>
                  <p>
                  Renovate and modernize your current website; provide regular maintenance and updates to guarantee peak performance; and provide technical help and troubleshooting.
                  </p>
                </li>
                <li><b>SEO-Friendly Design</b>
                  <p>
                  Search engine optimized websites for more exposure, on-page SEO strategies included into the layout, and frequent audits and upgrades to sustain top search ranks.
                  </p>
                </li>
                {/* <li><b>Web App Development</b>
                  <p>
                  Web application development and design for smooth user experiences, website integration for a unified platform, and unique features and functionalities catered to your company's requirements.
                  </p>
                </li> */}
                {/* <li>
                  <p>
                    Ensure your website loads quickly and performs optimally,
                    providing a smooth and enjoyable browsing experience.
                  </p>
                </li> */}
              </ul>
            </div>

            <div
              className="col-md-6 col-lg-6  popp"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk1 coverage">
                <img
                  src="/web-design-concept.webp"
                  alt="best Web design company"
                  className="image-fluid"
                  title="Web design"
                />
                
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* 2 */}
      {serv === "Seo" ? (
        <div className="container lolp mt-5 mb-5">
          <div className="hkl">
            <h2 className="bril mb-5 webfontchange"> Best SEO Company in Coimbatore </h2>
          </div>
          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk">
                <img
                  src="/images/SEO PAGE VECTOR.webp"
                  alt="best on-page Seo services"
                  className="image-fluid"
                  title="best Seo services"
                />
              </div>

              {/* <div className='butk'>
               <button className='btn butks'>
                <a href="https://infygain.com/onlinestore/"><FaCartPlus/> buy now </a></button>
               </div> */}
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra1"></div>

                <h3>On-Page SEO</h3>
              </div>
              <p className="text-muted ">
                <b>SEO Services Provider</b>: Unlock the full potential of your website's visibility with our
                dedicated <b> On-Page SEO services</b>. At Infygain Technologies, we
                understand that the best on-page SEO practices are essential for
                a strong online presence. Our experts meticulously optimize
                every element of your web pages, from content and meta tags to
                headers and internal links. By strategically incorporating
                high-value keywords and ensuring a seamless user experience, we
                aim to propel your pages to the top of search engine results.
                Trust us to lay a robust foundation for your online success
                through the best on-page SEO strategies.{" "}
              </p>
              <p className="text-muted ">
                Elevate your online presence with precision. Our <b>On-Page SEO
                services</b> ensure your website is finely tuned for search engine
                success. From strategic keyword integration to seamless user
                experience, trust us for the best On-Page SEO practices.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns2 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-7 col-lg-7  wifit1"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra1"></div>
                <h3>Off-Page SEO</h3>
              </div>
              <div className="piol">
                <p className="text-muted mt-2">
                <b>SEO Services in Coimbatore</b>: Elevate your online influence with our targeted Off-Page SEO
                  strategies. Building a robust online presence requires more
                  than just on-site optimizations. Our approach to off-page SEO
                  at Infygain Technologies focuses on acquiring high-quality
                  backlinks, fostering social media engagement, and managing
                  your online reputation. We believe in securing the best
                  off-page SEO elements that contribute to your website's
                  authority and credibility. By strategically connecting your
                  brand with the broader digital landscape, we ensure a lasting
                  impact that extends beyond your website's boundaries.
                </p>
                <p className="text-muted">
                  Extend your impact beyond your website. Our Off-Page SEO
                  strategies focus on building authority through high-quality
                  backlinks, social engagement, and online reputation
                  management. Choose us for the best Off-Page SEO elements that
                  amplify your brand's influence.
                  <br />
                  <Link
                    className="serv-link"
                    to="/ServiceSeo?scrollTo=projects"
                  >
                    <button className="serv-btns2 text-center text-light-blogss mt-2">
                      View Our Projects &nbsp;{" "}
                      <span className="fa fa-arrow-right"></span>{" "}
                    </button>
                  </Link>
                </p>
              </div>
            </div>
            <div
              className="col-md-5 col-lg-5  olk"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk1">
                <img
                  src="/images/seo-sec.webp"
                  alt="best off-page Seo services"
                  className="image-fluid"
                  title="best Seo services"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk">
                <img
                  src="/images/seo-third.webp"
                  alt="best Seo company"
                  className="image-fluid"
                  title="best Seo services"
                />
              </div>

              {/* <div className='butk'>
               <button className='btn butks'>
                <a href="https://infygain.com/onlinestore/"><FaCartPlus/> buy now </a></button>
               </div> */}
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra1"></div>

                <h3>Ranking</h3>
              </div>
              <p className="text-muted ">
                Achieving and maintaining high rankings in search engine results
                is at the core of our SEO philosophy. At Infygain Technologies,
                we are dedicated to securing the best rankings for your
                business. Our comprehensive approach starts with thorough
                keyword research and strategic on-page targeting to ensure you
                rank for the most relevant terms. We extend our commitment to
                optimizing your website's speed, ensuring mobile responsiveness,
                and adhering to the best SEO practices. The culmination of these
                efforts positions your website for the best ranking in the
                dynamic landscape of search engine results. Trust us to secure
                and improve your rankings, ensuring your business stands out
                when your audience searches for relevant terms.
              </p>
              <p className="text-muted ">
                Reach the pinnacle of search engine results. Our comprehensive
                approach, blending strategic on-page optimization and
                authoritative off-page strategies, ensures your business secures
                and maintains the best rankings. Trust us to navigate the
                dynamic landscape for sustained online success.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns2 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-6 col-lg-6 probl"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra1"></div>
                <h3>Discover Our Services</h3>
              </div>
              <ul className="text-muted">
                <li>
                  <p>
                    In-depth analysis of your current online presence and
                    Identification of optimization opportunities and challenges.
                  </p>
                </li>
                <li>
                  <p>
                    Meticulous research to target high-performing keywords and
                    Integration of strategically chosen keywords into your
                    content.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Optimization of meta tags, headings, and content structure.
                  </p>
                </li>
                <li>
                  <p>
                    Building authoritative backlinks to boost domain authority.
                  </p>
                </li>
                <li>
                  <p>
                    Google My Business optimization for enhanced local presence.
                  </p>
                </li>
                <li>
                  <p>Analytics-driven insights for continuous improvement.</p>
                </li>
                <li>
                  <p>
                    Proactive adjustments to stay current with search engine
                    algorithms.
                  </p>
                </li>
                <li>
                  <p>
                    Expert guidance on SEO best practices and Training sessions
                    to empower your team for ongoing success.
                  </p>
                </li>
              </ul>
            </div>

            <div
              className="col-md-6 col-lg-6 popp"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk1 coverage">
                <img
                  src="/images/seo-three.webp"
                  alt="best Seo services"
                  className="image-fluid"
                  title="best Seo services"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* 3 */}
      {serv === "Application" ? (
        <div className="container lolp mt-5 mb-5">
          <div className="hkl">
            <h2 className="bril mb-5 webfontchange">App Development Company in Coimbatore</h2>
          </div>
          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk">
                <img
                  src="/images/custom-web.webp"
                  alt="best web development company"
                  className="image-fluid"
                  title="Web Application"
                />
              </div>
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra2"></div>
                <h3>Custom Web Application</h3>
              </div>
              <p className="text-muted mt-2">
               <b><a className="pos-text"href="/ServiceSeo?scrollTo=projects">App Development Company</a></b>: At Infygain Technologies, we specialize in crafting tailored web
                <b> App</b> that meet your unique business needs. Our expert
                team merges cutting-edge technology with innovative solutions to
                create scalable, secure, and user-friendly <b><a className="pos-text" href="/ServiceSeo?scrollTo=projects">web app</a></b>.
                From conceptualization to deployment, we ensure your
                custom-built solution aligns seamlessly with your business
                objectives, offering a competitive edge in the digital
                landscape.
              </p>
              <p className="text-muted ">
                We prioritize understanding your business intricacies to develop
                customized <b><a className="pos-text"href="/ServiceSeo?scrollTo=projects">web applications</a></b> that optimize processes and drive
                growth. Our iterative development process involves constant
                communication and feedback, ensuring your vision is translated
                into a robust, functional application. Whether you require a new
                <b> web app</b> or enhancements to an existing one, Infygain
                Technologies is dedicated to delivering reliable,
                high-performance solutions that empower your business.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns3 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-7 col-lg-7   wifit1"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra2"></div>
                <h3>Cross-Platform Application Development</h3>
              </div>
              <p className="text-muted mt-2">
                At Infygain Technologies, we specialize in crafting versatile
                and efficient applications that run seamlessly across multiple
                platforms. Our skilled team harnesses the power of
                <b>cross-platform development</b> frameworks to create robust
                applications that offer a unified user experience on various
                devices and operating systems. From conceptualization to
                deployment, we ensure your app reaches a wider audience without
                compromising on performance or functionality.
              </p>
              <p className="text-muted">
                We understand the significance of broad accessibility without
                sacrificing quality. Our iterative development process
                emphasizes client collaboration, ensuring your vision aligns
                perfectly with our technical expertise. Whether you're launching
                a new cross-platform <b>app</b> or looking to enhance an existing one,
                Infygain Technologies is dedicated to delivering scalable,
                reliable, and user-centric <b>applications</b> that drive engagement
                and growth
                
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns3 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>
              </p>
            </div>
            <div
              className="col-md-5 col-lg-5   olk"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk2">
                <img
                  src="/images/cross-platform.webp"
                  alt="best app development"
                  className="image-fluid"
                  title="Application development"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk">
                <img
                  src="/images/Api-integ.webp"
                  alt="best API development"
                  className="image-fluid"
                  title="API development"
                />
              </div>
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra2"></div>
                <h3>API Development and Integration</h3>
              </div>
              <p className="text-muted mt-2">
                At Infygain Technologies, we specialize in crafting robust and
                scalable APIs tailored to streamline data exchange and enhance
                connectivity across diverse platforms and systems. Our expert
                team leverages cutting-edge technologies to design and develop
                APIs that enable seamless integration, empowering your
                applications with enhanced functionality and interoperability.
              </p>
              <p className="text-muted ">
                We prioritize understanding your unique business requirements to
                create APIs that perfectly align with your objectives. Our
                expertise lies in developing APIs that are secure, efficient,
                and easily adaptable to evolving technological landscapes.
                Whether you need custom API development, integration services,
                or API strategy consultation, Infygain Technologies ensures
                reliable solutions that optimize your workflows and drive
                efficiency.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns3 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-6   probl"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra2"></div>
                <h3>Discover Our Services</h3>
              </div>
              <ul className="text-muted">
                <li>
                  <p>
                    {" "}
                    Crafting customized web applications aligned with specific
                    business goals and requirements.
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    Creating applications that adapt seamlessly to various
                    devices and operating systems while maintaining consistent
                    user experiences.
                  </p>
                </li>
                <li>
                  <p>
                    Designing interfaces that ensure uniformity across different
                    platforms, enhancing intuitive navigation for diverse users.
                  </p>
                </li>
                <li>
                  <p>
                    Emphasizing performance through smart development practices
                    and leveraging cross-platform technologies.
                  </p>
                </li>
                <li>
                  <p>
                    Crafting scalable, secure APIs tailored precisely to meet
                    business needs and ensuring compliance with industry
                    standards.
                  </p>
                </li>
                <li>
                  <p>
                    Seamlessly integrating APIs into existing systems to
                    streamline operations and facilitate smooth data exchange.
                  </p>
                </li>
              </ul>
            </div>

            <div
              className="col-md-6 col-lg-6  popp"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk1 coverage">
                <img
                  src="/images/app-services.webp"
                  alt="best Application services"
                  className="image-fluid"
                  title="Our services"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* 4 */}
      {serv === "Designing" ? (
        <div className="container lolp mt-5 mb-5">
          <div className="hkl">
            <h2 className="bril mb-5">UI UX Design Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk1">
                <img
                  src="/images/graphic.webp"
                  alt="graphic designing company"
                  className="image-fluid"
                  title="Graphic Design"
                />
              </div>
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra3"></div>
                <h2>Graphic Design</h2>
              </div>
              <p className="text-muted mt-2">
                At Infygain Technologies, we specialize in crafting captivating
                visual experiences that elevate your brand presence. Our expert
                designers blend creativity with strategic thinking to deliver
                tailored <a className="pos-text"href="/contact">graphic design</a> solutions. From brand identity and print
                design to digital assets and user-friendly interfaces, we bring
                your vision to life with precision and impact.
              </p>
              <p className="text-muted ">
                With a focus on understanding your brand's essence and goals, we
                create designs that resonate with your audience. Our
                collaborative approach ensures your input is valued throughout
                the process. Whether you're starting fresh or looking to revamp
                your brand, Infygain Technologies is dedicated to delivering
                timely, high-quality <b>design solutions</b> that leave a lasting
                impression.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns4 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-7 col-lg-7  wifit1"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra3"></div>
                <h3>Branding & Identity</h3>
              </div>
              <p className="text-muted mt-2">
                At Infygain Technologies, we specialize in crafting
                comprehensive branding and identity solutions that resonate with
                your audience and embody the essence of your brand. From logos
                and color palettes to defining visual elements, our expert team
                creates cohesive and impactful designs that set your brand apart
                and leave a lasting impression.
              </p>
              <p className="text-muted">
                At Infygain Technologies, we recognize the pivotal role branding
                plays in shaping a company's identity. Our approach revolves
                around deeply understanding your brand's values, target
                audience, and market positioning. We tailor our designs to
                encapsulate your brand's uniqueness, ensuring consistency across
                all touchpoints. With a keen eye for detail and a commitment to
                creativity, our designs not only stand out but also communicate
                your brand's story effectively.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns4 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>
              </p>
            </div>
            <div
              className="col-md-5 col-lg-5  olk"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk2">
                <img
                  src="/images/brand-design.webp"
                  alt="Logo Design Specialists"
                  className="image-fluid"
                  title="Branding design"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-4 col-lg-4 popp"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="lmk2">
                <img
                  src="/images/uiandux.webp"
                  alt="UI/UX Design Experts"
                  className="image-fluid"
                  title="UI/UX Design"
                />
              </div>
            </div>
            <div
              className="col-md-8 col-lg-8 wifit"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="nmk">
                <div className="libra3"></div>
                <h3>UI/UX Design</h3>
              </div>
              <p className="text-muted mt-2">
                At Infygain Technologies, we specialize in creating seamless and
                intuitive digital experiences. Our UI/UX design team combines
                aesthetics with functionality, crafting interfaces that
                captivate users and elevate your brand. From wireframes to
                prototypes, we prioritize user-centric design to ensure your
                digital platforms engage and delight your audience.
              </p>
              <p className="text-muted ">
                We understand the critical role of user experience in driving
                engagement. Our collaborative process involves you at every
                stage, ensuring your vision aligns with our expertise. Whether
                you're launching a new app or revamping an existing platform,
                Infygain Technologies is committed to delivering user-focused
                designs that enhance usability and leave a lasting impression.
                <br />
                <Link className="serv-link" to="/ServiceSeo?scrollTo=projects">
                  <button className="serv-btns4 text-center text-light-blogss mt-2">
                    View Our Projects &nbsp;{" "}
                    <span className="fa fa-arrow-right"></span>{" "}
                  </button>
                </Link>
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-6 col-lg-6  probl"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="nmk1">
                <div className="libra3"></div>
                <h3>Discover Our Services</h3>
              </div>
              <ul className="text-muted">
                <li>
                  <p>
                    Crafting unique brand identities through logos, color
                    schemes, and visual elements that resonate with your
                    audience.
                  </p>
                </li>
                <li>
                  <p>
                    Creating engaging graphics and illustrations for marketing
                    materials, social media content, and print collateral to
                    enhance brand storytelling.
                  </p>
                </li>
                <li>
                  <p>
                    Designing user interfaces and experiences for websites and
                    applications, ensuring seamless navigation and interaction
                    for your audience.
                  </p>
                </li>
                <li>
                  <p>
                    Producing custom illustrations and artwork tailored to
                    digital and print media, offering versatile creative
                    solutions.
                  </p>
                </li>
                <li>
                  <p>
                    Utilizing typography and layout design to create visually
                    appealing and readable content across different mediums and
                    platforms.
                  </p>
                </li>
                <li>
                  <p>
                    Providing expert advice and guidance on design strategies,
                    aesthetics, and brand consistency to elevate your visual
                    presence.
                  </p>
                </li>
              </ul>
            </div>

            <div
              className="col-md-6 col-lg-6  popp"
              id="column1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="lmk1 coverage">
                <img
                  src="/images/design-serv.webp"
                  alt="best Designing services"
                  className="image-fluid"
                  title="Our Services"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default BestService;
