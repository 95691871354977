import axios from "axios";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedPage = () => {
  const navigate = useNavigate();

  const [adminCurrentUser, setAdminCurrentUser] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      const token = localStorage.getItem("adminToken");
      if (token) {
        try {
          const response = await axios.get(
            `https://infygain.com/api/getcurrentuser`,
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );
          setAdminCurrentUser(response.data.user);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("adminToken"); // Remove the expired token
            navigate("/admin");
          }
        }
      } else {
        navigate("/admin");
      }
    };
    fetchdata();
  }, [localStorage.getItem("adminToken")]);

  return (
    adminCurrentUser && (
      <>
        <Outlet />
      </>
    )
  );
};

export default ProtectedPage;
