import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../css/Packages.css";

function Bannerpac() {
  return (
    <>
      <div className=" head-isp-abou-pacs">
        <p className="head-isp-about">Packages</p>
        <p className="head-isp-para">
          Unlock Seamless Internet Speeds for Your Digital Lifestyle.
        </p>
      </div>
    </>
  );
}

export default Bannerpac;
