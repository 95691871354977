import {
  FaRegClock,
  FaLocationDot,
  FaCartPlus,
  FaPhone,
} from "react-icons/fa6";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  LiaFacebookF,
  LiaInstagram,
  LiaLinkedinIn,
  LiaWhatsapp,
  LiaYoutube,
  LiaTwitter,
} from "react-icons/lia";
import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import "../css/IotHeader.css";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";
import Fixedline from "./Fixedline";
import { MdOutlinePhone } from "react-icons/md";

import { Tooltip as ReactTooltip } from "react-tooltip";

function IotHeader() {
  function DropdownMenu() {
    let dropBox = document.querySelector(".iot-menu-box-drop");

    if (dropBox.classList.contains("iot-drop-show")) {
      dropBox.classList.remove("iot-drop-show");
    } else {
      dropBox.classList.add("iot-drop-show");
    }
  }
  function DropdownMenuSub(e) {
    let eve = e.target.style.display;
    if (eve === "block") {
      eve = "none";
    } else {
      eve = "block";
    }
  }

  return (
    <>
      <section>
        {/* Top head section */}
        <div className="iot-top-head  d-none d-md-block">
          <div className="row">
            <div className="d-none d-md-block col-md-6 col-lg-3">
              <p>
                <a className="iot-head-link" href="tel:+91 9952141444">
                  <MdOutlinePhone />
                  &nbsp;&nbsp;&nbsp;&nbsp; +91 99521 41444
                </a>{" "}
              </p>
            </div>
            <div className="d-none d-md-block col-md-3 col-lg-3">
              <p>
                <a className="iot-head-link" href="mailto:info@infygain.com">
                  <HiOutlineMail />
                  &nbsp;&nbsp;&nbsp;&nbsp; info@infygain.com
                </a>
              </p>
            </div>
            <div className="d-none d-lg-block col-lg-3">
              <div className="iot-headhr">
                <p>
                  <a className="iot-head-link" href="/contact">
                    <FaRegClock />
                    &nbsp;&nbsp;&nbsp;&nbsp; Office Hours: 10.00 am - 7:00 pm
                  </a>
                </p>
              </div>
            </div>
            <div className="d-none d-md-block col-md-3 col-lg-3">
              {/* <div className="iot-socialicons">
                  <a
                    className="iot-head-link iot-sm"
                    href="https://www.facebook.com/infygain/"
                    target="_blank"  rel="noopener noreferrer"
                  >
                    <LiaFacebookF />
                  </a>
                  <a
                    className="iot-head-link iot-sm"
                    href="https://www.instagram.com/infygain/"
                    target="_blank"  rel="noopener noreferrer"
                  >
                    <LiaInstagram />
                  </a>
                  <a className="iot-head-link iot-sm" href="https://twitter.com/infygain"  target="_blank"  rel="noopener noreferrer">
                    <LiaTwitter />
                  </a>
                
                  <a
                    className="iot-head-link iot-sm"
                    href="https://api.whatsapp.com/send/?phone=919952141444&text&type=phone_number&app_absent=0"
                    target="_blank"  rel="noopener noreferrer"
                  >
                    <LiaWhatsapp />
                  </a>
                  <a
                    className="iot-head-link iot-sm"
                    href="https://www.linkedin.com/company/infygain-technologies/"
                    target="_blank"  rel="noopener noreferrer"
                  >
                    <LiaLinkedinIn />
                  </a>
                  <a
                    className="iot-head-link iot-sm"
                    href="https://www.youtube.com/@infygain6707"
                    target="_blank"  rel="noopener noreferrer"
                  >
                    <LiaYoutube />
                  </a>
                </div> */}
              <div className="iot-socialicons">
                <a
                  className="iot-head-link iot-sm"
                  href="https://www.facebook.com/infygain/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <LiaFacebookF data-tooltip-id="my-tooltip-4" />
                </a>
                <ReactTooltip
                  id="my-tooltip-4"
                  place="bottom"
                  content="Facebook"
                />
                <a
                  className="iot-head-link iot-sm"
                  href="https://www.instagram.com/infygain/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <LiaInstagram data-tooltip-id="my-tooltip-5" />
                </a>
                <ReactTooltip
                  id="my-tooltip-5"
                  place="bottom"
                  content="Instagram"
                />
                <a
                  className="iot-head-link iot-sm"
                  href="https://twitter.com/infygain"
                  target="_blank"
                >
                  <LiaTwitter data-tooltip-id="my-tooltip-6" />
                </a>
                <ReactTooltip
                  id="my-tooltip-6"
                  place="bottom"
                  content="Twitter"
                />

                <a
                  className="iot-head-link iot-sm"
                  href="https://api.whatsapp.com/send/?phone=919952141444&text&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  <LiaWhatsapp data-tooltip-id="my-tooltip-7" />
                </a>
                <ReactTooltip
                  id="my-tooltip-7"
                  place="bottom"
                  content="Whatsapp"
                />
                <a
                  className="iot-head-link iot-sm"
                  href="https://www.linkedin.com/company/infygain-technologies/"
                  target="_blank"
                >
                  <LiaLinkedinIn data-tooltip-id="my-tooltip-8" />
                </a>
                <ReactTooltip
                  id="my-tooltip-8"
                  place="bottom"
                  content="LinkedinIn"
                />
                <a
                  className="iot-head-link iot-sm"
                  href="https://www.youtube.com/@infygain6707"
                  target="_blank"
                >
                  <LiaYoutube data-tooltip-id="my-tooltip-9" />
                </a>
                <ReactTooltip
                  id="my-tooltip-9"
                  place="bottom"
                  content="Youtube"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Main head section */}
        <div className="iot-main-head">
          <div className="iot-main-head-box">
            <div className="iot-logoo-box">
              <a href="/">
                <img
                  className="img-fluid1"
                  src="/images/others/logo-alt.svg"
                  alt="best it supports"
                  title="infygain logo"
                ></img>
              </a>
            </div>
            <div className="iot-menu-box">
              <li>
                <Link className="iot-mlink" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="iot-mlink" to="/about">
                  About
                </Link>
              </li>
              <li className="iot-sub">
                <Link className="iot-mlink">
                  Services <IoMdArrowDropdown />
                </Link>
                <div className="iot-submenu">
                  <li>
                    <Link className="mlink webhover" to="/Iotservice">
                      IT Supports
                      <IoMdArrowDropdown />
                    </Link>
                    <div className="iot-webmenu1">
                <li>
                  <Link className="mlink webhover" to="/services/networking">
                    Networking
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu12">
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Router">
                          Router
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Switch">
                         Switch
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Firewall">
                          Firewall
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/EndPointSecurity">
                         End Point Security
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Fiber">
                        Fiber Solution
                        </Link>
                      </li>
                    </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/cctv">
                    Security Solutions
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu13">
                  <li>
                        <Link className="mlink" to="/services/cctv">
                         CCTV solutions
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Cctv/Bio-Metric">
                         Bio-Metric
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Cctv/BoomBarrier">
                         Boom barrier
                        </Link>
                      </li>
                      </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/Projectors">
                    AV Solutions
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu14">
                      <li>
                        <Link className="mlink" to="/servicedetails/Projectors/SmartProjector">
                         Projector
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Projectors/InteractivePanel">
                         Interative Panel
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Projectors/TV">
                        Smart TV
                        </Link>
                      </li>
                      </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/laptopanddesktop">
                   Laptop/Desktop 
                   <IoMdArrowDropdown />
                  </Link>






                  <div className="iot-webmenu15">
                      <li>
                        <Link className="mlink" to="/servicedetails/Laptop/GamingLaptop">
                         Laptop Service (All Brands)
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Desktop/BrandedDesktop">
                         Desktop Service
                        </Link>
                      </li>
                  
                      </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/Pos">
                  Billing Machine
                  <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu17">
                  <li>
                  <Link className="mlink" to="/servicedetails/Pos/POS">
                  Pos Machine
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Pos/BarcodeScanner">
                 Barcode Scanner
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Pos/KIOSK">
                 Kiosk
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Pos/BillingApps">
                 Billing apps
                  </Link>
                </li>
</div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/Accessories">
                  Accessories
                  <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu16">
                  <li>
                  <Link className="mlink" to="/servicedetails/Accessories/RAM">
                  ram
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Accessories/HDD,SSD">
                 hard disk
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Accessories/MotherBoard">
                 motherboards
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="">
                SMPS
                  </Link>
                </li>
</div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/UPS">
                    Power Solutions
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu18">
                  <li>
                        <Link className="mlink" to="/services/UPS">
                         UPS
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/UPS/Inventor">
                         Inverter
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/UPS/OnlineUPS">
                         Online UPS
                        </Link>
                      </li>
                      </div>
                </li>
              </div>
                  </li>
                  <li>
                    <Link className="mlink webhover" to="/ServiceSeo">
                      Web Services
                      <IoMdArrowDropdown />
                    </Link>
                    <div className="iot-webmenu21">
                    <li>
                        <Link className="mlink" to="/webservices/Websites">
                          Website Development
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/webservices/Seo">
                          SEO Services
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/webservices/Application">
                          Web Application
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/webservices/Designing">
                          UI/UX Design
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/WebservicePortfolio">
                          Portfolio
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/Product">
                          Products
                        </Link>
                      </li>
                    </div>
                  </li>
                  <li>
                    <Link className="mlink webhover" to="/ispservice">
                      ISP Services
                      <IoMdArrowDropdown />
                    </Link>
                    <div className="iot-webmenu31">
                    <li>
                        <Link className="mlink" to="/Internet-Leased-Line">
                        Internet Leased Line
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/smepackage">
                        SME Line
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/Business-Broadband">
                        Business Broadband
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/isp-package">
                        Broadband /IPTV/Voice
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/intranet">
                        Intranet
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/vpn-solution">
                        VPN Solutions
                        </Link>
                      </li>
                      </div>
                  </li>
              
                </div>
              </li>
              <li>
                <a
                  className="mlink"
                  target="_blank"
                  href="https://infygain.com/onlinestore/"
                >
                  Products
                </a>
              </li>
              <li>
                <Link className="iot-mlink" to="/mainblogs">
                  Blogs
                </Link>
              </li>
              <li>
                <Link className="iot-mlink" to="/career">
                  Career
                </Link>
              </li>
              <li>
                <Link className="iot-mlink" to="/Partner">
                  Partner
                </Link>
              </li>
              <li>
                <Link className="iot-mlink" to="/contact">
                  Contact
                </Link>
              </li>
            </div>
            {/* <div className="contact-box">
                <div className="row align-items-center">
                  <div className="col-md-2 query">
                    <a className="head-link" href="#">
                      <FaHeadset />
                    </a>
                  </div>
                  <div className="col-md-10 q-cont">
                    <p>
                      Have Any Questions?<br></br>
                      <span className="">+91 &nbsp;99342567810</span>
                    </p>
                  </div>
                </div>
              </div> */}
            <div className="iot-enquiry-box">
              <a className="iot-head-link" href="/contact">
                <button className="iot-btn iot-btnss">
                  Get a quote &nbsp;&nbsp;<i className="fa fa-arrow-right"></i>
                </button>
              </a>
              <a
                className="iot-head-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://infygain.com/onlinestore/"
              >
                <FaCartPlus />
              </a>
              {/* <a className="iot-head-link" target="_blank" href="https://grayquest.com/institute/united-master">
                < FaCcAmazonPay/>
              </a> */}
            </div>

            <div className="iot-dropDownMenu">
              <HiMenuAlt1 role="button" onClick={() => DropdownMenu()} />
            </div>
          </div>
        </div>
      </section>

      <div className="iot-menu-box-drop">
        <li>
          <Link className="iot-mlink" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link className="iot-mlink" to="/about">
            About
          </Link>
        </li>
        <li className="iot-sub">
          <Link className="iot-mlink " onClick={DropdownMenuSub}>
            Services <IoMdArrowDropdown />
          </Link>
          <div className="iot-submenu">
          
          <li>
                    <Link className="mlink webhover" to="/Iotservice">
                      IT Supports
                      <IoMdArrowDropdown />
                    </Link>
                    <div className="iot-webmenu1">
                <li>
                  <Link className="mlink webhover" to="/services/networking">
                    Networking
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu12">
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Router">
                          Router
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Switch">
                         Switch
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Firewall">
                          Firewall
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/EndPointSecurity">
                         End Point Security
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Networking/Fiber">
                        Fiber Solution
                        </Link>
                      </li>
                    </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/cctv">
                    Security Solutions
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu13">
                  <li>
                        <Link className="mlink" to="/services/cctv">
                         CCTV solutions
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Cctv/Bio-Metric">
                         Bio-Metric
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Cctv/BoomBarrier">
                         Boom barrier
                        </Link>
                      </li>
                      </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/Projectors">
                    AV Solutions
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu14">
                      <li>
                        <Link className="mlink" to="/servicedetails/Projectors/SmartProjector">
                         Projector
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Projectors/InteractivePanel">
                         Interative Panel
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Projectors/TV">
                        Smart TV
                        </Link>
                      </li>
                      </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/laptopanddesktop">
                   Laptop/Desktop 
                   <IoMdArrowDropdown />
                  </Link>






                  <div className="iot-webmenu15">
                      <li>
                        <Link className="mlink" to="/servicedetails/Laptop/GamingLaptop">
                         Laptop Service (All Brands)
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/Desktop/BrandedDesktop">
                         Desktop Service
                        </Link>
                      </li>
                  
                      </div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/Pos">
                  Billing Machine
                  <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu17">
                  <li>
                  <Link className="mlink" to="/servicedetails/Pos/POS">
                  Pos Machine
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Pos/BarcodeScanner">
                 Barcode Scanner
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Pos/KIOSK">
                 Kiosk
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Pos/BillingApps">
                 Billing apps
                  </Link>
                </li>
</div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/Accessories">
                  Accessories
                  <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu16">
                  <li>
                  <Link className="mlink" to="/servicedetails/Accessories/RAM">
                  ram
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Accessories/HDD,SSD">
                 hard disk
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Accessories/MotherBoard">
                 motherboards
                  </Link>
                </li>
                <li>
                  <Link className="mlink" to="/servicedetails/Accessories/SMPS">
                SMPS
                  </Link>
                </li>
</div>
                </li>
                <li>
                  <Link className="mlink webhover" to="/services/UPS">
                    Power Solutions
                    <IoMdArrowDropdown />
                  </Link>
                  <div className="iot-webmenu18">
                  <li>
                        <Link className="mlink" to="/services/UPS">
                         UPS
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/UPS/Inventor">
                         Inverter
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/servicedetails/UPS/OnlineUPS">
                         Online UPS
                        </Link>
                      </li>
                      </div>
                </li>
              </div>
                  </li>
                  <li>
                    <Link className="mlink webhover" to="/ServiceSeo">
                      Web Services
                      <IoMdArrowDropdown />
                    </Link>
                    <div className="iot-webmenu21">
                    <li>
                        <Link className="mlink" to="/webservices/Websites">
                          Website Development
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/webservices/Seo">
                          SEO Services
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/webservices/Application">
                          Web Application
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/webservices/Designing">
                          UI/UX Design
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/WebservicePortfolio">
                          Portfolio
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/Product">
                          Products
                        </Link>
                      </li>
                    </div>
                  </li>
                  <li>
                    <Link className="mlink webhover" to="/ispservice">
                      ISP Services
                      <IoMdArrowDropdown />
                    </Link>
                    <div className="iot-webmenu31">
                    <li>
                        <Link className="mlink" to="/Internet-Leased-Line">
                        Internet Leased Line
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/smepackage">
                        SME Line
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/Business-Broadband">
                        Business Broadband
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/isp-package">
                        Broadband /IPTV/Voice
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/intranet">
                        Intranet
                        </Link>
                      </li>
                      <li>
                        <Link className="mlink" to="/vpn-solution">
                        VPN Solutions
                        </Link>
                      </li>
                      </div>
                  </li>
           
            {/* <li>
              <Link className="mlink" to="">
                Cloud Services
              </Link>
            </li>
            <li>
              <Link className="mlink" to="">
                Professional Services
              </Link>
            </li> */}
          </div>
        </li>
        <li>
          <a
            className="mlink"
            target="_blank"
            href="https://infygain.com/onlinestore/"
          >
            Products
          </a>
        </li>
        <li>
          <Link className="iot-mlink" to="/career">
            Career
          </Link>
        </li>
        <li>
          <Link className="iot-mlink" to="/Partner">
            Partner
          </Link>
        </li>
        <li>
          <Link className="iot-mlink" to="/blogs">
            Blog
          </Link>
        </li>
        <li>
          <Link className="iot-mlink" to="/contact">
            Contact
          </Link>
        </li>
        <Fixedline />
      </div>
    </>
  );
}

export default IotHeader;
