import "../css/mainpackage.css";
import React from "react";
import { Link } from "react-router-dom";

function SMEpackage() {
  return (
    <>
      <div className="container lolp mt-5 mb-5">
        <div className="hkl">
          <h2 className="bril mb-5 int-isp1">SME Internet</h2>
        </div>
        <div className="row">
          <div
            className="col-md-4 col-lg-4  popp"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="lmk sme-img1">
              <img
                src="/images/sme1.webp"
                alt="SME Internet"
                className="image-fluid"
                title="SME Internet"
              />
            </div>
          </div>
          <div
            className="col-md-8 col-lg-8 semt-1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              Welcome to our <b>SME Internet service</b>, your gateway to seamless and
              reliable connectivity ensuring your business remains operational
              24/7.
            </p>
            <p className="text-muted">
              Welcome to our <b>SME Internet service</b>, your gateway to seamless and
              reliable connectivity ensuring your business remains operational
              24/7. Our service guarantees high-speed, dedicated Internet
              connectivity, keeping your SME connected at all times. With a
              commitment to 1:1 symmetric bandwidth, you'll experience
              consistent upload and download speeds, optimizing your online
              performance. Moreover, we are IPv6 ready, ensuring future-proof
              compatibility with evolving technologies. At the infrastructure
              level, we provide dual upstream parenting at the POP level and
              dual POP parenting at the last mile, offering redundancy and
              reliability to minimize downtime and optimize network performance.
              To further support your connectivity needs, each SME client
              receives the dedicated assistance of a Technical Assurance
              Manager, ensuring prompt issue resolution and personalized
              support.
              <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-7 col-lg-7 wifit1"
            id="column2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              "Unleash your business potential with our SME Internet services -
              the key to seamless connectivity and unmatched performance."
            </p>
            <p className="text-muted ">
              Elevate your business presence with our <b>SME Internet solutions</b>
              meticulously designed to meet the needs of modern enterprises.
              With our robust infrastructure and steadfast commitment to
              reliability, we deliver dedicated internet connectivity tailored
              to enhance your operations with consistent speeds and
              uninterrupted access. Bid farewell to bandwidth bottlenecks and
              latency issues as our <b>SME Internet services</b> offer symmetric speeds
              and minimal latency, ensuring optimal performance for critical
              applications. Supported by our proactive monitoring and responsive
              support team, we assure maximum uptime and swift resolution of any
              connectivity issues, empowering you to focus on propelling your
              business forward. Experience the power of dependable connectivity
              with our SME Internet services and unlock new opportunities in the
              digital realm.
              <br />
            </p>
          </div>
          <div
            className="col-md-5 col-lg-5 olk"
            id="column1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="lmk2">
              <img
                src="/images/bb1.webp"
                alt="SME Internet"
                className="image-fluid"
                title="SME Internet"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ispmainservice">
        <h2 className="text-center int-isp1">PACKAGES</h2>
        <div className="container services">
          <div className="row Boxss">
            <div
              className="servicebox col-lg-4"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="serviceContent-thiban">
                <div className="des">
                  <img
                    className=""
                    src="../images/banner/Group 1 copy.jpg"
                    alt="High-Speed Internet Provider"
                    title="Basic package"
                  ></img>
                </div>
                <p className="midss-title">BASIC PACKAGE</p>
                <p className="midess-title">10 Mbps</p>
                <p className="text-muted  midesss-title">Speed Up to 10 Mbps</p>
                <hr className="smallserline"></hr>

                {/* <p className="text-muted midesss-title">Unlimited Data</p><hr className='smallserline'></hr> */}

                <p className="text-muted midesss-title">100% Uptime</p>
                <hr className="smallserline"></hr>

                <p className="text-muted midesss-title">Public IP</p>
                <hr className="smallserline"></hr>

                <p className="text-muted midesss-title">
                  24/7 customer support
                </p>

                <p className="pac-amount">
                  {" "}
                  Rs 3500/-<br></br>
                  <p className="par-package">/Monthly</p>
                </p>

                <div className="buttons">
                  <button className="btns-greenbox">
                    {" "}
                    <Link className="mlink" to="/contact">
                      subscribe now{" "}
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="servicebox col-lg-4"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="serviceContent-thiban">
                <div className="des">
                  <img
                    className="img-fluid"
                    src="../images/banner/Group 2 copy.jpg"
                    alt="ISP Solutions"
                    title="Premium package"
                  ></img>
                </div>

                <p className="midss-title">PREMIUM PACKAGE</p>
                <p className="midess-title">50 Mbps</p>
                <p className="text-muted  midesss-title">Speed Up to 50 Mbps</p>
                <hr className="smallserline"></hr>

                {/* <p className="text-muted midesss-title">Unlimited Data </p><hr className='smallserline'></hr> */}

                <p className="text-muted midesss-title">100% Uptime</p>
                <hr className="smallserline"></hr>

                <p className="text-muted midesss-title">Public IP</p>
                <hr className="smallserline"></hr>

                <p className="text-muted midesss-title">
                  24/7 customer support
                </p>

                <p className="pac-amount">
                  {" "}
                  Rs 9500/-<br></br>
                  <p className="par-package">/Monthly</p>
                </p>

                <div className="buttons">
                  <button className="btns-greenbox">
                    {" "}
                    <Link className="mlink" to="/contact">
                      subscribe now{" "}
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="servicebox col-lg-4"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="serviceContent-thiban">
                <div className="des">
                  <img
                    className="img-fluid"
                    src="../images/banner/Group 3 copy.jpg"
                    alt="Business Internet Solutions"
                    title="Hype package"
                  ></img>
                </div>
                <p className="midss-title">HYPE PACKAGE</p>
                <p className="midess-title">100 Mbps</p>
                <p className="text-muted  midesss-title">
                  Speed Up to 100 Mbps
                </p>
                <hr className="smallserline"></hr>

                {/* <p className="text-muted midesss-title">Unlimited Data </p><hr className='smallserline'></hr> */}

                <p className="text-muted midesss-title">100% Uptime</p>
                <hr className="smallserline"></hr>

                <p className="text-muted midesss-title">Public IP</p>
                <hr className="smallserline"></hr>

                <p className="text-muted midesss-title">
                  24/7 customer support
                </p>
                <p className="pac-amount">
                  {" "}
                  Rs 15000/-<br></br>
                  <p className="par-package">/Monthly</p>
                </p>

                <div className="buttons">
                  <button className="btns-greenbox">
                    <Link className="mlink" to="/contact">
                      subscribe now
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SMEpackage;
