import "./css/product.css";
import Header from "./common/header";
import Footer from "./common/footer";

const Product = () => {
  return (
    <>
      <Header />
      <div class="p_banner">
        <p class="p_heading">Our Products</p>
      </div>

      <div class="title_container">
        <p class="p_tittle"> Web Application</p>
        <p class="quotes">
          {" "}
          Web applications are where creativity meets functionality {" "}
        </p>
      </div>

      <div class="portfolio_boxx">
        <div class="img_boxx">
          <img src="./images/portfolio/ecommerce-ebilling.webp" alt="web services" title=""></img>
          <div class="p_content_box">
            <p>Ecommerce & E-Billing Website</p>
          </div>
        </div>
        <div class="img_boxx">
          <img src="./images/portfolio/hotel_management.webp" alt="web services" title=""></img>
          <div class="p_content_box">
            <p>Hotel Management</p>
          </div>
        </div>
        <div class="img_boxx">
          <img src="./images/portfolio/tailor_management.webp" alt="web services" title=""></img>
          <div class="p_content_box">
            <p>Tailor Management </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
