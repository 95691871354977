import React from "react";
import PartnerBanner from "../partner/PartnerBanner";
import Header from "../common/header";
import Footer from "../common/footer";
import "../css/Partner.css";
import PartnerBlog from "../partner/PartnerBlog";
import { Helmet } from "react-helmet";

// Get the current URL using window.location.href
const currentUrl = window.location.href;

let metaContent = {
  des: "Explore our strategic partnerships for business success. Join forces with a reliable partner committed to excellence. Unlock growth opportunities together.",
  key: "Strategic partnerships, business collaboration, reliable partners, growth opportunities, partnership success, collaborative excellence",
  ogt: "Best IT support and web development company in Coimbatore",
  og: "Explore our strategic partnerships for business success. Join forces with a reliable partner committed to excellence. Unlock growth opportunities together.",
  logo: "https://infygain.com/images/icons/infy-logo.svg",
};
function Partner() {
  return (
    <div className="responsive">
    
      <Header />
      <PartnerBanner />
      <PartnerBlog />
      <Footer />
    </div>
  );
}

export default Partner;
