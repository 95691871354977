import "font-awesome/css/font-awesome.css";
import "../css/ispservice.css";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <>
      <div className=" ispservice ">
        <div className=" topbannersection">
          <div className="container ">
            <div className="row">
              <div
                className="col-md-7"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className=" contentsection">
                  <h1 className="maintitle">“Your network our future”.</h1>
                  <div className="smalline"></div>
                  <p
                    className="bannercontent"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <a className="isp-intern-link" href="/Internet-Leased-Line" ><b>Best Internet service provider (ISP)</b></a> we provide access to
                    the internet to both personal and business customers. ISPs
                    make it possible for their customers to surf the web, shop
                    online, conduct business, and connect with family and
                    friends.
                  </p>
                </div>
                <div
                  className="lists"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <ul className="listlines">
                    <li>
                      <i className="fa fa-thin fa-check fa-2xs isp-banner-points"></i>{" "}
                      Dedicated 24/7 customer care
                    </li>
                    <li>
                      <i className="fa fa-thin fa-check isp-banner-points"></i>{" "}
                      Unlimited Data usage
                    </li>
                    <li>
                      <i className="fa fa-thin fa-check isp-banner-points"></i>{" "}
                      Plan Starts from Rs.349
                    </li>
                  </ul>
                </div>
                <Link to="/isp-package">
                  <div
                    className="button"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <button className="btns-green">see plan & deals</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
