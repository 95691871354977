import { Link } from "react-router-dom";
import "../css/mainpackage.css";
import React from "react";

function SMElles() {
  return (
    <>
      {/* <div className="container">
        <p className="data-pack-main">
          Our Internet Leased Line service provides high-speed, dedicated
          Internet connectivity to ensure your business stays connected 24/7
        </p>
        <li className="data-pack-main-con">
          Throughput Commitment:-1:1 symetric Bandwidth
        </li>
        <li className="data-pack-main-con">IPV6 Ready</li>
        <li className="data-pack-main-con">
          POP Level Dual Upstream Parenting
        </li>
        <li className="data-pack-main-con">
          Last Mile Level Dual POP Parenting
        </li>
        <li className="data-pack-main-con">
          Dedicated Technical Assurance Manager
        </li>
        <li className="data-pack-main-con">
          Self Help Portal And Billing, Usage Graph and Uptime information
        </li>
      </div> */}

      <div className="container lolp mt-5 mb-5">
        <div className="hkl">
          <h2 className="bril mb-5 int-isp1"> Internet Leased Line </h2>
        </div>
        <div className="row">
          <div
            className="col-md-4 col-lg-4  popp"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="lmk">
              <img
                src="/images/internet-ll1.webp"
                alt="Internet Leased Line"
                className="image-fluid"
                title="Internet Leased Line"
              />
            </div>
          </div>
          <div
            className="col-md-8 col-lg-8   wifit"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              Welcome to our <b>Internet Leased Line service</b>, your gateway to
              seamless and reliable connectivity ensuring your business remains
              operational 24/7.
            </p>
            <p className="text-muted">
              Our service guarantees high-speed, dedicated <b>Internet </b>
              connectivity, keeping you connected at all times. With a
              commitment to 1:1 symmetric bandwidth, you'll experience
              consistent upload and download speeds, optimizing your online
              performance. Moreover, we are IPv6 ready, ensuring future-proof
              compatibility with evolving technologies. At the infrastructure
              level, we provide dual upstream parenting at the POP level and
              dual POP parenting at the last mile, offering redundancy and
              reliability to minimize downtime and optimize network performance.
              To further support your connectivity needs, each client receives
              the dedicated assistance of a Technical Assurance Manager,
              ensuring prompt issue resolution and personalized support
              <br />
            </p>

          </div>
        </div>

        <div className="row">
          <div
            className="col-md-7 col-lg-7 wifit1"
            id="column2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
                        <p className="text-muted mt-2">
              "Catalyze your business potential with our <b>Internet Leased Line
              services</b> – your dedicated gateway to seamless connectivity and
              unparalleled performance."
            </p>
            <p className="text-muted ">
              Elevate your online presence with our <a className="pos-text"href="/contact"><b>Internet Leased Line</b></a>
              solutions meticulously crafted to meet the demands of modern
              businesses. With our robust infrastructure and unwavering
              commitment to reliability, we deliver a dedicated internet
              connection tailored to fuel your operations with consistent speeds
              and uninterrupted access. Say goodbye to bandwidth bottlenecks and
              latency issues as our <b>Internet Leased Lines</b> offer symmetric speeds
              and low latency, ensuring optimal performance for mission-critical
              applications. Backed by our proactive monitoring and responsive
              support team, we guarantee maximum uptime and swift resolution of
              any connectivity concerns, allowing you to focus on driving your
              business forward. Experience the power of reliable connectivity
              with our <b><a className="pos-text"href="/contact">Internet Leased Line services</a></b> and unlock new
              possibilities in the digital landscape
              <br />
            </p>
          </div>
          <div
            className="col-md-5 col-lg-5 olk"
            id="column1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            
            <div className="lmk2">
              <img
                src="/images/internet-ll2.webp"
                alt="Internet Leased Line"
                className="image-fluid"
                title="Internet Leased Line"
              />
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default SMElles;
