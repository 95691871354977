import Header from './common/header';
import Contactbanner from './contact/contactbanner';
import Contactform from './contact/contactform';
import Maincontent from './contact/maincontent';
import Footer from './common/footer';
import { Helmet } from 'react-helmet';

// Get the current URL using window.location.href
const currentUrl = window.location.href;
const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",

  "name": "Infygain Technologies",
  "legalName": "Computer Service",
  "email": "info@infygain.com",
  "url": "https://infygain.com/",
  "logo": "https://infygain.com/images/logo.svg",
  "sameAs": [
    "https://twitter.com/infygain",
    "https://www.instagram.com/infygain/",
    "https://www.facebook.com/infygain/",
    "https://www.linkedin.com/company/infygain-technologies/",
    "https://www.youtube.com/@infygain6707",
    "https://infygain.com/"
  ],
  "address": {
    "addressLocality": "prozone mall",
    "postalCode": "641035",
    "streetAddress": "Hari complex opp.prozone mall, Sathy Rd, Saravanampatti, Coimbatore, Tamil Nadu "
  }
};

const breadcrumbSchema = {
  "@context": "https://schema.org/",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://infygain.com/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "About - Us",
      "item": "https://infygain.com/about"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "IT Supports",
      "item": "https://infygain.com/Iotservice"
    },
    {
      "@type": "ListItem",
      "position": 4,
      "name": "ISP Services",
      "item": "https://infygain.com/ispservice"
    },
    {
      "@type": "ListItem",
      "position": 5,
      "name": "WEB Services",
      "item": "https://infygain.com/ServiceSeo"
    },
    {
      "@type": "ListItem",
      "position": 6,
      "name": "Blogs",
      "item": "https://infygain.com/mainblogs"
    },
    {
      "@type": "ListItem",
      "position": 7,
      "name": "Career",
      "item": "https://infygain.com/career"
    },
    {
      "@type": "ListItem",
      "position": 8,
      "name": "Partner",
      "item": "https://infygain.com/Partner"
    },
    {
      "@type": "ListItem",
      "position": 9,
      "name": "Contact",
      "item": "https://infygain.com/contact"
    },
    {
      "@type": "ListItem",
      "position": 10,
      "name": "Online Store",
      "item": "https://infygain.com/onlinestore/"
    }
  ]
};

const localBusinessSchema = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Infygain Technologies",
  "image": "https://infygain.com/images/logo.svg",
  "@id": "",
  "url": "https://infygain.com",
  "telephone": "9952141444",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "hari complex opp.prozone mall, Sathy Rd, Saravanampatti",
    "addressLocality": "Coimbatore",
    "postalCode": "641035",
    "addressCountry": "IN"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 11.0549976,
    "longitude": 76.9951292
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "opens": "09:45",
    "closes": "19:00"
  }
};


const websiteSchema = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Infygain Technologies",
  "url": "https://infygain.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://infygain.com/{search_term_string}",
    "query-input": "required name=search_term_string"
  }
};


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://infygain.com/"
  },
  "headline": "Computer Service in coimbatore,Best IT support and web development company in Coimbatore",
  "description": "We are a prominent IT service provider, based in Coimbatore, with a wide range of products and services, to meet the exclusive needs of our customers. We offer end-to-end solutions to enable a reliable and uninterrupted workflow for our customers.",
  "image": "https://infygain.com/images/others/services.webp",
  "author": {
    "@type": "Organization",
    "name": ""
  },
  "publisher": {
    "@type": "Organization",
    "name": "Infygain Technologies",
    "logo": {
      "@type": "ImageObject",
      "url": "https://infygain.com/images/logo.svg"
    }
  },
  "datePublished": "2024-01-12"
};
const videoSchema = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Infygain Technologies",
  "description": "INFYGAIN - Leading Computer Service at Doorstep in Coimbatore",
  "thumbnailUrl": "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
  "uploadDate": "2024-01-12T12:00:00Z",
  "duration": "PT1M1M",  // Replace with the actual duration of your video
  "contentUrl": "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
  "embedUrl": "https://www.youtube.com/embed/CdP9VCScVmQ",
  "publisher": {
    "@type": "Organization",
    "name": "Infygain Technologies",
    "logo": {
      "@type": "ImageObject",
      "url": "URL to your organization's logo"
    }
  }
};
let metaContent = {
  des: "best IT services in coimbatore and also we best in networking, cctv camera,server, billing software, web, graphic design, amc",
  key: "computer,laptop,cctv,printer,AMC,networking,website,graphics,isp",
  ogt: "IT Services in Coimbatore and web development company in Coimbatore",
  og: "Best IT services in coimbatore and also we best in networking, cctv camera,server, billing software, web, graphic design, amcbest IT services in coimbatore and also we best in networking, cctv camera,server, billing software, web, graphic design, amc",
  logo: "https://infygain.com/images/icons/infy-logo.svg"
}
function Contactus() {
  return (
    <>
               <Helmet>
                {/* <title></title> */}
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>{metaContent.ogt}</title>
                <link rel="canonical" href={currentUrl} />

                <link rel="icon" href={"favicon.ico"} />
                <meta name="description" content={metaContent.des} />
                <meta name="keywords" content={metaContent.key} />
                
                <meta name="google-site-verification" content="ocR-tZ4yt2LD1ePYD1bR4LK6GYUc0keDJcspEtblHoM" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={metaContent.ogt} />
                <meta property="og:description" content={metaContent.og} />
                <meta property="og:image" content={metaContent.logo} />
                <meta property="og:url" content="https://infygain.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Infygain Technologies" />
                {/*  END FB Open Graph */}

                {/* Twitter Card Meta Tags */}

                <meta name="twitter:card" content="Best computer/laptop sales and service in coimbatore" />
                <meta name="twitter:site" content="Infygain Technologies" />
                <meta name="twitter:title" content="Infygain Technologies" />
                <meta name="twitter:card" content="summary" />

                <meta name="twitter:description" content="We are provide the best computer and laptop, networking service in coimbatore and also we best in cctv camera, server, billing software, graphic design, amc." />
                <meta name="twitter:image" content={metaContent.logo} />
                {/* Robot tag */}
                <meta name="robots" content="index, follow" />
                
                {/* End Robot tag */}

                {/* Facebook Pixel Code */}
            <script type="text/javascript">
               {`
               !function(f,b,e,v,n,t,s)
               {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
               n.callMethod.apply(n,arguments):n.queue.push(arguments)};
               if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
               n.queue=[];t=b.createElement(e);t.async=!0;
               t.src=v;s=b.getElementsByTagName(e)[0];
               s.parentNode.insertBefore(t,s)}(window, document,'script',
               'https://connect.facebook.net/en_US/fbevents.js');
               fbq('init', '1093378958018811');
               fbq('track', 'PageView');
               `}
            </script>
            
            <noscript>{`
               <img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=1093378958018811&ev=PageView&noscript=1"
               />
               `}
            </noscript>
            {/* End Facebook Pixel Code */}
            
                
                {/* Organization schema*/}
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>

        {/* Breadcrumb schema*/}
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>


        {/* Local business 31/5/23 schema */}

        <script type="application/ld+json">
          {JSON.stringify(localBusinessSchema)}
        </script>

        {/* Website Schema */}
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
        {/*Article Schema */}
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
        {/*Video Schema*/}
        <script type="application/ld+json">
          {JSON.stringify(videoSchema)}
        </script>



                {/* Google Analytics */}
                <script>
                    {`
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)),
      (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", "UA-XXXXX-Y", "auto");
  ga("send", "pageview");
`}
                </script>
                {/* End Google Analytics */}

                {/* Google Tag Manager */}
                <script>
                    {`
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NRCN7RF");
`}
                </script>
                {/* End Google Tag Manager */}

                {/* Google tag (gtag.js) */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-89FZ8YHYVL"></script>

                <script>
                    {`
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("gtag("js", new Date());
          gtag("config", "G-89FZ8YHYVL");
        `}
                </script>
                {/* End Google tag (gtag.js) */}

                {/* Google optimizer */}
                <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NWGHP4M"></script>
                {/* End Google optimizer */}
            </Helmet>
      <Header />
      <Contactbanner />
      <Maincontent />
      <Contactform />
      <Footer />
    </>
  );
}

export default Contactus;