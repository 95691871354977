import React, { useEffect, useState } from "react";
import "../css/BestService.css";
import { useParams } from "react-router-dom";

function ServiceBanner() {
  const [servName, setServName] = useState("Services")
  const {cat,id} = useParams()

  useEffect(() => {
    const serviceNames = {
      Networking: {
        HUB: "Hub Solution Provider",
        Switch: "Network Switch Dealers in coimbatore",
        Router: "Wi-Fi Router Solution in coimbatore",
        Firewall: "Firewall Hardware Solutions",
        EndPointSecurity: "End Point Security Solution",
        Fiber: "Fiber Solution Provider- Jio",
        "SD-WAN": "SD-WAN Router Solution",
      },
      Cctv: {
        Analog: "Analog CCTV Camera ",
        IP: "IP CCTV Camera",
        DVR: "DVR Security Camera",
        NVR: "NVR CCTV Camera",
        360:"360 Degree CCTV Camera",
        PTZ: "PTZ CCTV Camera",
        "Bio-Metric": "Biometric Security System",
        BoomBarrier :"Boom-Barrier",
        AccessControl :"Access Control",
      },
      Server: {
        ServerInstallation: "Server Installation Service",
        ServerMaintenance:"Server Maintenance Service",
        WebServer:"Web Server",
        StorageServer:"Storage Server",
      },
      Cloud: {
        CloudHosting: "Cloud Hosting",
        "DataBackup&Recovery":"Data Backup & Recovery",
        CloudSecurity:"Cloud Security",
        "SaaS(SoftwareasaService)":"Saas(Software as a Service)",
        CloudMigrationServices:"Cloud Migration Services"
      },
      Pos:{
          POS:"Pos Machine Dealers in Coimbatore",
          KIOSK:"Kiosk Machine Dealer",
          BillingApps:"Billing Apps",
          BarcodeScanner:"Barcode Scanner Dealer",
          TableTopBarcodeScanner:"Table Top Barcode Scanner",
      },
      Printer:{
        Laser:"Laser Printer Sales & Service",
        Thermal:"Thermal Printer Sales & Service",
        Color:"Color Printer Sales &Service",
        MultiFunction:"Multifunction Printer Sales & Service",
        DotMatrix:"Dot Matrix printer Sales & Service",
      },
      Scanner:{
        Flatbed:"Flatbed Scanner",
        Vertical :"Vertical Scanner"
      },
      Accessories: {
        RAM: "RAM Solution",
        "HDD,SSD": " HDD,SSD Solution",
        KeyBoard: "KeyBoard Solution",
        "Mouse,Moniter": "Mouse,Moniter Solution",
        Cabinet:"Cabinet Solution",
        MotherBoard:"MotherBoard",
        SMPS:"SMPS",
"Cables,Charger":"Cables and Chargers",
"Connector,Convertor":"Connector and Converter"
      },
      Laptop:{
GamingLaptop:"Gaming Laptop Service",
Consumerlaptops:"Consumer Laptops",
WorkStation:"Workstation",
      },
      Desktop:{
        BrandedDesktop:"Branded Desktop Dealers",
        AssembledDesktop:"Assemble Desktop Dealer",
        WorkStationDesktop:"Work Station Desktop",
        GamingDesktop:"Gaming Desktop Sales & Service",
      },
      Projectors:{
LCD:"LCD Projector ",
LED:"LED Projector",
SmartProjector:"Smart Projector",
TV:"TV Solution",
InteractivePanel:"Interactive Panel",
      },
    UPS:{
      Inventor:"UPS Inventor",
      OnlineUPS:"Online UPS",
      },
      InterCom:{
        EPABX:"EPABX Intercom Service",
        IPPBX:"IPPBX Intercom Service",
      },
      WIFI:{
        InstantAccessPoint:"Instant Access Point", 
        AccessPointController:"Access Point Controller",
        WifiRouter:"Wifi Router Solution",
        PointtoPoint:"Point to Point",
      },
      default: {
        networking: "Networking Service in Coimbatore",
        laptopanddesktop: "Laptop & Computer Service in coimbatore",
        cctv: "Best CCTV Camera Dealers in coimbatore",
        Projectors: "Best Projector Service in Coimbatore",
        Accessories: "Computer Accessories Shop in Coimbatore",
        "Printer&Scanner": "Scanner & Printer Service in Coimbatore",
        UPS: "UPS Service provider in Coimbatore",
        WIFI: "WIFI Service provider in coimbatore",
        Pos: "POS Machine Dealers in Coimbatore",
        InterCom: "InterCom Services in Coimbatore",
        Server: "Server Service provider",
        Cloud:"Cloud Service Provider in Coimbatore",
      }

    };
  
    const serviceName = (serviceNames[cat] && serviceNames[cat][id]) || serviceNames.default[id] || "Services";
    setServName(serviceName);
  }, [cat, id]);
  
  return (
    <div className="fullparaview ">
      <h1 className="mainvaluepreview">{servName}</h1>
    </div>
  );
}
export default ServiceBanner;