import React from "react";
import { FaCartPlus } from "react-icons/fa";
import { Modal } from "antd";
import { useState } from "react";
import { RiContactsFill } from "react-icons/ri";
import axios from "axios";
import Alert from "react-bootstrap/Alert";

const ProductContent = ({ value }) => {
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);

  };
  const handleOk = () => {
   
  
  };
  const showModal = () => {
    console.log("hii");
    setOpen(true);
  };
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    category: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const handleInput = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: [e.target.value],
    }));
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("https://infygain.com/api/contact", values)
      .then((res) => {
        if (res.data.info) {
          setValues({
            name: "",
            phone: "",
            email: "",
            category: "",
            subject: "",
            message: "",
          });
          document.querySelector(".form").reset();
          setErrors(res.data.info);
          setShowMsg(true);
          setLoading(false);
          setOpen(false);
        } else {
          setErrors(res.data.err);
          setShow(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function alertBox() {
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }
  function msgBox() {
    if (showMsg) {
      return (
        <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }
  return (
   
    <>
     <div id="column1" className="col-md-8" key={value.name}>
      <div className="firstlineflex">
        <div className="yellowbreak"></div>
        <div className="firstheading">
          <h1>{value.key}</h1>
        </div>
      </div>
      <div className="row paraoutdoor">
        <div className="col-md-4 col-sm-12">
          <img src={value.img} alt={value.name} title={value.name} />
        </div>

        <div className="col-md-8 col-sm-12">
          <p className="text-muted firstparavalue">
            {/* {value.para} */}
          <p  className="text-muted firstparavalue"dangerouslySetInnerHTML={{__html:value.para}}></p>
            <div className="butk">
              {/* To Check Our product  */}
              <a target="_blank" href={value.link} className="me-3">
                <button className="btn butks">
                  <FaCartPlus className="butk-icons" /> buy now
                </button>
                
              </a>
              <a target="_blank"  onClick={showModal} >
                <button className="btn butks" >
                  <RiContactsFill className="butk-icons" /> Get a quote
                </button>
                
              </a>
            </div>
            <br></br>
            {alertBox()}
            {msgBox()}
          </p>
        </div>
      </div>

      <div className="downsession">
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
            <p dangerouslySetInnerHTML={{__html:value.p1}}></p>
          </span>
        </div>
        {/* 2 point */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p2}}></p>
          </span>
        </div>
        {/* 3 point */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p3}}></p>
          </span>
        </div>
        {/*  4 points */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p4}}></p>
          </span>
        </div>
        {/* 5 points */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p5}}></p>
          </span>
        </div>
        {/*  6 points  */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p6}}></p>
          </span>
        </div>
        {/* 7 points */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p7}}></p>
          </span>
        </div>
        {/*  8 points  */}
        <div className="downparasession">
          <img
            src="/images/download.svg"
            alt={`${value.name} services`}
            className="image-fluid iconroller"
            title={`${value.name} services`}
          />
          <span className="clinicpara">
          <p dangerouslySetInnerHTML={{__html:value.p8}}></p>
          </span>
        </div>
      </div>
    </div>{/* form modal */}
    
    <Modal
      footer={null}
      open={open}
      onOk={() => {
        handleOk();
      }}
      onCancel={handleCancel}
      centered
    >
   <div className="main container">
        <div className="row">
          <div className="col-md-12 form-details">
  <h2 className="">Get a Quote</h2>
   
           
            <form className="form" method="POST" onSubmit={handleSubmit}>
              <div className="row box">
                <div className="col-md-12 form-group">
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Your name *"
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    name="phone"
                    type="phone"
                    className="form-control"
                    placeholder="Phone *"
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email *"
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="category" hidden>
                    Requirement Category
                  </label>
                  <select
                    id="category"
                    className="form-control"
                    name="category"
                    required
                    onChange={handleInput}
                  >
                    <option className="text-muted" value="nil">
                      Select a Requirement Category
                    </option>
                    <optgroup label="IT Services">
                      <option value="Hardware Issues">Hardware Issues</option>
                      <option value="Software Problems">
                        Software Problems
                      </option>
                      <option value="Network Troubleshooting">
                        Network Troubleshooting
                      </option>
                    </optgroup>
                    <optgroup label="ISP Services">
                      <option value="Connection Problems">
                        Connection Problems
                      </option>
                      <option value="Slow Internet Speed">
                        Slow Internet Speed
                      </option>
                    </optgroup>
                    <optgroup label="Web Services">
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="Web Hosting">Web Hosting</option>
                      <option value="Domain Registration">
                        Domain Registration
                      </option>
                    </optgroup>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    name="subject"
                    type="textarea"
                    className="form-control servi-sub-high"
                    placeholder="Subject"
                    required
                    onChange={handleInput}
                  />
                </div>
                <div className=" form-group">
                  <input
                    name="message"
                    type="textarea"
                    className="form-control"
                    placeholder="Your Message"
                    required
                    onChange={handleInput}
                  />
                </div>
              </div>
              <button className="btns send" disabled={loading}>
                Send Message &nbsp;<span className="fa fa-arrow-right"></span>
              </button>
            </form>
          </div>
       
        </div>
      </div>
    </Modal></>
  );
};

export default ProductContent;
