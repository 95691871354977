import React from "react";
import { useParams } from "react-router-dom";
import IotHeader from "./IotHeader";
import ServiceBanner from "../servicepage/ServiceBanner";
import Enquiry from "../common/enquiry";
import IotFooter from "./IotFooter";
import Four from "../404";
import Category from "../servicepage/Category";
import { Helmet } from "react-helmet";

// Get the current URL using window.location.href
const currentUrl = window.location.href;
const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",

  "name": "Infygain Technologies",
  "legalName": "Computer Service",
  "email": "info@infygain.com",
  "url": "https://infygain.com/",
  "logo": "https://infygain.com/images/logo.svg",
  "sameAs": [
    "https://twitter.com/infygain",
    "https://www.instagram.com/infygain/",
    "https://www.facebook.com/infygain/",
    "https://www.linkedin.com/company/infygain-technologies/",
    "https://www.youtube.com/@infygain6707",
    "https://infygain.com/"
  ],
  "address": {
    "addressLocality": "prozone mall",
    "postalCode": "641035",
    "streetAddress": "Hari complex opp.prozone mall, Sathy Rd, Saravanampatti, Coimbatore, Tamil Nadu "
  }
};

const breadcrumbSchema = {
  "@context": "https://schema.org/",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://infygain.com/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "About - Us",
      "item": "https://infygain.com/about"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "IT Supports",
      "item": "https://infygain.com/Iotservice"
    },
    {
      "@type": "ListItem",
      "position": 4,
      "name": "ISP Services",
      "item": "https://infygain.com/ispservice"
    },
    {
      "@type": "ListItem",
      "position": 5,
      "name": "WEB Services",
      "item": "https://infygain.com/ServiceSeo"
    },
    {
      "@type": "ListItem",
      "position": 6,
      "name": "Blogs",
      "item": "https://infygain.com/mainblogs"
    },
    {
      "@type": "ListItem",
      "position": 7,
      "name": "Career",
      "item": "https://infygain.com/career"
    },
    {
      "@type": "ListItem",
      "position": 8,
      "name": "Partner",
      "item": "https://infygain.com/Partner"
    },
    {
      "@type": "ListItem",
      "position": 9,
      "name": "Contact",
      "item": "https://infygain.com/contact"
    },
    {
      "@type": "ListItem",
      "position": 10,
      "name": "Online Store",
      "item": "https://infygain.com/onlinestore/"
    }
  ]
};

const localBusinessSchema = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Infygain Technologies",
  "image": "https://infygain.com/images/logo.svg",
  "@id": "",
  "url": "https://infygain.com",
  "telephone": "9952141444",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "hari complex opp.prozone mall, Sathy Rd, Saravanampatti",
    "addressLocality": "Coimbatore",
    "postalCode": "641035",
    "addressCountry": "IN"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 11.0549976,
    "longitude": 76.9951292
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "opens": "09:45",
    "closes": "19:00"
  }
};


const websiteSchema = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Infygain Technologies",
  "url": "https://infygain.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://infygain.com/{search_term_string}",
    "query-input": "required name=search_term_string"
  }
};


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://infygain.com/"
  },
  "headline": "Best IT support and web development company in Coimbatore",
  "description": "We are a prominent IT service provider, based in Coimbatore, with a wide range of products and services, to meet the exclusive needs of our customers. We offer end-to-end solutions to enable a reliable and uninterrupted workflow for our customers.",
  "image": "https://infygain.com/images/others/services.webp",
  "author": {
    "@type": "Organization",
    "name": ""
  },
  "publisher": {
    "@type": "Organization",
    "name": "Infygain Technologies",
    "logo": {
      "@type": "ImageObject",
      "url": "https://infygain.com/images/logo.svg"
    }
  },
  "datePublished": "2024-01-12"
};
const videoSchema = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Infygain Technologies",
  "description": "INFYGAIN - Leading Computer Service at Doorstep in Coimbatore",
  "thumbnailUrl": "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
  "uploadDate": "2024-01-12T12:00:00Z",
  "duration": "PT1M1M",  // Replace with the actual duration of your video
  "contentUrl": "https://youtu.be/CdP9VCScVmQ?si=4U5prMcVo1eS1qwY",
  "embedUrl": "https://www.youtube.com/embed/CdP9VCScVmQ",
  "publisher": {
    "@type": "Organization",
    "name": "Infygain Technologies",
    "logo": {
      "@type": "ImageObject",
      "url": "URL to your organization's logo"
    }
  }
};
function NewServicePage() {
  const { id } = useParams();

  const valuePaths = [
    "networking",
    "laptopanddesktop",
    "cctv",
    "Projectors",
    "Accessories",
    "TV",
    "Printer&Scanner",
    "InteractivePanel",
    "UPS",
    "WIFI",
    "Pos",
    "InterCom",
    "Server",
    "Cloud"
  ];

  let metaContent =
    id === "networking"
      ? {
          des: "Networking service provider in coimbatore. Top Sales and Services in Hub, Switch, Router, Firewall, End Point Security, and Fiber Solutions.",
          key: "best networking, switch, router, firewall, end pointer security, fiber, network provider, wifi router, fiber optic cable, network security, wireless router,    ",
          ogt: "Networking Service provider in coimbatore,Network Solution",
          og: "Best networking service provider in coimbatore. Top Sales and Services in Hub, Switch, Router, Firewall, End Point Security, and Fiber Solutions.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "laptopanddesktop"
      ? {
          des: "Best Computer Service Center in Coimbatore.We provide Quality Computer Service & Laptop Service at affordable price.",
          key: "best computer service, desktop, gaming laptop service , computer sales, best laptop, hp laptop, dell laptop, Lenovo laptop, acer laptop, asus laptop, cheap laptop, ",
          ogt: "Computer Service center in coimbatore,Laptop Service center in coimbatore",
          og: "Best Computer Service Center in Coimbatore.We provide Quality Computer Service & Laptop Service at affordable price.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "cctv"
      ? {
          des: "CCTV camera dealers in coimbatore.Looking for CCTV camera dealers? Discover top-quality surveillance systems and expert advice for your home or business.",
          key: "cctv, camera, ip camera, analog camera, cctv camera, digital camera, computer sales, ptz camera, 360 camera",
          ogt: "CCTV Camera Dealer in coimbatore,best cctv camera shop in Coimbatore",
          og: "CCTV camera dealers in coimbatore.Looking for CCTV camera dealers? Discover top-quality surveillance systems and expert advice for your home or business.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Projectors"
      ? {
          des: "Best projector in Coimbatore for college & office and we also provide the best sales and services in smart projectors, LCD Projector and LED Projector.",
          key: "projectors, LCD, LED, smart projectors, projectors for home, best projectors, cheap projectors, projectors for office.",
          ogt: "Best projector in Coimbatore|Best projector for College &Office",
          og: "we provide the best projectors for college & office and we also provide the best sales and services in smart projectors, LCD and LED.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Accessories"
      ? {
          des: "Computer Accessories Shop in Coimbatore! Top sales and services in RAM, keyboard, monitor, cabinet, motherboard, cables, charger, connector, and converters.",
          key: "projectors, LCD,LED, smart projectors, projectors for home, best projectors, cheap projectors, projectors for office.",
          ogt: "Best Computer Accessories shop in Coimbatore|Computer Accessories Store.",
          og: "Computer Accessories Shop in Coimbatore! Top sales and services in RAM, keyboard, monitor, cabinet, motherboard, cables, charger, connector, and converters.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Accessories"
      ? {
          des: "Computer Accessories Shop in Coimbatore! Top sales and services in RAM, keyboard, monitor, cabinet, motherboard, cables, charger, connector, and converters.",
          key: "projectors, LCD,LED, smart projectors, projectors for home, best projectors, cheap projectors, projectors for office.",
          ogt: "computer accessories shop in Coimbatore.|best computer accessories service",
          og: "Computer Accessories Shop in Coimbatore! Top sales and services in RAM, keyboard, monitor, cabinet, motherboard, cables, charger, connector, and converters.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "TV"
      ? {
          des: "Elevate your entertainment with the best TV solutions in Coimbatore. Explore top-quality options, unbeatable prices, and expert service.",
          key: "TV, android tv, smart tv, internet tv, best tv, cheap tv, 32 inch smart tv,  50 inch smart tv, 43 inch smart tv, best smart tv, new model smart tv",
          ogt: "best smart tv sales and services in coimbatore|best tv service center",
          og: "Coimbatore's best for computer accessories! Top sales and services in RAM, keyboard, monitor, motherboard, cables, chargers, connectors, and converters.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Printer&Scanner"
      ? {
          des: "Printer Service Center in coimbatore.we offer laser printer service, thermal printer service, color, multifunction, and flatbed service. ",
          key: "printer, thermal printer, laser printer, scanner, best printer,hp ink printer",
          ogt: "Printer Service Center in Coimbatore|printer Repair service in coimbatore",
          og: "Best Printer Repair Service|Printer Service Center in coimbatore – laser, thermal, color, multifunction, and flatbed. Exceptional quality and variety for your needs.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "InteractivePanel"
      ? {
          des: "we offer the greatest interactive panel in coimbatore and we also provide the best sales and services in interactive panel 65 and interactive panel 75.",
          key: "interactive panel, interactive panel 65, interactive panel 75, interactive touchscreen, best interactive panel, best quality panel, cheap interactive panel",
          ogt: "Best interactive panel service center in coimbatore",
          og: "we offer the greatest interactive panel in coimbatore and we also provide the best sales and services in interactive panel 65 and interactive panel 75.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "UPS"
      ? {
          des: "Get the best UPS service in Coimbatore from our trusted website. We provide reliable power backup solutions to ensure uninterrupted operations for your business. Contact us now! ",
          key: "ups, ups sales, ups service, ups for home, ups for office, best ups, new models in ups, cheap ups, online ups, inventor",
          ogt: "UPS for home in coimbatore|best ups store in coimbatore",
          og: "we offer the best online ups and inventor sales and services in coimbatore.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "WIFI"
      ? {
          des: "WiFi Service in coimbatore and we also provide the best wifi services in wifi Router, wifi 6 and access point controller.",
          key: "wifi, best wifi device, high quality wifi, wifi extender, home wifi, wifi router, best wifi extender, wireless wifi, best wifi router, portable wifi, cheap wifi",
          ogt: "WiFi Service provider in Coimbatore|best internet Service provider",
          og: "We provide the best wifi Service in coimbatore and we also provide the best wifi services in wifi 5, wifi 6 and access point controller.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "Pos"
      ? {
          des: "Pos Machine Dealers in coimbatore and we also provide the best sales and services in billing apps, kiosk and POS Machine.",
          key: "point of sale, best pos, best pos for small business, pos system for sale, billing apps, kiosk, best kiosk, cheap pos system, pos system, high quality pos",
          ogt: "POS Machine Dealers in coimbatore| Pos Machine Service in coimbatore",
          og: "Best Pos Dealer in coimbatore and we also provide the best sales and services in billing apps, kiosk and POS.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : id === "InterCom"
      ? {
          des: "Intercom Repair Service in coimbatore and we offer the best sales and services in IPPBX, EPABX and intercom service.",
          key: "intercom, best intercom, cheap intercom, best intercom sales, new models in intercom, intercom for business, intercom price, intercom online, intercom epabx",
          ogt: "Intercom Repair Service in Coimbatore|Intercom Service in Coimbatore.",
          og: "we offer the best intercom Repair Service in coimbatore and we also offer the best sales and services in IPPBX, EPABX and intercom.",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
        : id === "Server"
      ? {
          des: "Server Service provider in coimbatore and we offer the Server installation services, server maintenance service,storage and web server ",
          key: "intercom, best intercom, cheap intercom, best intercom sales, new models in intercom, intercom for business, intercom price, intercom online, intercom epabx",
          ogt: "Server Service Company in Coimbatore|Server Installation|Server Maintenance Service",
          og: "Server Service provider in coimbatore and we offer the Server installation services, server maintenance service,storage and web server ",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
        : id === "Cloud"
        ?{
          des: "Discover the top cloud service providers in Coimbatore! Get reliable and secure cloud solutions tailored to your business needs. Explore now!",
          key: "intercom, best intercom, cheap intercom, best intercom sales, new models in intercom, intercom for business, intercom price, intercom online, intercom epabx",
          ogt: "Cloud Service Providers in Coimbatore|Cloud Service in Coimbatore",
          og: "Discover the top cloud service providers in Coimbatore! Get reliable and secure cloud solutions tailored to your business needs. Explore now!",
          logo: "https://infygain.com/images/icons/infy-logo.svg",
        }
      : "";






  return (
    <div>
      <Helmet>
        {/* <title></title> */}
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{metaContent.ogt}</title>
        <link rel="canonical" href={currentUrl} />

        <link rel="icon" href={"favicon.ico"} />
        <meta name="description" content={metaContent.des} />
        <meta name="keywords" content={metaContent.key} />

        <meta
          name="google-site-verification"
          content="ocR-tZ4yt2LD1ePYD1bR4LK6GYUc0keDJcspEtblHoM"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={metaContent.ogt} />
        <meta property="og:description" content={metaContent.og} />
        <meta property="og:image" content={metaContent.logo} />
        <meta property="og:url" content="https://infygain.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Infygain Technologies" />
        {/*  END FB Open Graph */}

        {/* Twitter Card Meta Tags */}

        <meta
          name="twitter:card"
          content="Best computer/laptop sales and service in coimbatore"
        />
        <meta name="twitter:site" content="Infygain Technologies" />
        <meta name="twitter:title" content="Infygain Technologies" />
        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:description"
          content="We are provide the best computer and laptop, networking service in coimbatore and also we best in cctv camera, server, billing software, graphic design, amc."
        />
        <meta name="twitter:image" content={metaContent.logo} />
        {/* Robot tag */}
        <meta name="robots" content="index, follow" />

        {/* End Robot tag */}

        {/* Facebook Pixel Code */}
        <script type="text/javascript">
          {`
               !function(f,b,e,v,n,t,s)
               {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
               n.callMethod.apply(n,arguments):n.queue.push(arguments)};
               if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
               n.queue=[];t=b.createElement(e);t.async=!0;
               t.src=v;s=b.getElementsByTagName(e)[0];
               s.parentNode.insertBefore(t,s)}(window, document,'script',
               'https://connect.facebook.net/en_US/fbevents.js');
               fbq('init', '1093378958018811');
               fbq('track', 'PageView');
               `}
        </script>

        <noscript>
          {`
               <img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=1093378958018811&ev=PageView&noscript=1"
               />
               `}
        </noscript>
        {/* End Facebook Pixel Code */}

       {/* Organization schema*/}
       <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>

        {/* Breadcrumb schema*/}
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>


        {/* Local business 31/5/23 schema */}

        <script type="application/ld+json">
          {JSON.stringify(localBusinessSchema)}
        </script>

        {/* Website Schema */}
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
        {/*Article Schema */}
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
        {/*Video Schema*/}
        <script type="application/ld+json">
          {JSON.stringify(videoSchema)}
        </script>


       

        {/* Google Analytics */}
        <script>
          {`
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)),
      (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", "UA-XXXXX-Y", "auto");
  ga("send", "pageview");
`}
        </script>
        {/* End Google Analytics */}

        {/* Google Tag Manager */}
        <script>
          {`
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NRCN7RF");
`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-89FZ8YHYVL"
        ></script>

        <script>
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-89FZ8YHYVL');
        `}
        </script>
        {/* End Google tag (gtag.js) */}

        {/* Google optimizer */}
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NWGHP4M"></script>
        {/* End Google optimizer */}
      </Helmet>
      {valuePaths.includes(id) ? (
        <>
          <div className="webservice">
            <IotHeader />
            <ServiceBanner />

            <Category valuePaths={valuePaths} />

            <Enquiry />
            <IotFooter />
          </div>
        </>
      ) : (
        <Four />
      )}
    </div>
  );
}

export default NewServicePage;
