import "../css/mainpackage.css";
import Header from "../common/header";
import Footer from "../common/footer";
import Bannerlle from "./leasedbanner";

const Intranet = () => {
  return (
    <div className="ispmainservice">
      <Header />
      <Bannerlle content={"Intranet"} />

      <div className="container lolp mt-5 mb-5">
        <div className="hkl mt-3">
          <h2 className="bril mb-5 int-isp1">Intranet</h2>
        </div>
        <div className="row">
          <div
            className="col-md-4 col-lg-4  popp"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="lmk sme-img2">
              <img
                src="/images/intranet-vector1.webp"
                alt="Intranet"
                className="image-fluid"
                title="Intranet"
              />
            </div>
          </div>
          <div
            className="col-md-8 col-lg-8   wifit"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              Welcome to our Intranet Connectivity Service, your gateway to
              seamless and reliable connectivity ensuring your business remains
              operational 24/7 within our internal network.
            </p>
            <p className="text-muted">
              Our service guarantees high-speed, dedicated Intranet
              connectivity, keeping you connected at all times. With a
              commitment to 1:1 symmetric bandwidth, you'll experience
              consistent upload and download speeds, optimizing your online
              performance within our corporate environment. Moreover, we are
              IPv6 ready, ensuring future-proof compatibility with evolving
              technologies. At the infrastructure level, we provide dual
              upstream parenting at the central server level and dual server
              parenting at the last mile, offering redundancy and reliability to
              minimize downtime and optimize network performance. To further
              support your connectivity needs, each client receives the
              dedicated assistance of a Technical Assurance Manager, ensuring
              prompt issue resolution and personalized support within our
              intranet ecosystem.
              <br />
            </p>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-7 col-lg-7 wifit1"
            id="column2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              "Catalyze your business potential with our Intranet Connectivity
              Services – your dedicated gateway to seamless connectivity and
              unparalleled performance within our internal network."
            </p>
            <p className="text-muted ">
              Elevate your online presence with our Intranet Connectivity
              solutions meticulously crafted to meet the demands of modern
              businesses. With our robust infrastructure and unwavering
              commitment to reliability, we deliver a dedicated Intranet
              connection tailored to fuel your operations with consistent speeds
              and uninterrupted access within our corporate environment. Say
              goodbye to bandwidth bottlenecks and latency issues as our
              Intranet Connectivity solutions offer symmetric speeds and low
              latency, ensuring optimal performance for mission-critical
              applications. Backed by our proactive monitoring and responsive
              support team, we guarantee maximum uptime and swift resolution of
              any connectivity concerns, allowing you to focus on driving your
              business forward. Experience the power of reliable connectivity
              with our Intranet Connectivity Services and unlock new
              possibilities within our corporate digital landscape.
              <br />
            </p>
          </div>
          <div
            className="col-md-5 col-lg-5 olk"
            id="column1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="lmk2">
              <img
                src="/images/intranet-vector2.webp"
                alt="Intranet"
                className="image-fluid"
                title="Intranet"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Intranet;
