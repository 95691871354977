
import React, { useEffect, useState } from 'react';
import "../css/ecommercedemo.css";
import { LuSmilePlus } from "react-icons/lu";
import { MdOutlineImportantDevices } from "react-icons/md";
import { FaUsersGear } from "react-icons/fa6";
import { MdOutlineReviews } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LiaTelegramPlane } from "react-icons/lia";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPencilAlt, faLightbulb, faCog, faBrain } from '@fortawesome/free-solid-svg-icons';
import { FaPlay } from "react-icons/fa";
import { BsPlus } from 'react-icons/bs';
import { SiSecurityscorecard } from "react-icons/si";
import { Link } from 'react-router-dom';

const EcommerceDemoemo = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    dots: false,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <>


      {/* //ecommerce banner section//  */}
      <section>
        <div className="ecommerce_banner">
          <div className='overlay_eocm'></div>
          <div className="banner_content">
            <div className='banner_head'>
              <p>Crafted Marketing Strategies For Social Media Specialist </p>
            </div>
            <div className="ecom_content">

              <div className="box">
                <div className="title">
                  <span className="block"></span>
                  <p>
                    E-commerce Billing Software in <span></span>
                  </p>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="500">
              <p className="eb_para">
                Manage your business professionally with Vyapar. Using the best
                software for your billing, inventory & accounting needs.
              </p>
            </div>
            <div className="banner_btn_box" data-aos="fade-up" data-aos-duration="3000">
              <button className="ecombanner_btn " data-aos="fade-up" data-aos-duration="3000">
                <span>  Our Products! &nbsp;</span>
                <svg viewBox="0 0 13 10" height="10px" width="15px">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </button>
              <div className="containerr">
                <div className="button is-play" >
                  <div className="button-outer-circle has-scale-animation"></div>
                  <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                  <div className="button-icon is-play">
                    <FaPlay className="triangle" />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='banner_image_box'>
          {/* <img src="./images/ecom_banner.jpg" alt="Demo 1" /> */}
          {/* <div className='banner_bottom_box'>
            <div className='inner_bottom_box'>
            Unveil Brilliance:
            Your Destination for Elite Car Care Solutions,for Elite Car Care Solutions ,care
            </div>
          </div> */}
          </div>
         
        </div>
        <div className="infy_about_container">
          <div className="e_customer ecom_about_box" data-aos="fade-up">
            <div className="ecom_icon_container">
              <LuSmilePlus className="e_icon" />
            </div>
            <div className="e_content">
              <p className="ecom_head">416 +</p>
              <p className="ecom_subhead">Happy Customers</p>
            </div>
          </div>
          <div className="e_res ecom_about_box" data-aos="fade-up">
            <div className="ecom_icon_container">
              <MdOutlineImportantDevices className="e_icon" />
            </div>
            <div className="e_content">
              <p className="ecom_head">Multi-Device</p>
              <p className="ecom_subhead">Mobile/Desktop</p>
            </div>
          </div>
          <div className="e_user ecom_about_box" data-aos="fade-up">
            <div className="ecom_icon_container">
              <FaUsersGear className="e_icon" />
            </div>
            <div className="e_content">
              <p className="ecom_head">Multi-User</p>
              <p className="ecom_subhead">User Management Feature</p>
            </div>
          </div>
          <div className="e_review ecom_about_box" data-aos="fade-up">
            <div className="ecom_icon_container">
              <MdOutlineReviews className="e_icon" />
            </div>
            <div className="e_content">
              <p className="ecom_head">500 +</p>
              <p className="ecom_subhead">Our Reviews</p>
            </div>
          </div>
        </div>
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#f3f4f5" fill-opacity="0.5" d="M0,160L24,154.7C48,149,96,139,144,122.7C192,107,240,85,288,101.3C336,117,384,171,432,181.3C480,192,528,160,576,133.3C624,107,672,85,720,85.3C768,85,816,107,864,133.3C912,160,960,192,1008,181.3C1056,171,1104,117,1152,90.7C1200,64,1248,64,1296,80C1344,96,1392,128,1416,144L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path>
</svg> */}
      </section>

      {/* //our process section//  */}
     {/* <section>

        <div className="OurProcess_container">
          <div className="OurProcess_head">
            <h1>Our Process</h1>
          </div>
          <div className="OurProcess_separator">
          </div>
          <div className="OurProcess_circles">
            <ul className="OurProcess_circle_holder">
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faSearch} className="process_icon" />
                  </span>
                </span>
                <div className="OurProcess_circle_text_holder">
                  <h5 className="q_circle_title">Research</h5>
                  <p className="q_circle_text">Pitchfork cornhole distillery irony seitan Pinterest <br />next level church-key. Gluten-free</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faPencilAlt} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Concept</h5>
                  <p className="q_circle_text">Blue Bottle bitters street art,<br /> pug wolf iPhone small batch hoodie letterpress shabby</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faLightbulb} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Create</h5>
                  <p className="q_circle_text">Cliche plaid Austin, dreamcatcher<br /> blog bicycle rights tousled kale chips pug</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faCog} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Develop</h5>
                  <p className="q_circle_text">Pitchfork cornhole distillery irony seitan Pinterest <br />next level church-key. Gluten-free</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faBrain} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Test</h5>
                  <p className="q_circle_text">Blue Bottle bitters street art, pug wolf iPhone<br /> small batch hoodie letterpress shabby</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </section>  */}

      {/* //demo contenier section//  */}
      <section>
        
      <div className="demo_container">
      {/* <div className='overlay_eocm'></div> */}
      



        <div className="demo_box" data-aos="fade-right">
          <div className="dimg_container" data-aos="flip-left">
            <img src="./images/demoimg.png" alt="Demo 1" />
          </div>
          <div className="d_content">
            <p className="demo_head">
              Create GST Bills for customers and share them online
            </p>
            <p className="demo_para">
              Vyapar billing software helps you set up a professional brand
              identity with useful GST billing features.
              <span className="para_none">
                Vyapar billing software helps you set up a professional
                brand identity with useful GST billing features.
              </span>
              <br /> <span>+ Read more</span>
            </p>
          </div>
        </div>
        <div className="demo_box" data-aos="fade-left">
          <div className="d_content">
            <p className="demo_head">Manage Inventory Seamlessly</p>
            <p className="demo_para">
              Vyapar brings the finest inventory management software with
              incredibly effective features. It helps improve
              <br /> <span>+ Read more</span>
            </p>
          </div>
          <div className="dimg_container" data-aos="flip-right">
          <img src="./images/demoimg.png" alt="Demo 1" />
          </div>
        </div>
        <div className="demo_box" data-aos="fade-right">
          <div className="dimg_container" data-aos="flip-left">
          <img src="./images/demoimg.png" alt="Demo 1" />
          </div>
          <div className="d_content">
            <p className="demo_head">
              Send Payment Reminders to Recover Dues
            </p>
            <p className="demo_para">
              Vyapar accounting and billing software help SMEs ensure timely
              payments and maintain cash flow. Vyapar does it
              <br /> <span>+ Read more</span>
            </p>
          </div>
        </div>
        <div className="demo_box" data-aos="fade-left">
          <div className="d_content">
            <p className="demo_head">GST filing made simpler and faster</p>
            <p className="demo_para">
              Filing GST is a challenge for business owners and is a long
              process if done manually. Using Vyapar billing software,
              <br /> <span>+ Read more</span>
            </p>
          </div>
          <div className="dimg_container" data-aos="flip-right">
          <img src="./images/demoimg.png" alt="Demo 1" />
          </div>
        </div>
      </div>
   </section>





      <div className="security-section">
    
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>
        <div className="security-topic">
          <h2>Network Security</h2>
          <p>Aenean vitae nisl urna. Vestibulum enim risus, ultricies id ullamcorper at, elementum ut arcu.</p>
          <SiSecurityscorecard className="r_icon" />
          <a href="" className="read-more">
            <span className="icon-cont">
              Read More &nbsp;&nbsp; <BsPlus className="icon" />
            </span>
          </a>
        </div>


      </div >









       //our process section// 
      <section>

        <div className="OurProcess_container container">
          <div className="OurProcess_head">
            <h1>Our Process</h1>
          </div>
          <div className="OurProcess_separator">
          </div>
          <div className="OurProcess_circles">
            <ul className="OurProcess_circle_holder">
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faSearch} className="process_icon" />
                  </span>
                </span>
                <div className="OurProcess_circle_text_holder">
                  <h5 className="q_circle_title">Research</h5>
                  <p className="q_circle_text">Pitchfork cornhole distillery irony seitan Pinterest next level church-key. Gluten-free</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faPencilAlt} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Concept</h5>
                  <p className="q_circle_text">Blue Bottle bitters street art, pug wolf iPhone small batch hoodie letterpress shabby</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faLightbulb} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Create</h5>
                  <p className="q_circle_text">Cliche plaid Austin, dreamcatcher blog bicycle rights tousled kale chips pug</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faCog} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Develop</h5>
                  <p className="q_circle_text">Pitchfork cornhole distillery irony seitan Pinterest next level church-key. Gluten-free</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faBrain} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Test</h5>
                  <p className="q_circle_text">Blue Bottle bitters street art, pug wolf iPhone small batch hoodie letterpress shabby</p>
                </div>
              </li>
              <li className="q_circle_outer">
                <span className="q_circle_inner">
                  <span className="q_circle_inner2">
                    <FontAwesomeIcon icon={faBrain} className="process_icon" />
                  </span>
                </span>
                <div className="q_circle_text_holder">
                  <h5 className="q_circle_title">Test</h5>
                  <p className="q_circle_text">Blue Bottle bitters street art, pug wolf iPhone small batch hoodie letterpress shabby</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </section> 






      {/* <div className="consulting-firm">
        <div className="intro">
          <h1>Features of GST Billing and Accounting Software</h1>
          <p>Sample text. Click to select the text box. Click again or double click to start editing the text. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <div className="features">
            <div className="feature f1 ">
              <img src="path/to/your/image.jpg" alt="Strategy" />
              <h2>STRATEGY</h2>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
            <div className="feature f2">
              <img src="path/to/your/image.jpg" alt="Results" />
              <h2>RESULTS</h2>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
            <div className="feature f1">
              <img src="path/to/your/image.jpg" alt="Expertise" />
              <h2>EXPERTISE</h2>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
            <div className="feature f4">
              <img src="path/to/your/image.jpg" alt="Support" />
              <h2>SUPPORT</h2>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
          </div>
        </div>



      </div>
      <div className='bottom_box'></div> */}




















    </>
  );
};

export default EcommerceDemoemo;
