import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../css/mainpackage.css";

function Bannerspacsme() {
  return (
    <>
      <div className="head-isp-serviceban ">
        <p className="head-isp-about">SME Internet Provider in Coimbatore</p>
        <p className="head-isp-para">3 Static Public IP</p>
        {/* <p className='head-isp-para'>Throughput Commitment:- 85% Of Subscribed Bandwidth</p> */}
        <p className="head-isp-para">IPV6 Ready</p>
        {/* <p className='head-isp-para'>POP Level Dual Upstream Parenting</p> */}
        {/* <p className='head-isp-para'>Last Mile Level Dual Uplink Parenting</p> */}
      </div>
    </>
  );
}

export default Bannerspacsme;
