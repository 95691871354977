import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AOS from "aos";

const SubCateContent = () => {
  const { id } = useParams();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="container mt-5 mp-5">
    {id === "networking" && (
      <>
        <div className="about1">
          <div className="smallsss1"></div>
          <h2 className="aboutline_head1">Complete Networking Solutions</h2>
        </div>
        <div className="biod biode">
          <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
            <b><a className="pos-text" href="/servicedetails/Networking/HUB">Networking Service Provider</a></b>:
Infygain Technologies in Coimbatore offers custom <b>networking</b> solutions for businesses, guaranteeing top-notch performance and security
          </h2>
          <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
            <p>
            At Infygain Technologies, we set a new standard for the best <b>Complete <a className="pos-text" href="/servicedetails/Networking/HUB">Networking</a> solutions</b> in Coimbatore. Our unwavering commitment to client satisfaction, coupled with cutting-edge technology, makes us the preferred choice for businesses aiming for unparalleled connectivity. Elevate your network with us – where excellence meets innovation in Coimbatore's best complete <b><a className="pos-text" href="/servicedetails/Networking/HUB">network solutions</a>  </b>         </p>
          </div>
        </div>
      </>
    )}
          {id === "cctv" &&(
            <><div className='about1'>
              <div className='smallsss1'></div>
              <h2 className='aboutline_head1'>CCTV Camera Dealers</h2>

            </div>
        </>
      )}

      {id === "Accessories" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">Computer Accessories Shop</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              <b><a className="pos-text"href="/servicedetails/Accessories/RAM">Computer Accessories</a></b>: Elevate your setup with our curated
              selection of <b>computer accessories</b>. Experience seamless
              compatibility and enhanced performance, setting a new benchmark
              for accessory excellence.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              At Infygain Technologies, discover a new standard in <b>computer accessories</b>. Our commitment to customer satisfaction, paired with top-of-the-line technology, establishes us as the preferred choice for those seeking the <b>best <a className="pos-text"href="/servicedetails/Accessories/RAM">computer accessories shop</a> in Coimbatore</b>. Elevate your computing experience with us – where quality converges with innovation for unparalleled enhancements in your computing setup.
              </p>
            </div>
          </div>
        </>
      )}
      {id === "Projectors" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">Projectors Sales & Service</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              <b><a className="pos-text"href="/servicedetails/Projectors/LCD">Projectors</a> Service</b>: Illuminate brilliance with our
              precision-engineered <b><a className="pos-text" href="/servicedetails/Projectors/LCD">projectors</a></b>. Elevate your visuals with
              unparalleled clarity and reliability, setting the stage for
              exceptional presentations.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              At Infygain Technologies, discover a new era of projection excellence. Our unwavering dedication to customer satisfaction, fused with cutting-edge technology, makes us the premier choice for those seeking the <b>best projectors for college and office </b>use. Illuminate your presentations with us – where excellence harmonizes with innovation in the best <b><a className="pos-text" href="/servicedetails/Projectors/LCD">projector solutions</a></b> for college and office settings.
              </p>
            </div>
          </div>
        </>
      )}
      {id === "TV" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">TV</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              Infygain Technologies: Transform your entertainment experience
              with our state of the art TVs. Immerse yourself in unparalleled
              clarity and vibrant visuals, setting a new standard for home
              viewing excellence
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              At Infygain Technologies, redefine your TV experience. Our unwavering dedication to customer satisfaction, combined with cutting-edge technology, positions us as the prime choice for those seeking the best TV solutions in Coimbatore. Elevate your viewing pleasure with us – where excellence meets innovation in the world of entertainment.
              </p>
            </div>
          </div>
        </>
      )}

      {id === "InteractivePanel" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">Interactive Panel</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              Infygain Technologies: Step into a world of interactive brilliance
              with our cutting-edge panels. Elevate engagement and learning with
              seamless interactivity, setting a new standard for interactive
              excellence.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              Discover the best in interactive panel sales and service at Infygain Technologies. Our commitment to customer satisfaction, coupled with cutting-edge interactive technology, positions us as the premier choice. Elevate your collaboration and presentation experiences with the best interactive panels – where excellence meets unparalleled service.
              </p>
            </div>
          </div>
        </>
      )}
      {id === "UPS" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">UPS</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              <b><a className="pos-text"href="/servicedetails/UPS/Inventor">UPS</a> Service</b>: Power up your reliability with our advanced
              <b><a className="pos-text"href="/servicedetails/UPS/Inventor"> UPS systems</a></b>. Elevate protection and continuity with seamless power
              backup, setting new standards for reliability excellence.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              At Infygain Technologies, we ensure uninterrupted power with our advanced <b><a className="pos-text"href="/services/UPS">UPS solutions</a></b>. Our steadfast dedication to customer satisfaction, paired with advanced technology, positions us as the preferred choice for those seeking the best <b>UPS solutions in Coimbatore</b>. Safeguard your systems with us – where excellence in protection meets innovative power backup solutions.
              </p>
            </div>
          </div>
        </>
      )}
      {id === "WIFI" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">WIFI</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              <a className="pos-text" href="/servicedetails/WIFI/WifiRouter"><b>WiFi Service Provider</b></a>: Empower connectivity with our advanced <a className="pos-text"href="/servicedetails/WIFI/WifiRouter"><b>WiFi </b>
              solutions</a>. Elevate your network with seamless coverage and speed,
              setting a new standard for wireless excellence.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              At Infygain Technologies, we redefine connectivity with our advanced <b><a className="pos-text" href="/servicedetails/WIFI/WifiRouter">WiFi solutions</a></b>. Our unwavering commitment to customer satisfaction, combined with cutting-edge technology, positions us as the premier choice for those seeking seamless and robust wireless networks. Elevate your connectivity with us – where excellence in <b>WiFi</b> meets innovative solutions.
              </p>
            </div>
          </div>
        </>
      )}
       {id === "cctv" && (
        <>
          <div className="about1">
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
            <b><a className="pos-text" href="/servicedetails/Cctv/Analog">CCTV Camera Dealer</a></b>, we take pride in being the forefront provider of the best <b><a className="pos-text" href="/servicedetails/Cctv/Analog">CCTV camera</a></b> dealers in Coimbatore. With an unwavering commitment to your safety, we bring you cutting-edge technology, customized solutions, and a team of experts dedicated to securing your assets.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              Our smart surveillance systems redefine the way you monitor and secure your premises. As the best <b>CCTV camera</b> provider and <b>cctv camera dealers in Coimbatore</b> Infygain Technologies brings you high-definition cameras, intelligent analytics, and real-time monitoring that deliver a level of security beyond expectations. Whether it's your home, office, or industrial space, our surveillance solutions are designed to provide unmatched protection. Elevate your security standards with us – where excellence meets innovation in Coimbatore's best <a className="pos-text" href="/services/cctv"><b>CCTV camera services</b></a>.
              </p>
            </div>
          </div>
        </>
       )}
      {id === "Pos" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">Point Of Sale</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              <b><a className="pos-text" href="/servicedetails/Pos/POS">POS Machine Dealers</a></b>: Revolutionize transactions with our
              cutting-edge Point of Sale solutions. Elevate convenience and
              efficiency in every transaction, setting a new standard for
              seamless <b><a className="pos-text" href="/servicedetails/Pos/POS">POS</a></b> excellence
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
              At Infygain Technologies, revolutionize your business transactions with our cutting-edge <b><a className="pos-text" href="/servicedetails/Pos/POS">POS solutions</a></b>. Our dedication to customer satisfaction, coupled with advanced technology, positions us as the go-to choice for those seeking the  <b>POS solutions in Coimbatore</b>. Streamline your sales process with us – where innovation meets excellence in efficient and reliable transaction management..
              </p>
            </div>
          </div>
        </>
      )}
      {id === "InterCom" && (
        <>
          <div className="about1">
            <div className="smallsss1"></div>
            <h2 className="aboutline_head1">InterCom</h2>
          </div>
          <div className="biod biode">
            <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
              <a className="pos-text" href=""><b>Intercom Services</b></a>: Enhance communication with our
              state-of-the-art <a className="pos-text"href="/servicedetails/InterCom/EPABX"><b>intercom systems</b></a>. Elevate connectivity and
              clarity in every interaction, setting a new standard for seamless
              intercom excellence.
            </h2>
            <div
              className="text-muted pol"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <p>
                At Infygain Technologies, redefine communication efficiency with
                our <b>Intercom solutions</b>. Our unwavering commitment to customer
                satisfaction, combined with cutting-edge technology, positions
                us as the prime choice for those seeking seamless and reliable
                communication systems. Enhance your connectivity with us – where
                excellence in <a className="pos-text"href="/servicedetails/InterCom/EPABX">Intercom</a> meets innovative solutions.
              </p>
            </div>
          </div>
          
        </>
      )}
        {id === "Server" && (
      <>
        <div className="about1">
          <div className="smallsss1"></div>
          <h2 className="aboutline_head1">Server</h2>
        </div>
        <div className="biod biode">
          <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
          <b><a className="pos-text" href="/servicedetails/Server/ServerInstallation">Server Service Provider</a></b>: Elevate your business with seamless <b><a className="pos-text" href="/servicedetails/Server/ServerInstallation">server</a> installation</b> and expert setup services. Our robust solutions ensure optimal performance and success for your infrastructure.
          </h2>
          <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
            <p>
            At Infygain Technologies, we set a new standard for the best complete <b>server solutions in Coimbatore</b>. Our unwavering commitment to client satisfaction, coupled with cutting-edge technology, makes us the preferred choice for businesses aiming for unparalleled <b>server</b> performance. Elevate your server infrastructure with us – where excellence meets innovation in Coimbatore's best complete <a className="pos-text" href="/servicedetails/Server/ServerInstallation">server solutions</a>.
              </p>
          </div>
        </div>
      </>
    )}
        {id === "Cloud" && (
      <>
        <div className="about1">
          <div className="smallsss1"></div>
          <h2 className="aboutline_head1">Cloud Services</h2>
        </div>
        <div className="biod biode">
          <h2 className="pill" data-aos="fade-right" data-aos-duration="1500">
          Explore top-notch <b>cloud services</b> on our website. Enhance your business with scalable and cost-effective solutions tailored to your requirements.
          </h2>
          <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
            <p>
            At Infygain Technologies, we set a new standard for the best <b>cloud services in Coimbatore</b>. Our unwavering commitment to client satisfaction, combined with cutting-edge technology, makes us the preferred choice for businesses seeking unparalleled cloud solutions. Elevate your business infrastructure with us – where excellence meets innovation in Coimbatore's premier <b>cloud services</b>.
              </p>
          </div>
        </div>
      </>
    )}
    </div>
  );
};

export default SubCateContent;
