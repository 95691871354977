import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../css/Category.css";
import AOS from "aos";
import SubCateContent from "./SubCateContent";

const Category = () => {
  const { id } = useParams();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const laptop = [
    {
      id: 1,
      path: "GamingLaptop",
      title: "Gaming Laptop",
      para: "High-performance gaming laptops with cutting-edge graphics and processing power for immersive gameplay.",
    },
    {
      id: 2,
      path: "Consumerlaptops",
      title: "Consumer laptops",
      para: "Consumer laptops designed for everyday use, offering versatility, portability, and reliability for various tasks and entertainment",
    },
    {
      id: 3,
      path: "WorkStation",
      title: "Work Station",
      para: "Powerful workstations crafted for high-performance computing and demanding professional applications.",
    },
  ];
  const desktop = [
    {
      id: 1,
      path: "BrandedDesktop",
      title: "Branded Desktop",
      para: "Top-tier branded desktops featuring superior build quality, performance, and reliability for diverse computing needs.",
    },
    {
      id: 2,
      path: "AssembledDesktop",
      title: "Assembled Desktop",
      para: "Tailor-made assembled desktops crafted to exact specifications, delivering personalized performance and versatility.",
    },
    {
      id: 3,
      title: "Work Station Desktop",
      para: "Workstations: Engineered for elite performance in specialized tasks, from complex data analysis to professional content creation",
    },
    {
      id: 4,
      path: "GamingDesktop",
      title: "Gaming Desktop",
      para: "High-powered gaming desktops designed to deliver top-tier performance, immersive graphics, and seamless gameplay experiences",
    },
  ];
  const printer = [
    {
      id: 1,
      path: "BrandedDesktop",
      title: "Laser",
      para: "High-speed, efficient printers utilizing laser technology to produce crisp, high-quality prints ideal for bulk and text-heavy documents.",
    },
    {
      id: 2,
      path: "AssembledDesktop",
      title: "Thermal",
      para: "Utilizing heat to produce high-quality prints, ideal for receipts, labels, and applications where fast, quiet printing is crucial.",
    },
    {
      id: 3,
      path: "WorkStationDesktop",
      title: "Color",
      para: "Devices capable of producing vibrant, high-resolution prints in a variety of colors, ideal for photos, presentations, and creative projects.",
    },
    {
      id: 4,
      path: "GamingDesktop",
      title: "Multi Function",
      para: "All-in-one devices combining printing, scanning, copying, and sometimes faxing functionalities for versatile office or home use.",
    },
    {
      id: 5,
      path: "GamingDesktop",
      title: "Dot Matrix",
      para: "Utilizing impact-based technology, these printers create text and graphics through a matrix of pins, often used for continuous forms and basic prints.",
    },
  ];
  const scanner = [
    {
      id: 1,
      title: "Flatbed",
      para: " Devices with a flat, glass surface for scanning documents or photos, offering high-quality, versatile scanning capabilities...",
    },
    {
      id: 2,
      title: "Vertical",
      para: " Devices designed to scan documents in a vertical position, often optimized for quick, efficient scanning of multiple pages or documents",
    },
  ];

  const Networking = [
    {
      id: 1,
      path: "GamingLaptop",
      title: "HUB",
      para: "Explore central connectivity with our HUB solutions. Streamline data sharing and communication within your network effortlessly.",
    },
    {
      id: 2,
      path: "Consumerlaptops",
      title: "Switch",
      para: "Upgrade to high-performance networking with our cutting-edge switches. Experience efficient data transfer and network optimization.",
    },
    {
      id: 3,
      path: "WorkStation",
      title: "Router",
      para: "we understand the pivotal role that routers play in the heart of modern networks. Our commitment is to provide cutting-edge router solutions. ",
    },
    {
      id: 4,
      path: "GamingLaptop",
      title: "Firewall",
      para: "Safeguard your digital fortress with our robust firewall solutions. Defend against cyber threats and ensure a secure digital environment.",
    },
    {
      id: 5,
      path: "Consumerlaptops",
      title: "End Point Security",
      para: "Fortify your network's endpoints against cyber threats. Our endpoint security solutions provide comprehensive protection..",
    },
    {
      id: 6,
      path: "WorkStation",
      title: "Fiber Solution",
      para: "Propel your network into the future with fiber optics. Enjoy lightning-fast data transfer, low latency, and unparalleled reliability.",
    },
    {
      id: 7,
      title: "SD-WAN",
      para: "Propel your network into the future with fiber optics. Enjoy lightning-fast data transfer, low latency, and unparalleled reliability.",
    },
  ];
  const Cctv = [
    {
      id: 1,
      path: "GamingLaptop",
      title: "Analog",
      para: "Immerse yourself in timeless simplicity with our best Analog cameras and electronics. Experience clarity and reliability that stands the test of time.",
    },
    
    {
      id: 2,
      path: "Consumerlaptops",
      title: "IP",
      para: "Step into the future with our best IP cameras and solutions. Harness the power of connectivity and high-definition surveillance...",
    },
    {
      id: 5,
      path: "Consumerlaptops",
      title: "360",
      para: "Experience comprehensive surveillance with our best 360-degree cameras. Eliminate blind spots and gain complete visibility for..",
    },
    {
      id: 6,
      path: "WorkStation",
      title: "PTZ",
      para: "Navigate your security landscape with precision using our best PTZ (Pan-Tilt-Zoom) cameras. Control, zoom, and focus for a customizable...",
    },
    {
      id: 3,
      path: "WorkStation",
      title: "DVR",
      para: "Secure your space with confidence using our best DVR and NVR systems. Capture, store, and access footage seamlessly with advanced recording...",
    },
    {
      id: 4,
      path: "GamingLaptop",
      title: "NVR",
      para: "Secure your space with confidence using our best DVR and NVR systems. Capture, store, and access footage seamlessly with advanced recording...",
    },
    {
      id: 7,
      title: "Bio-Metric",
      para: "Biometrics utilizes unique biological characteristics for identification and verification, bolstering security in applications like access control and authentication.",
    },
    {
      id: 8,
      title: "Boom Barrier",
      para: "A boom barrier is a horizontal arm used for controlling vehicle access at checkpoints, parking lots, and gated entrances. ",
    },
    {
      id: 9,
      title: "Access Control",
      para: "Access control refers to the process of regulating and managing entry to physical or digital spaces, resources, or information. ",
    },
    
  ];
  const Accessories = [
    {
      id: 1,
      title: "RAM",
      para: "Crucial hardware for a computer's performance, providing high-speed memory for quick access to data and applications.",
    },
    {
      id: 2,
      title: "HDD,SSD",
      para: "Storage essentials for computers; HDDs offer high capacity, while SSDs deliver speed and reliability through flash memory technology.",
    },
    {
      id: 3,
      title: "KeyBoard",
      para: " Essential input devices designed for comfort and efficiency, enabling user interaction and text input on computers.",
    },
    {
      id: 4,
      title: "Mouse,Moniter",
      para: "Mouse: Precision navigation tool; Monitor: Visual display for computer output, offering varied sizes and resolutions",
    },
    {
      id: 5,
      title: "Cabinet",
      para: "The housing unit that holds and organizes computer components, providing structure and protection within a PC setup",
    },
    {
      id: 6,
      title: "Mother Board",
      para: " The central circuit board linking and powering all computer components, serving as the foundation for a system's functionality and expansion",
    },
    {
      id: 7,
      title: "SMPS",
      para: "These suits are made from different materials based on the specific risks and requirements of the tasks.",
    },
    {
      id: 8,
      title: "Cables,Charger",
      para: "Cables: Connectors vital for data transfer; Charger: Power supply for device replenishment, ensuring uninterrupted usage.",
    },
    {
      id: 9,
      title: "Connector,Convertor",
      para: "Connectors create physical links between devices, while converters enable compatibility by adapting interfaces or signals.. ",
    },
  ];
  const Projectors = [
    {
      id: 1,
      title: "LCD",
      para: "LCD projectors: Portable, high-resolution visual solutions, perfect for presentations, movies, and etc..",
    },
    {
      id: 2,
      title: "LED",
      para: "LED projectors: Compact, energy-efficient devices providing vibrant, high-resolution projections for diverse entertainment.",
    },
    {
      id: 3,
      title: "Smart Projector",
      para: "Smart projectors: All-in-one connectivity and interactive capabilities for dynamic, immersive projection experiences.",
    },
    {
      id: 4,
      title: "TV",
      para: "Access a world of entertainment with seamless integration of apps, games, and streaming services on your television",
    },
    {
      id: 5,
      title: "Interactive Panel",
      para: "Transform your presentations and collaboration sessions with touch-enabled displays that foster engagement and productivity",
    },
  ];
  const TV = [
    {
      id: 1,
      title: "Andriod TV",
      para: "Android TV: Smart TV platform powered by Android OS, offering a range of apps, games, and streaming services directly on your television",
    },
    {
      id: 2,
      title: "Smart TV",
      para: "Smart TVs: Integrated with internet connectivity and apps, offering a range of online content, streaming services, etc..",
    },
  ];

  const InteractivePanel = [
    {
      id: 1,
      title: "Interactive Panel 65",
      para: " A 65-inch touch-enabled display fostering collaboration and interactive engagement in presentations and educational environments",
    },
    {
      id: 2,
      title: "Interactive Panel 75",
      para: "A 75-inch touch-enabled display facilitating dynamic and engaging interactions for presentations, education, and collaborative work. ",
    },
  ];
  const UPS = [
    {
      id: 1,
      title: "Inventor",
      para: "A power backup system ensuring uninterrupted operation for devices during power outages, designed by an innovative creator",
    },
    {
      id: 2,
      title: "Online UPS",
      para: "A power backup system offering continuous and stable electricity flow, safeguarding devices from power disruptions or fluctuations.",
    },
  ];
  const WIFI = [
    {
      id: 1,
      title: "Instant Access Point",
      para: "An Instant Access Point (IAP) is a wireless networking device that provides quick and easy deployment of wireless networks.",
    },
    {
      id: 2,
      title: "Access Point Controller",
      para: "An Access Point (AP) is a networking device that allows wireless devices, such as laptops, smartphones, and tablets, to connect to a wired network. ",
    },
    {
      id: 3,
      title: "Wifi Router",
      para: "A WiFi router is a device that enables wireless communication between devices and the internet or a local network.",
    },
    {
      id: 4,
      title: "Point to Point",
      para: "A point-to-point WiFi system consists of two devices, typically antennas or routers, that establish a direct wireless connection between each other over a considerable distance.",
    },
 
  ];
  const pos = [
    {
      id: 1,
      title: "POS",
      para: "Point of Sale (POS) systems are software and hardware used to complete transactions and manage sales in retail businesses.",
    },
    {
      id: 2,
      title: "KIOSK",
      para: "A self-service interactive terminal providing information, services, or transactions in various settings like retail, airports, or informational booths",
    },
    {
      id: 3,
      title: "Billing Apps",
      para: "Software applications facilitating streamlined invoicing, payment processing, and financial management for businesses or individuals.",
    },
    {
      id: 4,
      title: "Barcode Scanner",
      para: "A barcode scanner reads barcodes by emitting light onto them, detecting the reflected pattern, and converting it into data for inventory and sales tracking.",
    },
    {
      id: 5,
      title: "Table Top Barcode Scanner",
      para: "A tabletop barcode scanner, like its handheld counterpart, reads barcodes by emitting light, detecting patterns, and converting them into usable data for inventory and sales tracking.",
    },
  ];
  const InterCom = [
    {
      id: 1,
      title: "EPABX",
      para: "An automated telephony system facilitating internal and external call management within organizations for streamlined communication.",
    },
    {
      id: 2,
      title: "IPPBX",
      para: " Leveraging internet protocol for advanced call routing and communication capabilities within organizational networks",
    },
    // {
    //   id: 3,
    //   title: "InterCom",
    //   para: "Intercom offers a mobile app that allows users to manage conversations, support tickets, and engage with customers via their phones.",
    // },
  ];

  const server = [
    {
      id: 1,
      title: "Server Installation",
      para: "  Get reliable server installation service for your business. Our experts ensure seamless setup and configuration to optimize your server's performance.",
    },
    {

      id: 2,
      title: "Server Maintenance",
      para: "  Enhance the reliability of your servers with our top-notch maintenance services. Our team ensures smooth operations and timely updates, minimizing disruptions. ",
    },
    {
      id: 3,
      title: "Web Server",
      para: "Discover the power of web servers! Our website provides comprehensive information on web servers, helping you understand their importance and functionality.",
    },
    {
      id: 4,
      title: "Storage Server",
      para: "Looking for a reliable storage server? Explore our website for top-notch storage solutions that cater to your business requirements.",
    },
  ];
  const Cloud = [
    {
      id: 1,
      path: "Cloud Hosting",
      title: "Cloud Hosting",
      para: " Discover reliable and secure cloud hosting solutions for your website. Experience top-notch performance and scalability with our services.",
    },
    
    {
      id: 2,
      path: "Data Backup & Recovery",
      title: "Data Backup & Recovery",
      para: " Protect your valuable data with our reliable backup and recovery solutions. Safeguard your information and ensure business continuity.",
    },
    {
      id: 3,
      path: "Cloud Security",
      title: "Cloud Security",
      para: "Safeguard your business with our top-notch cloud security solutions. Explore our website for comprehensive protection against cyber threats and data breaches",
    },
    {
      id: 4,
      path: "SaaS (Software as a Service) Solutions",
      title: "SaaS (Software as a Service)",
      para: "Discover the power of SaaS with our comprehensive website. Explore our range of software solutions designed to streamline your business operations. Boost productivity and efficiency today!",
    },
    {
      id: 5,
      path: "Cloud Migration Services",
      title: "Cloud Migration Services",
      para: "Simplify your transition to the cloud with our reliable and efficient cloud migration services. Seamlessly move your data and applications today.",
    },
  ];
  return (
    <>
      {id === "laptopanddesktop" && (
        <>
          <div className="container mt-5 mp-5">
            <div className="about1">
              <div className="smallsss1"></div>
              <h2 className="aboutline_head1">Laptop Service Center in Coimbatore</h2>
            </div>
            <div className="biod biode">
              <h2
                className="pill"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
              <b><a className="pos-text" href="/servicedetails/Laptop/GamingLaptop">Laptop Service</a> in coimbatore</b>:Unlock boundless potential with our
                precision-engineered <b><a className="pos-text" href="/servicedetails/Laptop/GamingLaptop">laptops</a></b>, fortified with cutting-edge
                security and unrivaled performance. Choose innovation. Choose
                excellence.
              </h2>
              <div
                className="text-muted pol"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <p>
                Discover a redefined standard of <b><a className="pos-text" href="/servicedetails/Laptop/GamingLaptop">laptop</a></b> performance at Infygain Technologies, your go-to choice for the best <b><a className="pos-text" href="/servicedetails/Laptop/GamingLaptop">laptop service center</a></b> in Coimbatore. Our relentless focus on user satisfaction, paired with leading-edge technology, establishes us as the preferred destination for those seeking unparalleled computing services. Elevate your computing experience with us – where excellence meets innovation in Coimbatore's best <b><a className="pos-text" href="/servicedetails/Laptop/GamingLaptop">laptop service</a></b> center.
                </p>
              </div>
            </div>
          </div>
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {laptop.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2">
                      <img
                        className={
                          val.id === 1
                            ? "cat-lap1"
                            : val.id === 2
                            ? "cat-lap3"
                            : val.id === 3
                            ? "cat-lap2"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/Gaming-Laptop-Service-Center-Coimbatore.webp"
                            : val.id === 2
                            ? "/images/Consumer-Laptop-Service-Center-Coimbatore.webp"
                            : val.id === 3
                            ? "/images/HP,Asus,Acer,DellLaptop-Service-Center.webp"
                            : ""
                        }`}
                        alt="Laptop Service Center in Coimbatore"
                        title="Laptop Service Center in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Laptop/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="container mt-5 mp-5">
            <div className="about1">
              <div className="smallsss1"></div>
              <h2 className="aboutline_head1">Computer Service in Coimbatore</h2>
            </div>
            <div className="biod biode">
              <h2
                className="pill"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
<b>Computer Service in Coimbatore</b>: Unparalleled Support for Your Computing Needs.</h2>
              <div
                className="text-muted pol"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <p>
                At Infygain Technologies, we offer comprehensive <b>computer service in Coimbatore</b>, providing unmatched support to ensure your devices are always running at their best. From troubleshooting software issues to repairing hardware malfunctions, we're here to help you with all your computing needs.
                </p>
              </div>
            </div>
          </div>
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {desktop.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 2
                            ? "cat-desk2"
                            : val.id === 3
                            ? "cat-desk1"
                            : val.id === 4
                            ? "cat-desk3"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/Computer-Service-center-in-coimbatore.webp"
                            : val.id === 2
                            ? "/images/Assembled-Desktop-Service-center.webp"
                            : val.id === 3
                            ? "/images/workstation desktop-service.webp"
                            : val.id === 4
                            ? "/images/game-desk-service-center.webp"
                            : ""
                        }`}
                        alt="Computer Service Center in Coimbatore"
                        title="Computer Service Center in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Desktop/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}

      {id === "networking" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {Networking.map((val, index) => (
                <>
                  <div className=" subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                      className={
                        val.id === 7
                       ? "net-wan":""}
                        src={`${
                          val.id === 1
                            ? "/images/Networking-Hub-Service.webp"
                            : val.id === 2
                            ? "/images/network-switch-Services.webp"
                            : val.id === 3
                            ? "/images/network-switch-Service-in Coimbatore.webp"
                            : val.id === 4
                            ? "/images/Network_Firewall_Security-in-Coimbatore.webp"
                            : val.id === 5
                            ? "/images/Network-End-Point-Security.webp"
                            : val.id === 6
                            ? "/images/Network-Fiber-Solution-coimbatore.webp"
                            : val.id === 7
                            ? "/images/network-datacenter (1).webp"
                            : ""
                        }`}
                        alt="Networking Service in Coimbatore"
                        title="Networking Service in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Networking/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}

      {id === "cctv" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {Cctv.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                       className={
                             val.id === 7
                            ? "cctv-bm"
                            : val.id === 8
                            ? "cctv-bb"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/Analog-CCTV-camera-dealers-in-coimbatore.webp"
                            : val.id === 2
                            ? "/images/ip-cctv-camera-dealers-in-coimbatore.webp"
                            : val.id === 3
                            ? "/images/dvr-cctv-camera.webp"
                            : val.id === 4
                            ? "/images/nvr-cctv-camera.webp"
                            : val.id === 5
                            ? "/images/360-Degree-Camera-dealers.webp"
                            : val.id === 6
                            ? "/images/PTZ-camera-dealers-in-coimbatore.webp"
                            : val.id === 8
                            ? "/images/boom-barrier.webp"
                            : val.id === 7
                            ? "/images/Biometric-cctv.webp"
                            : val.id === 9
                            ? "/images/access-control.webp"
                            : ""
                        }`}
                        alt="CCTV Camera Dealers in Coimbatore"
                        title="CCTV Camera Dealers in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Cctv/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      {id === "Accessories" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {Accessories.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 4
                            ? "cat-acc1"
                            : val.id === 7
                            ? "cat-lap1"
                            : val.id === 2
                            ? "cat-lap3"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/ram.webp"
                            : val.id === 2
                            ? "/images/hdd.webp"
                            : val.id === 3
                            ? "/images/keyboard.webp"
                            : val.id === 4
                            ? "/images/mm.webp"
                            : val.id === 5
                            ? "/images/cabinet.webp"
                            : val.id === 6
                            ? "/images/motherboard.webp"
                            : val.id === 7
                            ? "/images/smps123.webp"
                            : val.id === 8
                            ? "/images/cable.webp"
                            : val.id === 9
                            ? "/images/connector.webp"
                            : ""
                        }`}
                        alt="Computer Accessories Shop in Coimbatore"
                        title="Computer Accessories Shop in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Accessories/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      {id === "Projectors" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {Projectors.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={val.id === 3 ? "cat-pro1" : val.id === 4
                        ? "cat-tv1" : ""}
                        src={`${
                          val.id === 1
                            ? "/images/LCD-Projector-in-coimbatore.webp"
                            : val.id === 2
                            ? "/images/Best-LED-Projector.webp"
                            : val.id === 3
                            ? "/images/smart-projectors.webp"
                            : val.id === 4
                            ? "/images/and-tv.webp"
                            : val.id === 5
                            ? "/images/interactivepanel65.webp"
                            : ""
                        }`}
                        alt="Projector Service in Coimbatore"
                        title="Projector Service in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Projectors/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      {id === "TV" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {TV.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 1
                            ? "cat-tv1"
                            : val.id === 2
                            ? "cat-tv2"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/and-tv.webp"
                            : val.id === 2
                            ? "/images/smart-tv.webp"
                            : ""
                        }`}
                        alt={val.title}
                        title={val.title}
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/TV/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}

      {id === "Printer&Scanner" && (
        <>
          <div className="container mt-5 mp-5">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Printer</h4>
            </div>
            <div className="biod biode">
              <h2
                className="pill"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <b>Printer Service Provider</b>: Print with precision and reliability
                using our advanced <b><a className="pos-text"href="/servicedetails/Printer/Laser">printers</a></b>. Elevate your efficiency with sharp,
                high-quality outputs, setting new standards for printing
                excellence
              </h2>
              <div
                className="text-muted pol"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <p>
                At Infygain Technologies, we offer complete <b><a className="pos-text" href="/servicedetails/Printer/Laser">printer solutions</a></b>, including <b>toner and cartridge refill, printer service, installation</b>, and ongoing support. Our commitment to excellence and cutting-edge technology ensures a seamless and reliable printing experience. Choose us to elevate your printer management with simplicity and efficiency.                </p>
              </div>
            </div>
          </div>
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {printer.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 1
                            ? "cat-pri1"
                            : val.id === 2
                            ? "cat-pri2"
                            : val.id === 3
                            ? "cat-pri3"
                            : val.id === 4
                            ? "cat-pri4"
                            : val.id === 5
                            ? "cat-pri5"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/laser-printer-services-in-coimbatore.webp"
                            : val.id === 2
                            ? "/images/thermal-printer-services-in-coimbatore.webp"
                            : val.id === 3
                            ? "/images/color-printer-services-in-coimbatore.webp"
                            : val.id === 4
                            ? "/images/multi-function-printer-services-in-coimbatore.webp"
                            : val.id === 5
                            ? "/images/dot-matrix-printer-services-in-coimbatore.webp"
                            : ""
                        }`}
                        alt="Printer Service Center in Coimbatore"
                        title="Printer Service Center in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Printer/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="container mt-5 mp-5">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1"> Scanner</h4>
            </div>
            <div className="biod biode">
              <h2
                className="pill"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <b><a className="pos-text"href="/servicedetails/Scanner/Flatbed">Scanner Service</a></b>: Capture perfection with our
                high-performance <b><a className="pos-text"href="/servicedetails/Scanner/Flatbed">scanners</a></b>. Elevate your scanning experience with
                precision and speed, setting new benchmarks for scanning
                excellence.
              </h2>
              <div
                className="text-muted pol"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <p>
                  At Infygain Technologies, elevate your scanning capabilities.
                  Our dedication to customer satisfaction, fused with
                  state-of-the-art technology, positions us as the top choice
                  for those seeking superior <b>scanning solutions</b>. Streamline your
                  workflow with us – where excellence and innovation seamlessly
                  merge.
                </p>
              </div>
            </div>
          </div>
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {scanner.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        src={`${
                          val.id === 1
                            ? "/images/flatbad scanner.webp"
                            : val.id === 2
                            ? "/images/verticalscanner.webp"
                            : ""
                        }`}
                        alt="Scanner Sales & Service"
                        title={val.title}
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Scanner/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}

      {id === "InteractivePanel" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {InteractivePanel.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={val.id === 2 ? "cat-ip2" : ""}
                        src={`${
                          val.id === 1
                            ? "/images/interactivepanel65.webp"
                            : val.id === 2
                            ? "/images/interactivepanel75.webp"
                            : ""
                        }`}
                        alt="Interactive Flat Panel"
                        title="Interactive Flat Panel"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/InteractivePanel/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}

      {id === "UPS" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {UPS.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 1
                            ? "cat-ups1"
                            : val.id === 2
                            ? "cat-ups2"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/inventer.webp"
                            : val.id === 2
                            ? "/images/onlineups.webp"
                            : ""
                        }`}
                        alt={val.title}
                        title={val.title}
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/UPS/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      {id === "WIFI" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {WIFI.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 3
                            ? "cat-wifi2"
                            : val.id === 2
                            ? "cat-wifi3"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/acespoint.webp":
                          val.id === 2
                            ? "/images/access-point.webp"
                            : val.id === 3
                            ? "/images/wifi5.webp"
                            : val.id === 4
                            ? "/images/point2point.webp"
                            : ""
                        }`}
                        alt="WIFI Service in Coimbatore"
                        title="WIFI Service in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/WIFI/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
       {id === "Cloud" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {Cloud.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={
                          val.id === 3
                            ? "cat-wifi2"
                            : val.id === 2
                            ? "cat-wifi3"
                            : ""
                        }
                        src={`${
                          val.id === 1
                            ? "/images/Cloud-Hosting.webp":
                          val.id === 2
                            ? "/images/Databackup-Recovery.webp"
                            : val.id === 3
                            ? "/images/CloudSec.webp"
                            : val.id === 4
                            ? "/images/SaaS.webp"
                            : val.id === 5
                            ? "/images/cloud-migration-laptop.webp"
                            : ""
                        }`}
                        alt="WIFI Service in Coimbatore"
                        title="WIFI Service in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Cloud/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      {id === "Pos" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {pos.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={val.id === 3 ? "cat-desk2" : val.id === 5 ? "cat-tb" :""}
                        src={`${
                          val.id === 1
                            ? "/images/POS-Machine-dealers-in-coimbatore.webp"
                            : val.id === 2
                            ? "/images/POS-System-Dealers-in-Coimbatore.webp"
                            : val.id === 3
                            ? "/images/Pos-Dealers-in-Coimbatore.webp"
                            : val.id === 4
                            ? "/images/Pos-Machine.webp"
                            : val.id === 5
                            ? "/images/pos-system-in-coimbatore.webp"
                            : ""
                        }`}
                        alt="POS Machine Dealers in Coimbatore"
                        title="POS Machine Dealers in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Pos/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      {id === "InterCom" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {InterCom.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        src={`${
                          val.id === 1
                            ? "/images/epabx.webp"
                            : val.id === 2
                            ? "/images/ipabx.webp"
                            : val.id === 3
                            ? "/images/intercom.webp"
                            : ""
                        }`}
                        alt="Intercom Services in Coimbatore"
                        title="Intercom Services in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/InterCom/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
         {id === "Server" && (
        <>
          <SubCateContent />
          <div className="container mt-5 mp-5">
            <div className="subcategory1">
              {server.map((val, index) => (
                <>
                  <div className="subcategoryhead" key={index}>
                    <div className="subcategory2 ">
                      <img
                        className={val.id === 1 ? "cat-sev1" : val.id === 2 ? "cat-sev2" :val.id === 3 ? "cat-sev3" :val.id === 4 ? "cat-sev4" :""}
                        src={`${
                          val.id === 1
                            ? "/images/server-main.webp"
                            : val.id === 2
                            ? "/images/server-inst.webp"
                            : 
                          val.id === 3
                            ? "/images/web-server.webp"
                            : val.id === 4
                            ? "/images/storage-server.webp"
                            : 
                            ""
                        }`}
                        alt="Server Service Provider in Coimbatore"
                        title="Server Service Provider in Coimbatore"
                      />
                    </div>

                    <div className="subcategory3">
                      <h2 className="ser-font-size">{val.title}</h2>
                      <div className="pilot">
                        <p>{val.para}</p>
                        <Link
                          to={`/servicedetails/Server/${val.title.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          <button className="btn btn-md warrier">
                            Read More{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Category;
