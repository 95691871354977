import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../css/mainpackage.css";

function Bannerlle({content}) {
  return (
    <>
      <div className="head-isp-serviceban ">
        <h1 className="head-isp-about">{content ? <>{content}</> :<> Internet Leased Line Service in Coimbatore</>}</h1>
      </div>
    </>
  );
}

export default Bannerlle;
