import "../css/mainpackage.css";
import React from "react";
import { Link } from "react-router-dom";

function Mainpackage() {
  return (
    <>
      <div className="container lolp mt-5 mb-5">
        <div className="hkl">
          <h2 className="bril mb-5 int-isp1">Business Broadband</h2>
        </div>
        <div className="row">
          <div
            className="col-md-4 col-lg-4  popp"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="lmk">
              <img
                src="/images/broadband-vector.webp"
                alt="Business Broadband"
                className="image-fluid"
                title="Business Broadband"
              />
            </div>
          </div>
          <div
            className="col-md-8 col-lg-8   wifit"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              Welcome to our <b>Business Broadband service</b>, your gateway to
              seamless and reliable connectivity ensuring your business remains
              operational 24/7
            </p>
            <p className="text-muted">
              Our service guarantees high-speed, dedicated Internet
              connectivity, keeping your business connected at all times. With a
              commitment to symmetric bandwidth, you'll experience consistent
              upload and download speeds, optimizing your online performance.
              Moreover, we are IPv6 ready, ensuring future-proof compatibility
              with evolving technologies. At the infrastructure level, we
              provide dual upstream parenting at the POP level and dual POP
              parenting at the last mile, offering redundancy and reliability to
              minimize downtime and optimize network performance. To further
              support your connectivity needs, each client receives the
              dedicated assistance of a Technical Assurance Manager, ensuring
              prompt issue resolution and personalized support tailored to your
              business requirements.
              <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-7 col-lg-7 wifit1"
            id="column2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-muted mt-2">
              "Catalyze your business potential with our <b>Business Broadband
              services</b>– your dedicated gateway to seamless connectivity and
              unparalleled performance"
            </p>
            <p className="text-muted ">
              Elevate your online presence with our <b>Business Broadband solutions</b>
              meticulously crafted to meet the demands of modern businesses.
              With our robust infrastructure and unwavering commitment to
              reliability, we deliver a dedicated internet connection tailored
              to fuel your operations with consistent speeds and uninterrupted
              access. Say goodbye to bandwidth bottlenecks and latency issues as
              our <b>Business Broadband</b> offers symmetric speeds and low latency,
              ensuring optimal performance for mission-critical applications.
              Backed by our proactive monitoring and responsive support team, we
              guarantee maximum uptime and swift resolution of any connectivity
              concerns, allowing you to focus on driving your business forward.
              Experience the power of reliable connectivity with our Business
              Broadband services and unlock new possibilities in the digital
              landscape.
              <br />
            </p>
          </div>
          <div
            className="col-md-5 col-lg-5 olk"
            id="column1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="lmk2">
              <img
                src="/images/bb2.webp"
                alt="Business Broadband"
                className="image-fluid"
                title="Business Broadband"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container services">
        <h2 className="text-center int-isp1">PACKAGES</h2>
        <div className="row Boxss">
          <div
            className="servicebox col-lg-4"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <div className="serviceContent-thiban">
              <div className="des">
                <img
                  className="img-fluid"
                  src="/images/banner/Group 1 copys.webp"
                  alt="High-Speed Internet Provider"
                  title="Basic package"
                ></img>
              </div>
              <p className="midss-title">BASIC PACKAGE</p>
              <p className="midess-title">50 Mbps</p>
              <p className="text-muted  midesss-title">Speed Up to 50 Mbps</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">Unlimited Data</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">100% Uptime</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">IP Private</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">24/7 customer support</p>

              <p className="pac-amount">
                {" "}
                Rs 1500/-<br></br>
                <p className="par-package">/Monthly</p>
              </p>

              <div className="buttons">
                <button className="btns-greenbox">
                  {" "}
                  <Link className="mlink" to="/contact">
                    subscribe now{" "}
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div
            className="servicebox col-lg-4"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="serviceContent-thiban">
              <div className="des">
                <img
                  className="img-fluid"
                  src="../images/banner/Group 2 copy.jpg"
                  alt="ISP Solutions"
                  title="Premium package"
                ></img>
              </div>

              <p className="midss-title">PREMIUM PACKAGE</p>
              <p className="midess-title">100 Mbps</p>
              <p className="text-muted  midesss-title">Speed Up to 100 Mbps</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">Unlimited Data </p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">100% Uptime</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">Free Public IP</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">24/7 customer support</p>

              <p className="pac-amount">
                {" "}
                Rs 2500/-<br></br>
                <p className="par-package">/Monthly</p>
              </p>

              <div className="buttons">
                <button className="btns-greenbox">
                  {" "}
                  <Link className="mlink" to="/contact">
                    subscribe now{" "}
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div
            className="servicebox col-lg-4"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <div className="serviceContent-thiban">
              <div className="des">
                <img
                  className="img-fluid"
                  src="../images/banner/Group 3 copy.jpg"
                  alt="Business Internet Solutions"
                  title="Hype package"
                ></img>
              </div>
              <p className="midss-title">HYPE PACKAGE</p>
              <p className="midess-title">250 Mbps</p>
              <p className="text-muted  midesss-title">Speed Up to 250 Mbps</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">Unlimited Data </p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">100% Uptime</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">Free Public IP</p>
              <hr className="smallserline"></hr>

              <p className="text-muted midesss-title">24/7 customer support</p>
              <p className="pac-amount">
                {" "}
                Rs 5000/-<br></br>
                <p className="par-package">/Monthly</p>
              </p>

              <div className="buttons">
                <button className="btns-greenbox">
                  <Link className="mlink" to="/contact">
                    subscribe now
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mainpackage;
