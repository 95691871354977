import "font-awesome/css/font-awesome.css";
import "../css/mainblog.css";

function Banner() {
  return (
    <>
      <div className="top-banner">
        <div>
          <p className="main-title">Main-Blogs</p>
        </div>
      </div>
    </>
  );
}

export default Banner;
